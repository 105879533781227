.hub-home-container {
  width: 100%;
  overflow: scroll hidden;
}

.hub-home-container .header-container {
  margin: 0px;
  background-color: white;
  padding: 25px 0px;
  overflow-x: clip;
}
.hub-home-container .contact .btn:focus, .btn:active:focus, .btn.active:focus, .btn.focus, .btn:active.focus, .btn.active.focus {
  outline: none;
}

.hub-home-container .contact .btn-default:hover {
  background-color: white !important;
}

.hub-home-container .contact {
  display: inline-block;
  padding-right: 15px;
  padding-left: 15px;
  height: 25px;
  margin-right: 15px;
}

.hub-home-container .contact-info {
  display: inline-block;
  color: var(--colour-dark-extra);
  font-family: var(--font-main);
}

.hub-home-container .header-image-container {
  display: flex;
  flex-direction: row;
  text-align: center;
  margin-top: -8em;
}

.hub-home-container .hub-logo {
  height: 180px;
  min-width: 180px;
  border-radius: 50%;
  box-shadow: #ababab79 0px 0px 10px;
  background-size: cover;
  background-position: center center;
  margin: 0 3em 0 5em;
  z-index: 2;
}

.hub-home-container .hub-details-container {
  padding-left: 25px;
}

.hub-home-container .action-button {
  color: var(--colour-dark-main);
  background-color: #ffffff;
  font-family: var(--font-main);
  font-weight: bold;
  margin: 8.5em 2em 0px auto;
  text-shadow: none;
  height: 3em;
  border-radius: 15px;
  padding: 0px 5px 0px 5px;
}

.hub-home-container .action-button-following {
  background-color: var(--colour-accent-1);
  color: #ffffff;
  font-family: var(--font-main);
  font-weight: bold;
  padding: 5px;
  margin: 8.5em 2em 0px auto;
  text-shadow: none;
  height: 3em;
  border-radius: 15px;
  padding: 0px 30px;
}

.hub-home-container .action-button:hover{
  border-color: var(--colour-dark-side);
  color: var(--colour-dark-side);
}

.hub-home-container .banner-image-container {
 text-align: center;
 background-size: cover;
 background-position: top center;
 height: 250px;
 max-width: 100%;
 display: flex;
}

.hub-home-container .banner-image {
  display: inline-block;
  height: auto;
  max-height: 250px;
  max-width: 100%;
}

.hub-home-container .social-container {
  height: auto;
  margin-left: auto;
  margin-top: auto;
  margin-bottom: -20px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding-left: 300px;
  background-color: var(--color-background-darkest-role);
}

.hub-home-container .social {
  border-radius: 50%;
  color: white;
}



.hub-home-container .social-icon-container:hover {
  cursor: pointer;

}

.social-tz {
  color: var(--color-background-darkest-role);
  padding-right: 10px;
}

.hub-home-container .social-tw {
  background-color: #ffffff;
  color: var(--color-background-darkest-role);
  padding-top: 2px;
}

.hub-home-container .social-fb {
  background-color: #ffffff;
  color: var(--color-background-darkest-role);
  padding-top: 2px;
}

.hub-home-container .social-ln {
  background-color: #ffffff;
  color: var(--color-background-darkest-role);
  padding-top: 2px;
}

.hub-home-container .social-g {
  background-color: #ffffff;
  color: var(--color-background-darkest-role);
}

.hub-home-container .social-icon-container {
  display: inline-block;
  height: 34px;
  width: 34px;
  border-radius: 50%;
  margin: 10px;
  line-height: 30px;
  z-index: 1;
}

.hub-home-container .nav-tabs {
  border-bottom: solid 1px #EFEFEF;
  border-top: solid 1px #EFEFEF;
  display: flex;
  text-transform: uppercase;
  white-space: nowrap;
  padding-left: 2.5%;
  overflow: none;
}

.hub-home-container ::-webkit-scrollbar {
  height: 8px;
}

.hub-home-container ::-webkit-scrollbar-thumb {
  background-color: var(--colour-light-extra);
  transition: ease-in-out 1.5s background-color;
}

.hub-home-container ::-webkit-scrollbar-thumb:hover {
  background-color: var(--colour-dark-side);
}

@media (max-width: 1020px) {
  .hub-home-container .nav-tabs {
    overflow: scroll clip;
  }

  .hub-home-container .header-image-container {
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  .hub-home-container .hub-logo {
    max-width: 180px;
    margin: 0px auto;

  }

  .hub-home-container .hub-name {
    margin: 0.5em 0px;
  }

  .hub-home-container .action-button {
    margin: 1em auto;
  }

  .hub-home-container .action-button-following {
    margin: 1em auto;
  }

  .hub-home-container .social-container {
    padding-right: 5px;
    display: flex;
    justify-content: flex-end;
  }

  .hub-home-container .access-lvl {
    margin: 0px !important;
  }

  .header-image-container .hub-name {
    margin-top: 1em !important;
  }
}

@media (min-width: 1020px) {
  .hub-home-container .header-container, .hub-home-container .nav-tabs, .home-tab-container, .create-module-tab-container, .create-plan-session-tab-container, .users-tab-container, .analytics-tab-container, .settings-container {
    padding-left: 3em;
    padding-right: 3em;
  }
}

.hub-home-container .nav-tabs > li.active > a, .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus {
  border: none;
  color: var(--color-background-darkest-role) !important;
  border-bottom: 5px solid var(--color-background-darkest-role);
  color: black;
  background-color: #f5f5f5;
}

.nav li a {
  color: black;
  padding: 10px 25px;
}

.hub-home-container .access-lvl {
  background-color: transparent;
  background-image: none;
  color: var(--colour-dark-main);
  font-family: var(--font-main);
  text-transform: uppercase;
  padding: 15px;
  margin: 7.5em 3em 0px auto;
  text-shadow: none;
  height: 4em;
  border-radius: 4px;
}

.hub-home-container .access-lvl:hover {
  cursor: default;
}

.header-image-container .hub-name {
  font-size: 2.8em;
  font-weight: 700;
  color: var(--colour-dark-extra);
  margin-top: 3em;
  cursor: default;
  margin-left: 0.5em;
}

.hub-details-container .hub-name {
  /* font-family: "Roboto"; */
  font-weight: bold;
  color: #646464;
}

.followers {
  font-size: 1.5em;
  font-weight: 300;
  color: var(--colour-dark-extra);
  cursor: default;
  white-space: nowrap;
}

.hub-description {
  font-size: 1em;
  font-weight: 300;
  margin: 1em 0em 2em 0em;
  color: var(--colour-dark-extra);
}

.featured-container {
  margin: 0px 0.5em;
}

.featured-container .section-title {
  font-family: var(--font-main) !important;
  color: var(--colour-dark-extra);
  font-size: 1.2em;
}

@media (max-width: 425px) {
  .hub-home-container .hub-logo{
    height: 150px;
    min-width: 150px;
  }
  .hub-home-container .social-container {
    margin-bottom: 0px;
  }
  .hub-home-container .social-icon-container {
    height: 25px;
    width: 25px;
    margin: 7px;
    line-height: 20px;
  }
}

@media (max-width: 375px) {
.hub-home-container .social-container {
  margin-bottom: -40px;
}
}

@media (max-width: 575px) {
  .hub-home-container .contact {
    padding-right: 5px;
    padding-left: 5px;
    margin-right: 5px;
  }
}

@media (max-width: 500px) {
  .hub-home-container .contact {
    padding-right: 0px;
    padding-left: 0px;
    margin-right: 5px;
  }
}

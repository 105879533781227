.main-container-courses{
    padding: 20px;
}

.main-container-courses .main-heading{
    color: #14B4C8;
    font-size: 35px;
    font-weight: bold;
    font-family: var(--font-main);
}

.list-courses-table-container {
    padding: 0% 2.5%;
    font-family: var(--font-main);
  }
  
  .list-courses-table-container table{
    width: 100%;
  }
  
  .list-courses-table-container .add-user {
    padding-right: 5px;
    color: var(--colour-accent-1);
    font-size: 17px;
    background-color: var(--color-background-darkest-role-other);
  }
  
  .list-courses-table-container .add-user:hover {
    cursor: pointer;
  }
  
  .list-courses-table-container .avatar-image {
    height: 75px;
    width: 75px;
    border-radius: 50%;
    background-size: cover;
    background-position: center center;
  }
  
  .list-courses-table-container th {
    padding: 25px;
    text-align: center;
  }
  
  .list-courses-table-container tr {
    border-bottom: solid 2px #eeeeee;
  }
  
  .list-courses-table-container td {
    padding: 10px;
    
  }

  .tile-container .buttons-container{
display: flex;
gap:10px;
padding:20px 10px;
  }

  .tile-container .buttons-container .btn{

    background-color: #14B4C8;
    color: white;
    border-radius: 20px;
    font-size: 13px;
    width:100% 
    /* Need to remove the width when adding download report button */
  }

  .list-courses-table-container .course-name{
    color: #14B4C8;
    font-weight: bold;
  }
  
  .list-courses-table-container table button {
    height: 40px;
  }
  
  .list-courses-table-container .add-user-button-container {
    text-align: right;
  }
  
  .add-user-button-container button{
    background-color: var(--colour-accent-1);
    border: none;
    font-family: var(--font-main);
  }
  
  .add-user-button-container button:hover{
    background-color: var(--colour-accent-1);
    border: none;
    color: white;
  }
  
  .list-courses-table-container button {
    text-transform: uppercase !important;
    background-color: var(--colour-accent-1);
    color: white;
    text-shadow: none;
  }
  
  .list-courses-table-container button:hover {
    text-transform: uppercase !important;
    background-color: var(--colour-accent-1);
    color: white;
    text-shadow: none;
  }
  
  
  .list-courses-table-container .user-name:hover {
    cursor: pointer;
  }
  
  .list-courses-table-container .facaretdown:hover {
    cursor: pointer;
  }

  .sub-header-container{
    display: flex;
  }

  .sub-header-container .header-back-button-container{
    margin-left: auto;
  }

  .header-back-button-container .back-button{
    color: #FFFFFF;
    background-color: #14B4C8;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
    text-decoration: none;
    font-size: 16px;
  }

  .group-info-container .group-name{
    color: #14B4C8;
    font-weight: bold;
    cursor: pointer;
  }

  
  @media (max-width: 600px) {
    .list-courses-table-container{
      overflow-x: scroll;
    }
  }
  
.main-container-create-group{
    padding: 20px 20px 20px 20px;
}

.assigned-courses-main-container{

    padding-top: 10px;
}

.user-main-container{

    padding-top: 30px;

}

.sub-label{
    font-size: larger;
    font-weight: bold;
    color: #646464;
  font-style: "Roboto";
  display: block;
   
}

.main-container-create-group .button-header-container{
    display: flex;
    justify-content: flex-end;
}

.button-header-container .header-button{

    display: flex;
    background-color: #14B4C8;
    font-family: var(--font-main);
    background-image: none;
    color: white;
    text-transform: uppercase;
    text-shadow: none;
    padding: 10px 20px 10px 20px;
}

.button-header-container .button-container{
    display: flex;
    padding-left: 20px;
}

.main-container-create-group .heading-name{
    color: #14B4C8;
    font-size: 35px;
    font-weight: bold;
    font-family: var(--font-main);
}

.main-container-create-group .group-name-container{
    display: flex;
    padding: 10px 0px 10px 0px;
}

.main-container-create-group .form-group-name{
    display: flex;
}

.group-name-container .form-input-group-name{
    display: flex;
    flex:0.5;
    font-size: large;
    color: #14B4C8;
    font-weight: bold;
    
}

.group-name-container .control-label-group-name{
    display: block;
    flex:0.4;
    font-size: large;
    font-weight: bold;
    
}

.users-table-container {
    padding: 0% 2.5%;
    font-family: var(--font-main);
  }
  
  .users-table-container table{
    width: 100%;
  }
  
  .users-table-container .add-user {
    padding-right: 5px;
    color: var(--colour-accent-1);
    font-size: 17px;
    background-color: var(--color-background-darkest-role-other);
  }
  
  .users-table-container .add-user:hover {
    cursor: pointer;
  }
  
  .users-table-container .avatar-image {
    height: 75px;
    width: 75px;
    border-radius: 50%;
    background-size: cover;
    background-position: center center;
  }
  
  .users-table-container th {
    padding: 25px;
    text-align: center;
    font-weight: bold;
    color: #646464;
  font-style: "Roboto";
  }
  
  .users-table-container tr {
    border-bottom: solid 2px #eeeeee;
  }
  
  .users-table-container td {
    padding: 10px;
    text-align: center;
  }
  
  .users-table-container .role-heading {
    padding: 15px;
  }
  .users-table-container table button {
    height: 40px;
  }
  
  .users-table-container .add-user-button-container {
    text-align: right;
  }
  
  .add-user-button-container button{
    background-color: var(--colour-accent-1);
    border: none;
    font-family: var(--font-main);
  }
  
  .add-user-button-container button:hover{
    background-color: var(--colour-accent-1);
    border: none;
    color: white;
  }
  

  .users-table-container .warning-button {
    background: red;
    color: white;
    text-shadow: none;
    font-size: 14px;
    margin-left: 5px;
    text-transform: uppercase;
  }
  
  .users-table-container .warning-button:hover {
    cursor: pointer;
    background: red
  }
  
  .users-table-container .user-name:hover {
    cursor: pointer;
  }
  
  .users-table-container .facaretdown:hover {
    cursor: pointer;
  }

  .arrow-button-group-tab {
    background-color: white;
    color: #14B4C8;
    border: none;
    font-size: 10px;
    bottom: 4px;
  }

 .arrow-button-group-tab:active {
    background-color: white;
    color: #14B4C8;
  }

.main-container-create-group .save-button-container{
  display: flex;
  justify-content: center;
  padding: 20px 0px 20px 0px;
margin-top: 30px;

}
 .main-container-create-group .save-button{
  background-color: #14B4C8;
  color: white;
  text-transform: capitalize;
  font-size: medium;
  border-radius: 20px;
width: 120px;
 } 

  
  @media (max-width: 600px) {
    .users-table-container{
      overflow-x: scroll;
    }
  }

  
  
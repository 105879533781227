:root {
  /* --font-main: 'Roboto';
  --font-headings: 'Roboto'; */

  --colour-light-main: #f5f5f5;
  --colour-light-side: #ffffff;
  --colour-light-extra: rgb(209, 209, 209);
  --colour-light-explore: rgb(45, 103, 115);
  --colour-dark-main: #646464;
  --colour-dark-side: rgb(158, 158, 158);
  --colour-dark-extra: rgb(70, 70, 70);
  --colour-dark-explore: rgb(45, 103, 115);
  --colour-accent-1: #14b4c8;
  --colour-accent-2: #f6ae2d;
  --colour-warning: rgb(207, 5, 5);

  /* default practitioner */
  --color-background-role: #FFF8E2;
  --color-background-darker-role: #FFEFBC;
  --color-background-darkest-role: #FAC832;
  --color-background-darkestest-role: #FEE9A7;
  --colour-session-calendar-day: #FFF8E2;
  --colour-session-calendar-today: #FEE9A7;
  /* other = personal */
  --color-background-role-other: #F2FDFF;
  --color-background-darker-role-other: #D3F5FA;
  --color-background-darkest-role-other: #14B4C8;
  --color-background-darkestest-role-other: #2D6773;
  --colour-session-calendar-day-other: #EFFDFF;
  --colour-session-calendar-today-other: #C4ECF1;
}

[data-theme="personal"] {
  --color-background-role: #F2FDFF;
  --color-background-darker-role: #D3F5FA;
  --color-background-darkest-role: #14B4C8;
  --color-background-darkestest-role: #2D6773;
  --colour-light-explore: #ffffff;
  --colour-session-calendar-day: #EFFDFF;
  --colour-session-calendar-today: #C4ECF1;
  --colour-light-message:#418E9E;

  --color-background-role-other: #FFF8E2;
  --color-background-darker-role-other: #FFEFBC;
  --color-background-darkest-role-other: #FAC832;
  --color-background-darkestest-role-other: #FEE9A7;
  --colour-dark-explore: rgb(45, 103, 115);

  --colour-session-calendar-day-other: #FFF8E2;
  --colour-session-calendar-today-other: #FEE9A7;
  --colour-dark-message:#646464;
}

h1, h2, h3, h4, h5, h6 {
  font-family: var(--font-headings);
  color: var(--colour-dark-main);
}

p, a, label, li {
  font-family: var(--font-main);
  color: var(--colour-dark-main);
}

body {
  /* font-family: "Roboto"; */
}

button {
  transition: ease-in-out 0.15s;
  text-shadow: none !important;
}

.btn {
  text-shadow: none !important;
}

.App {
  text-align: center;
}
.wrapper {
  margin-top: -1px;
}

.modal-header {
  border-bottom: none;
  padding-bottom: none;
  margin-bottom: -49px;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App button {
  text-shadow: none !important;
}

.App-header {
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

@keyframes slide-up {
  from { transform: translateY(100%); }
  to { transform: translateY(0%); }
}

.contentWrapper {
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: 700;
}

.cookieWrapper {
  position: absolute;
  top: 180px;
  margin-left: 418px;
  display: flex;
  justify-content: left;
  align-items: left;
}

.cookieButton {
  padding: 7px 23px;
  margin: 0px 15px;
  border: none;
  border-radius: 25px;
  width: 247px;
  height: 52px;
  box-shadow: 0px 2px 5px #7b7b7b;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
}

.cookieImage {
  height: 194px;
  width: 200px;
  margin-left: 67px;
  margin-right: 89px;
  display: inline;
}

.cookieText {
  position: absolute;
  top: 35px;
  margin-left: 418px;
  padding-right: 20px;
}

.cookieLargeText {
  font-size: 40px;
  line-height: 48px;
  color: white;
}

.cookieSmallText {
  font-size: 20px;
  line-height: 24px;
  color: white;
}

@media (max-width: 1250px) {
  .cookieImage {
    height: 180px;
    width: 185px;
  }

  .cookieText {
    top: 35px;
    margin-left: 335px;
  }

  .cookieLargeText {
    font-size: 35px;
    line-height: 42px;
  }

  .cookieSmallText {
    font-size: 17.5px;
    line-height: 21px;
  }

  .cookieWrapper {
    top: 165px;
    margin-left: 335px;
  }
}

@media (max-width: 1050px) {
  .cookieImage {
    height: 141px;
    width: 145px;
    margin-left: 25px;
  }

  .cookieText {
    top: 25px;
    margin-left: 215px;
  }

  .cookieLargeText {
    font-size: 30px;
    line-height: 36px;
  }

  .cookieSmallText {
    font-size: 16px;
    line-height: 18px;
  }

  .cookieWrapper {
    top: 145px;
    margin-left: 215px;
  }

  .cookieButton {
    width: 200px;
    height: 46px;
    font-size: 19px;
    line-height: 20px;
  }
}

@media (max-width: 860px) {
  .cookieSmallText {
    display: inline;
  }
}

@media (max-width: 700px) {
  .cookieImage {
    height: 141px;
    width: 145px;
    margin-left: 0px;
  }

  .cookieLargeText{
    font-size: 25px;
  }

  .cookieText {
    margin-left: 170px;
  }

  .cookieWrapper {
    top: 145px;
    margin-left: 170px;
  }

  .cookieButton {
    width: 200px;
    height: 46px;
    font-size: 19px;
    line-height: 20px;
  }
}

@media (max-width: 620px) {
  .cookieImage {
    margin-left: -300px;
  }

  .cookieText {
    margin-left: 0;
  }

  .cookieWrapper {
    top: 145px;
    margin-left: 0px;
  }

  .cookieButton {
    width: 200px;
    height: 46px;
    font-size: 19px;
    line-height: 20px;
  }
}

@media (max-width: 475px) {
  .cookieButton {
    width: 150px;
    height: 39px;
  }

  .cookieText{
    top: 10px;
  }

  .cookieLargeText{
    font-size: 23px;
  }

  .cookieSmallText {
    font-size: 16px;
  }
}

@media (max-width: 320px) {
  .cookieButton {
    width: 120px;
  }
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0);
  -webkit-box-shadow: 0 0 1px rgb(255 255 255 / 50%);
}

::-webkit-scrollbar-thumb:hover {
  border-radius: 4px;
  background-color: rgba(0,0,0,.25);
  -webkit-box-shadow: 0 0 1px rgb(255 255 255 / 50%);
}

.beta-tag {
  font-size: 80%;
  position: relative;
  top: -0.5em;
}


.comments-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 2em;
  /* margin-top: 60px; */
}

.comment-section-container {
  padding: 28px;
  padding-top: 10px;
  background-color: #f5f5f5;
  border-radius: 35px;
  padding: 31px;
}

.comment-box {
  width: 100%;
  height: 50px;
  padding-left: 0.4em;
  font-family: var(--font-main);
  border: 1px solid var(--colour-light-extra);
  background-color: inherit;
  border-radius: 5px;
}

.comment-box:focus {
  padding-left: 0.4em;
  font-family: var(--font-main);
  border: 1px solid var(--colour-light-extra);
  outline: none;
  background-color: white;
}

.comment-submit {
  font-family: var(--font-main);
  background-color: var(--colour-accent-1);
  width: 10em !important;
  height: 40px;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%) !important;
  border-radius: 25px;
  text-transform: none !important;
  color: white;
  font-weight: bold;

  margin-top: 15px;
  float:right;
}

.replies.hide {
  visibility: hidden;
}

.comment.hide {
  visibility: hidden;
}

.avatar-image {
  display: inline-block;
  height: 35px;
  width: 35px;
  border-radius: 50%;
  border: 1px solid var(--colour-light-extra);
  background-position: center center;
  background-size: cover;
  margin-right: 10px;
}

.likes-section {
  display: flex;
  cursor: pointer;
}

.disabled-likes {
  cursor: inherit;
}

.like-count {
  font-size: 18px;
  padding: 2px 15px 0px 7px;
}

.like-button {
  font-size: 18px;
}

.liked {
  color: var(--colour-accent-1) !important;
}

.comment-middle .name {
  font-weight: bold;
  color: var(--colour-accent-1);
  font-size: calc(1em + 0.25vw);
  margin: 0px;
}

.comment-middle .time {
  color: gray;
  font-size: calc(0.6em + 0.175vw);
  padding-left: 10px;
}

.delete {
  margin-left: auto;
  text-transform: uppercase;
  font-size: calc(0.6em + 0.175vw);
  text-shadow: none;
  background-color: none;
  text-decoration: underline;
  font-weight: bold;
  color: var(--colour-warning);
  background-color: inherit;
}

.comment-middle p {
  font-family: var(--font-main);
  font-size: calc(0.8em + 0.28vw);
  overflow-wrap: break-word;
  color: rgba(100, 100, 100, 1);
}

.replies {
  padding-left: calc(75px + 10px);
  margin-top: -28px;
  height: calc(2.6em + 0.4vw);
  overflow: hidden;
}

.replies > button {
  background-color: #f5f5f5;
}

.replies.expanded {
  height: auto;
}

.expand {
  /* margin-bottom: calc(0.1em + 0.025vw); */
  margin-bottom: -8px;
  color: #94CBD6;
  text-shadow: none;
  font-size: calc(1em + 0.175vw);
  /* border: 1px solid; */
  z-index: 999999;
}

.expand:hover {
  color: var(--colour-dark-extra);
}

.feedback {
  display: block;
  padding: 1em;
}

.reply-button {
  font-weight: bold;
  color: var(--colour-dark-extra);
  transition: all 0.2s ease;
  padding: 0.125em 0.2em 0.125em 0.2em;
}

.reply-button:hover {
  background-color: var(--colour-dark-side);
  border-radius: 10%;
  color: var(--colour-light-main);
  cursor: pointer;
}

.full-width {
  width: 100% !important;
}

.login-prompt {
  display: block;
  margin: 0 2em 0 1em 0;
  font-weight: bold;
  font-size: 1.15em;
  color: var(--colour-dark-extra);
}

.refresh {
  font-size: 0.8em;
  color: var(--colour-accent-1);
}

.refresh:hover {
  color: var(--colour-dark-side);
  cursor: pointer;
}

.not-ready {
  color: var(--colour-dark-side);
  cursor: default !important;
}

.comment{
  width: 100%;
  display: inline-block;
  padding-top: 35px;
  margin-bottom: 20px;
}

.comment-middle{
  display: inline-block;
  vertical-align: top;
  padding-left: 10px;
  max-width: 850px;
}

.comment-likes{
  display: inline-block;
  float: right;
}

.user-comment-reply{
  margin-top: 28px;
}

@media (max-width: 320px) {
  .replies{
    padding-left: 0px;
  }

  .comment-middle {
    max-width: 190px;
    padding-left: 0px;
  }
}

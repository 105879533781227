.hub-tile-container {
  display: inline-flex;
  align-items: center;
  margin-bottom: 1em;
  color: var(--colour-dark-main);
  background-color: #ffffff;
  border: 1px solid var(--colour-light-extra);
  border-radius: 30px;
  padding: 1em;
  overflow: hidden;
  transition: ease-in-out 0.15s box-shadow;
  width: 100%;
}

.hub-tile-outer-container.carousel {
  width: 100%;
}

.hub-tile-outer-container {
  padding: 0px 0.5em;
  width: 25%;
}

.tooltiptext.hidden {
  visibility: hidden;
}

.hub-tile-container .tooltiptext.carousel {
  visibility: hidden;
  width: 120px;
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 85%;
  left: 50%;
  margin-left: -60px;
}

.hub-tile-container .tooltiptext.carousel::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.7) transparent transparent transparent;
}

.hub-tile-container:hover .tooltiptext.carousel {
  visibility: visible;
}

@media (max-width:1600px) {
  .hub-tile-outer-container {
    width: 25%;
  }
}

@media (max-width:1300px) {
  .hub-tile-outer-container {
    width: 33.3%;
  }
}

@media (max-width:1100px) {
  .hub-tile-outer-container {
    width: 50%;
  }
}

@media (max-width:900px) {
  .hub-tile-outer-container {
    width: 50%;

  }
}

@media (max-width: 700px) {
  .hub-tile-outer-container {
    width: 50%;
  }
}

@media (max-width: 500px) {
  .hub-tile-outer-container {
    width: 100%;
    padding: 0px 0.5em;
  }
}

.hub-tile-container:hover {
  box-shadow: inset 0 0 10px #0c808d3d;
}

.hub-tile-container .banner-image {
  max-width: 100%;
  height: auto;
  overflow: hidden;

}

.hub-tile-container .tile-image-container {
  text-align: center;
  height: 60px;
  min-width: 60px;
  border-radius: 50%;
  margin-right: 1em;
  border: none;
  overflow: hidden;
  background-size: cover;
  background-position: center center;
  border: 1px solid var(--colour-light-extra);
}

.hub-tile-container .overflow {
  display: inline-block;
  text-align: center;
  height: 92px;
  width: 92px;
  border-radius: 50%;
  border: 2px solid #888;
  overflow: hidden;
  background-size: cover;
  background-position: center center;
}

.hub-tile-container .select-container {
  height: 100px;
}

.hub-tile-container .overflow:hover {
  border: 1px solid #0c808d;
  box-shadow: 0 0 2px 2px #0c808d;
}

.hub-tile-container .hub-name-container {
  display: block;
}

.hub-tile-container .hub-name-container h3 {
  font-size: 1.15em;
  margin: 0px 0px 3px 10px;
}

.hub-tile-container .hub-name-container p {
  font-size: calc(0.7em + 0.3vw);
  margin: 0px 0px 0px 10px;
}

.hub-tile-container .fa-check {
  color: #10b5c6;
  font-size: 18px;
}

.hub-tile-container .hub-link {
  line-height: 50px;
}

.hub-tile-container .checkbox {
  height: 20px;
  width: 20px;
  border: 1px solid black;
  display: inline-block;
  position: relative;
  top: -30px;
  left: 35px;
  background: white;
}

.style1>.faq-caret-icon {
  background-image: url('../../resources/img/ant-design_plus-circle-outlined-blue.svg');
  background-position: right;
  background-repeat: no-repeat;
  height: 32px;
  width: 32px;
}

.style1>.faq-caret-icon-rotated {
  background-image: url('../../resources/img/akar-icons_circle-minus-blue.svg');
}

.style2>.faq-caret-icon {
  background-image: url('../../resources/img/ant-design_plus-circle-outlined.svg');
  background-position: right;
  background-repeat: no-repeat;
  height: 32px;
  width: 32px;
}

.style2>.faq-caret-icon-rotated {
  background-image: url('../../resources/img/akar-icons_circle-minus.svg');
}

/* Classes from the react-collapsible package */
.style1>.Collapsible {
  background-color: #E6F8FB;
  border-radius: 25px;
  border: 2.5px solid;
  margin-bottom: 1em;
  max-width: 629px;
  text-align: left;
  padding: 2em;
  border-color: #81C3D0;

}

.style2>.Collapsible {
  background-color: #FFF8E2;

  border-radius: 25px;
  border: 2.5px solid;
  margin-bottom: 1em;
  max-width: 629px;
  text-align: left;
  padding: 2em;
  border-color: #FAC832;
}



.Collapsible__trigger {
  font-size: 1.25em;
}

.Collapsible .question {
  font-family: var(--font-headings);
  margin-right: 15px;
  font-weight: bold;
  color: #646464;
}

.Collapsible__contentInner {
  font-size: 1.1em;
  font-family: var(--font-main);
  color: var(--colour-dark-main);
  margin-right: 50px;
  margin-top: 0.8em;
}

@media (max-width:600px){
  .Collapsible .question {
    font-size: 16px;
  }

  .Collapsible__contentInner{
    margin-right: 10px;
  }
}

.add-button-container {
  text-align: right;
  margin-top: 40px;
}

.add-button {
  background-color: var(--colour-accent-1);
  background-image: none;
  color: #ffffff;
  text-transform: uppercase;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  text-shadow: none;
}

.alert {
  margin: 6px;
  margin-top: -40px;
  color: #831f29;
  background-color: #f9d6da;
  border-color: #f4c2c7;
}

.login-error-container .feedback {
  color: #a94442 !important;
}

.login-container {
  padding: 5%;
}

.login-container .form-control {
  background-color: #f5f5f5;
  border-radius: 0px;
}

.login-container .name{
  display: inline-block;
  width: 48%;
}

.login-container .surname {
  margin-left: 4%;
}

.login-container .day{
  display: inline-block;
  width: 26%;
  margin-left: 5%;
}

.login-container .month{
  display: inline-block;
  width: 38%;
}

.login-container .year{
  display: inline-block;
  width: 26%;
  margin-left: 5%;
}

.login-container .control-label {
  display: block;
}

.login-container .modal-button {
  background-color: var(--colour-accent-1);
  background-image: none;
  color: #ffffff;
  text-transform: uppercase;
  border-color: #e59552;
  text-shadow: none;
  width: 30%
}

.login-container .modal-button-container {
  text-align: center;
}

.login-container .modal-header-container {
  text-align: center;
  padding-bottom: 25px;
}

.login-container .logo {
  display: inline-block;
  height: auto;
  width: 80%;
}

.login-container .multi-select {
  display: inline-block;
  width: 70%;
}

.login-container .multi-select-container {
  display: inline-block;
  text-align: center;
  width: 30%;
}

.login-container .multi-button-select {
  display: inline-block;
  background-color: #e59552;
  background-image: none;
  color: #ffffff;
  text-transform: uppercase;
  border-color: #e59552;
  width: 60%;
  text-shadow: none;
}

.login-container .selection-text {
  display: inline-block;
}

.login-container .selection-icon{
  display: inline-block;
  padding-left: 5px;
}

.login-container .selection-container {
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin: 5px;
  background-color: #eeeeee;
  padding: 5px;
}

.login-container .chosen-selection-container {
  padding: 10px;
}

.login-container .heading-container {
  padding-top: 10px;
}

.login-container .banner-image {
  height: 92px;
  width: 92px;
}

.login-container .banner-image-container {
  height: 92px;
  width: 92px;
}

.login-container .hub-tile-container {
  width: 92px;
  height: 150px;
}

.login-container .hub-name-container {
  width: 92px;
  font-size: 12px;
}

.login-container .section-container {
  padding: 50px 0px 10px 0px;
}

.login-container .feedback-container {
  text-align: center;
}

.login-container .reset-help-header, .login-container .reset-confirmed-header {
  margin: 10px 30px 0 30px;
  text-align: center;
  font-size: 1.1em;
}

.login-container .form-footer {
  width: 100%;
  margin-bottom: 10px;
}

.login-container .login-error-container {
  text-align: left;
  width: 50%;
  display: inline-block;
}

.login-error-container {
   text-align: center;
}

.login-container .password-reset-container {
  text-align: right;
  display: inline-block;
  width: 50%;
}

.login-container .reset-link {
  cursor: pointer;
}

.login-container .reset-link:hover {
  text-decoration: underline;
}

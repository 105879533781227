.module-header-container .banner-image {
  display: inline-block;
  max-width: 100%;
  height: auto;
}

.module-header-container .banner-image-container {
  text-align: center;
  height: 250px;
  width: 100%;
  overflow: hidden;
  background-color: #dadada;
  background-position: top center;
  background-size: cover;
}

.module-header-container .module-logo {
  height: 150px;
  width: 150px;
  border-radius: 50%;
  box-shadow: #ababab 0px 0px 20px;
  background-position: top center;
  background-size: cover;
  margin: 0 auto;
}

.module-header-container .module-logo:hover {
    -webkit-box-shadow: 0 0 2px 2px #0c808d;
    box-shadow: 0 0 20px 0px #0c808d;
}


.module-header-container .header-container {
  text-align: center;
  background-color: #f5f5f5;
  padding: 30px 0px;
  margin-bottom: 50px;
}

.module-header-container  button {
  background-color: var(--colour-accent-1);
  background-image: none;
  color: #ffffff;
  text-transform: uppercase;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  text-shadow: none;
}

.module-header-container button:hover {
  background-color: var(--colour-accent-1);
  background-image: none;
  color: #ffffff;
}

.module-header-container button:active {
  background-color: var(--colour-accent-1);
  background-image: none;
  color: #ffffff;
}

.module-header-container button:focus {
  background-color: var(--colour-accent-1);
  background-image: none;
  color: #ffffff;
}

.module-header-container .warning {
  background-color: var(--colour-warning);
}

.module-header-container .warning:hover {
  background-color: var(--colour-warning);
}

.module-header-container .warning:focus {
  background-color: var(--colour-warning);
}

.module-header-container .warning:active {
  background-color: var(--colour-warning);
  color: white;
}

.module-header-container .rating-controls-container {
  padding-top: 10px;
}

.module-header-container .module-details-container {
  padding-left: 25px;
}

@media (max-width: 992px) {
  .module-details-container .rating-controls {
      text-align: center;
      margin: 0px auto;
  }

  .module-details-container .thumbs-col {
    text-align: center;
    margin: 0px auto;
    padding-right: 0px;
  }

  .header-container {
    flex-direction: column;
  }

  .header-image-container {
    padding-left: 0px !important;
  }

  .module-header-container .module-details-container {
    padding-left: 0px;
  }
}

.module-header-container .header-container {
  display: flex;
}

.module-header-container .header-image-container {
  width: auto;
  padding-left: 3em;
}

.module-header-container .module-details-container {
  width: 100%;
}

.module-header-container .action-button-container-top {
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.rating-and-author-container {
  display: flex;
  flex-direction: row;
  justify-content: start;
  gap: 1.25rem;
}

@media (width <= 992px) {
  .rating-and-author-container {
    flex-direction: column;
    justify-content: center;
  }
}

.author-container {
  display: flex;
  flex-direction: column;
  line-height: 12px;
}
.create-fill-question .question-word {
  display: inline-block;
  margin: 0px 5px;
  padding: 5px;
  min-width: 30px;
  text-align: center;
  background-color: #f5f5f5;
}

.create-fill-question .question-word:hover {
  cursor: pointer;
  color: white;
  background-color:#10b5c6;
  border-radius: 25%;
}

.create-fill-question .instruction-text {
  font-weight: bold;
}

.create-fill-question .picked {
  text-decoration: line-through;
  color: #10b5c6;
}

.create-fill-question .words-container {
  margin: 20px 0px;
  min-height: 50px;
}






.walkthrough {
    text-align: center;
}

.walkthrough .top-section {
    margin: 30px auto;
    margin-top: 2.5em;
}

.walkthrough #logo {
    float: left;
    margin-left: 5%;
}

.walkthrough .heading {
    color: #14B4C8;
    font-weight: bold;
    font-size: 2.5em;
    text-align: center;
    padding: 0;
}

.walkthrough .subheading {
  font-style: normal;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: #646464;
  margin: 0 auto;
  width: 65%;
  margin-top: 2em;
}

.walkthrough .walkthrough-img {
  width: 80%;
  margin: 3em auto 2em auto;
}

.walkthrough .bottom-section {
    margin-top: 2.5em;
    border-top: 2px solid #14B4C8;
    background-color: #C4ECF1;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding-bottom: 2.5em;
}

.walkthrough .subheading .blue {
    color: #2D6773;
}

.walkthrough .option {
    background-color: white;
    margin: 30px auto;
    width: 450px;
    height: 85px;
    border-radius: 45px;
}

.walkthrough .option img {
    float: left;
    margin-left: 2em;
    margin-top: 1em;
}

.walkthrough .option p {
    padding-right: 2em;
    font-size: 16px;
    margin: 0 auto;
}

.walkthrough .selected {
    outline: 2.5px solid  #14b4c8;
}

.walkthrough .page-one-buttons {
  display: flex;
  gap: 20px;
  margin: auto;
  justify-content: center;
  margin: 0 2em;
}

.walkthrough .walkthrough-button, .skip-button {
    background-color: #14b4c8 !important;
    color: white !important;
    border-radius: 100px;
    box-shadow: 0px 4px 3px #bbb !important;
    font-weight: 700;
    font-size: 16px;
    padding: 10px 20px;
}

.walkthrough .skip {
  color: #646464!important;
  background-color: white !important;
  border-radius: 100px #14b4c8;
}

.walkthrough .walkthrough-button {
    width: 250px;
    height: 45px;
    margin-bottom: 15px;
  }

  .walkthrough .explore-button {
    width: 140px;
    height: 45px;
    margin-top: 20px;
  }

  .walkthrough .navigation {
    padding: 0 3em;

    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  .walkthrough .back-button {
    display: inline;
    font-size: 17px;
    font-weight: 700;
    color: #418E9E;
    border-bottom: 2px solid #418E9E;
    border-radius: 0;
    /* margin-right: 65%; */
  }

  .walkthrough .next-button {
    display: inline;
    font-size: 17px;
    font-weight: 700;
    color: #418E9E;
    border-bottom: 2px solid #418E9E;
    border-radius: 0;
  }

  @media (max-width: 1300px) {
    .walkthrough .heading {
      font-size: 3em;
    }
  }

  @media (max-width: 600px) {
    .walkthrough .heading {
      font-size: 2em;
    }

    .walkthrough .option {
      width: 450px;
    }

    .walkthrough .walkthrough-button {
      width: 450px;
    }
  }

  @media (max-width: 485px) {
    .walkthrough .heading {
      font-size: 1.8em;
    }

    .walkthrough .option {
      width: 329px;
    }

    .walkthrough .option p {
      font-size: 12px;
    }

    .walkthrough .walkthrough-button {
      width: 329px;
    }
  }

  @media (max-width: 425px) {
    .walkthrough .subheading {
      font-style: normal;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #646464;
      margin: 0 auto;
      width: 90%;
      margin-top: 1em;
    }

    .walkthrough .walkthrough-img {
      width: 90%;
      margin: 0.5em auto 1em auto;
    }

    .walkthrough .walkthrough-button {
      width: 229px;
  }
  }

.create-items {
    display: flex;
    flex-wrap: wrap;
    gap: 20px; 
    margin-top: 50px;
    margin-left: 40px;
    margin-bottom: 50px;
}

.create-item {

    width: 350px; 
    height: 200px; 
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); 
    cursor: pointer;
    display: flex;
    position: relative;
}
.create-item .btn{
    background-color: #14B4C8; 
    color: white;
    font-size: 30px;
    font-weight: bold;
    width: 100%;
    height: 100%;
    padding:  0px 0px 0px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box; 
}

.create-item:hover {
    background-color: #4899a8; 
    color: white;
}

.create-section h1{
    color: #14b4c8;
    font-weight: bold;
    margin-left: 40px;
    margin-top: 40px;
}
.box {
    position: relative;
    display: flex; 
    padding-bottom: 20px;
  
}

.box .info-icon {
    position: relative; /* Now the icon is positioned within create-item */
    top: 10px; /* Adjust these values as needed */
    right: 30px;
    margin: 2px;
    width: 20px;
    height: 20px;
    z-index: 1; /* Ensures the icon appears above the button */
}

.box .info-container {
    position: relative;
    display: inline-block; 
    z-index: 1;/* Ensure the container wraps the image */
}

.box .hover-text {
    display: none; /* Hide text initially */
    position: absolute;
    top: 20%; /* Adjust to position text below the image */
    left: 0%;
    transform: translateX(-90%);
    background-color: white;
    color: #646464;
    font-weight: bold;
    font-size: medium;
    padding: 5px;
    border-radius: 4px;
    white-space: normal; /* Allow text to wrap */
    width: 400px; /* Define a fixed width */
    max-width: 600px; /* Optional, can adjust the max width */
    word-wrap: break-word;
}

.info-icon:hover + .hover-text {
    display: block; /* Show the text when hovering on the image */
}
.create-body{
    display: flex;
    flex-wrap: wrap;
    padding-left: 30px;
    padding-top: 20px;
}

.main-div{
    display: block;
}
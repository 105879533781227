.rte-button {
  margin: 0 !important;
}

.icon-image {
  display: inline-block;
  width: 22px;
  height: 22px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 18px;
  background-image: url("../../resources/img/image.svg");
  background-size: 14px;
}

.RichTextEditor__root___2QXK- {
  border-radius: 16px !important;
  background-color: #FFF8E2 !important;
}

.embed-icon-image {
  display: inline-block;
  width: 22px;
  height: 22px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 18px;
  background-image: url("../../resources/img/embedvideo.svg");
  background-size: 14px;
}

.loading {
  width: 22px;
  height: 22px;
  font-size: 1.2em;
  font-weight: bold;
}

.embed-wrap {
  display: inline-block;
  position: relative;
  z-index: 10;
}

.embed-popover::before {
  content: "";
  display: block;
  position: absolute;
  width: 0;
  height: 0;
  left: 10px;
  top: -10px;
  border: 5px solid transparent;
  border-bottom-color: #999;
}

.embed-popover::after {
  top: -9px;
  border: 5px solid transparent;
  border-bottom-color: #fdfdfd;
  content: "";
  display: block;
  position: absolute;
  width: 0;
  height: 0;
  left: 10px;
}

.embed-popover {
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  width: 180px;
  background: none #fdfdfd;
  background: linear-gradient(180deg,#fdfdfd 0,#f6f7f8);
  border: 1px solid #999;
  border-radius: 2px;
  box-sizing: border-box;
  padding: 4px;
  text-align: center;
}

.embed-popover-inner {
  display: block;
}

.embed-popover-inner > input {
  display: block;
  flex: 1 0 auto;
  height: 30px;
  background: none #fff;
  border: 1px solid #999;
  border-radius: 2px;
  box-sizing: border-box;
  padding: 2px 6px;
  font-family: inherit;
  font-size: inherit;
  line-height: 24px;
}

.embed-popover-accept-decline {
  margin-top: 0.25em;
}

#decline {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: 0;
}

.accept-icon {
  display: inline-block;
  width: 11px;
  height: 11px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 18px;
  background-image: url("../../resources/img/accept.svg");
  background-size: 14px;
}

#accept {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.decline-icon {
  display: inline-block;
  width: 11px;
  height: 11px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 18px;
  background-image: url("../../resources/img/decline.svg");
  background-size: 14px;
}


.embed-popover-accept-decline > button {
  margin-right: 0;
  border-radius: 0;
  display: inline-block;
  padding: 3px 8px;
  height: 30px;
  line-height: 22px;
  box-sizing: border-box;
  background: none #fdfdfd;
  background: linear-gradient(180deg,#fdfdfd 0,#f6f7f8);
  border: 1px solid #999;
  border-radius: 2px;
  color: #333;
  text-decoration: none;
  font-size: inherit;
  font-family: inherit;
  cursor: pointer;
  white-space: nowrap;
}

/*
span[class^='ImageSpan_'] {
  letter-spacing: 0 !important;
  background-size: contain !important;
  max-width: 100% !important;
  height: auto !important;
  line-height: 200px !important;
}
*/

.popover-sizes button {
  border: 1px solid transparent;
  margin-top: 0.25em;
  margin-left: 0.25em;
  margin-right: 0.25em;
  height: 2em;
  width: 2em;
}

.popover-sizes .selected {
  border: 1px solid black;
}

.DraftEditor-editorContainer {
  padding: 6px 12px;
  height: 500px;
}

.public-DraftEditor-content {
  height: 420px;
  font-family: sans-serif;
}


.home-tab-container .no-modules {
  padding-top: 2.5%;
  font-size: 12pt;
  text-align: center;
  width: 100%;
}

.home-tab-container .create-module-button {
  margin-top: 25px;
  background-color: #14B4C8;
  background-image: none;
  color: #ffffff;
  text-transform: uppercase;
  border-color: #e59552;
  padding: 10px;
  margin-bottom: 10px;
  text-shadow: none;
}

.home-tab-container .section-title {
  font-family: var(--font-main) !important;
  color: var(--colour-accent-1);
  font-size: 1.5em;
  font-weight: bold;
}

.home-tab-container .featured-modules{
  background-color: #E6F8FB;
  margin-left: -3em;
  padding: 1em 3em 3em 4em;
  margin-right: -3em;
}

.home-tab-container .featured-folders{
  margin-left: -3em;
  padding: 1em 3em 3em 4em;
  margin-right: -3em;
}

.home-tab-container .featured-modules .tile-container{
  background-color: #ffffff;
}

.home-tab-container .featured-modules .section-title, .featured-folders .section-title{
  color: #646464;
  text-align: left;
  font-size: 25px;
  font-weight: bold;
  font-family: var(--font-main) !important;

}
.home-tab-container .featured-folders .section-title{
  color: #418E9E;
}


.home-tab-container .featured-modules .sub-title{
  color: #646464;
  font-size: 1em;
  text-align: left;
  margin-top: 2em;
  font-weight: bold;
}

.home-tab-container .featured-modules .sub-title, .featured-folders .sub-title{
  color: #646464;
  font-size: 1em;
  text-align: left;
  margin-top: 2em;
  font-weight: bolder;
}

.home-tab-container .no-modules .description, .featured-folders .description{
  text-align: left;
  color: #646464;
  margin-top: 0.8em;
  width:75%;
  margin-bottom: 3em;
}



.home-tab-container .select-featured-button {
  margin-top: 25px;
  background-color: #ffffff;
  background-image: none;
  color: #418E9E;
  border-color: #418E9E;
  padding: 10px;
  margin-bottom: 10px;
  text-shadow: none;
  border-radius: 25px;
  border: 2px solid;
}

.home-tab-container .file-background{
  background-size: cover;
  margin-top: -30%;
  z-index: 1;
  padding-bottom: 5%;
  height: 70%;
}


.home-tab-container .file-background .module-name-container{
  padding: 10% 10px 0px 7%;
  font-size: 18px;
}

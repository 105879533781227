.faq-page-container {
  margin-bottom: 15%;
}

.faq-page-container .top-text {
  font-weight: bold;
  color: #14B4C8;
  text-transform: uppercase;
  font-size: 18px;
}

.faq-page-container .title-text {
  font-weight: bold;
  color: #2D6773;
  font-size: 48px;
  text-align: center;
  padding-top: 0.3em;
}

.faq-page-container .main-text {
  display: block;
  text-align: center;
  color: #646464;
  font-size: 20px;
  max-width: 658px;
  margin-top: 1em;
  margin-bottom: 2.5em;
}

.faq-page-container .personas {
  width: 669px;
  display: flex;
  justify-content: space-between;
}

.faq-page-container .personas>div {
  cursor: pointer;
}

.faq-page-container .personas>img {
  max-width: 325px;
}

.faq-page-container .helping-others-image{
  content:url("../../resources/img/helping_others.svg")
}

.faq-page-container .personal-use-image{
  content:url("../../resources/img/personal_use.svg")
}


.faq-page-container .top-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-top: 4em;
  padding-left: 5em;
  padding-right: 5em;
  padding-bottom: 2em;

}

.faq-page-container .firstDiv {
  position: relative;
  max-width: 629px;


  margin: 0 auto;
  padding: 10px;
  padding-top: 100px;

}

.faq-page-container .right-col {
  position: absolute;
  left: 95%;

  width: 100px;
  height: 300px;

  padding: 10px;
  padding-top: 0px;
  text-align: left;
  margin-left: 40px;
}

.faq-page-container .right-col .practitioner {
  content:url('../../resources/img/practitioner.svg')
}

.faq-page-container .right-col .user-icon {
  content:url('../../resources/img/user_icon.svg')
}

.faq-page-container .top-text-grey {
  font-weight: bold;
  color: #646464;
  text-transform: uppercase;
  font-size: 20px;
  padding-bottom: 1em;
}

.faq-page-container .main-text-faq {
  color: #646464;
  font-size: 20px;
  max-width: 629px;
  padding-bottom: 1em;
}

.faq-page-container .blue-button {
  background-color: #14b4c8 !important;
  color: white !important;
  border-radius: 100px;
  box-shadow: 0px 4px 3px #bbb !important;
  font-weight: bold;
  font-size: 16px;
  padding: 10px 20px;
}

.faq-page-container .blue-button-container {
  text-align: right;
  flex-direction: row;
  align-items: flex-end;
  max-width: 629px;
  margin-top: 25px;
}

@media (max-width: 768px) {
  .faq-page-container .right-col .user-icon {
    display: none;
  }

  .faq-page-container .right-col .practitioner {
    display: none;
  }
}

@media (max-width: 600px){
  .faq-page-container .right-col .user-icon {
    display: block;
  }

  .faq-page-container .right-col .practitioner {
    display: block;
  }

  .faq-page-container .top-container {
    padding-top: 3em;
    padding-left: 2.5em;
    padding-right: 2.5em;
    padding-bottom: 2em;
  }

  .faq-page-container .title-text{
    font-size: 32px;
  }

  .faq-page-container .main-text{
    font-size: 16px;
    margin-top: 0.5em;
    margin-bottom: 1em;
  }

  .faq-page-container .personas {
    width: auto;
    display: flex;
    justify-content: space-between;
  }

  .faq-page-container .helping-others-image{
    content:url("../../resources/img/helping_others_mobile.svg");
    margin-right: 18px;
  }

  .faq-page-container .personal-use-image{
    content:url("../../resources/img/personal_use_mobile.svg")
  }

  .faq-page-container .main-text-faq {
    color: #646464;
    font-size: 16px;
  }

  .faq-page-container .firstDiv {
    text-align: center;
    padding-left: 2.5em;
    padding-right: 2.5em;
  }

  .faq-page-container .top-text-grey {
    padding-bottom: 1.5em;
  }

  .faq-page-container .right-col {
    position: relative;
    left: 0px;
    margin: auto;
    min-width: 248px;
    height: 300px;

    padding: 0px;
    padding-top: 0px;
  }

  .faq-page-container .right-col .practitioner {
    content:url('../../resources/img/practitioner.svg');
  }

  .faq-page-container .right-col .user-icon {
    content:url('../../resources/img/user_icon.svg')
  }
}

@media (max-width: 375px){
  .faq-page-container .helping-others-image{
    margin-right: 5px;
  }
}

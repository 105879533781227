.free-text-question-container {
  background-color: rgba(250, 200, 50, 0.35);
  padding-left: 20px;
  padding-bottom: 20px;
  border-radius: 25px;
  margin-top: 20px;
}
.free-text-question-container .question-text-container {
  font-weight: bold;
  padding-top: 15px;
  width: 80%;
  display: inline-block;
  padding-left: 10px;
  color: #646464;
}

.free-text-question-container .control-label {
  margin-bottom: 10px;
  width: 100% !important;
}

.create-module-tab-container .free-text-question-container .form-control {
  width: 96% !important;
  height: 150px;
}

.free-text-question-container .answers-container {
  padding-left: 10px;
}

.free-text-question-container ol {
  padding-left: 20px;
  list-style: outside;
  list-style-type: decimal;
  display: block;
}

.free-text-question-container .review-container {
  width: 100%;
  text-align: center;
}

.free-text-question-container .review-container button {
  margin: 20px;
}

.free-text-question-container button {
  background-color: green;
  background-image: none;
  color: #ffffff;
  text-transform: uppercase;
  border-color: green;
  text-shadow: none;
  font-size: 14px;
  font-weight: normal;
  border: 1px solid transparent;
  border-radius: 4px;
}

.free-text-question-container .review-container .correct-button:hover {
  background-color: green;
  color: #FFF;
}

.free-text-question-container .review-container .correct-button:focus {
  background-color: green;
  color: #FFF;
}

.free-text-question-container .review-container .incorrect-button {
  background-color: #ca3e25 !important;;
  color: #FFF !important;;
  text-shadow: none;
}

.free-text-question-container .review-container .incorrect-button:hover {
  background-color: #ca3e25 !important;;
  color: #FFF !important;;
}

.free-text-question-container .review-container .incorrect-button:focus {
  background-color: #ca3e25 !important;
  color: #FFF !important;;
}

.free-text-question-container .fa-check {
  padding-right: 20px;
}

.create-plan-session-tab-container .main-col {
  padding: 0 2.5%
}

.create-plan-session-tab-container .sidebar {
  padding: 2.5% 5% 0 0;
}


.create-plan-session-tab-container .form-heading {
  margin: 20px 0px 40px 0px;
  font-weight: bold;
  color: #14B4C8;
  font-size: 28px;
}

.create-plan-session-tab-container .form-input {
  display: inline-block;
  width: 80%;
}

.create-plan-session-tab-container .form-control {
  display: inline-block;
  float: right;
  width: 60%;
  background-color: #FFF8E2;
  border-radius: 36.5px;
}

.create-plan-session-tab-container .padding-line {
  height: 1px;
  width: calc(100%+224px);
  background-color:var(--colour-light-extra);
  margin: 30px -112px 30px -112px;
}

.create-plan-session-tab-container .control-label {
  font-size: 18px;
  color: #646464;
}

.create-plan-session-tab-container .form-group {
  margin-bottom: 40px;
}

.create-plan-session-tab-container .text-area {
  width: 100%;
}

.create-plan-session-tab-container .form-text-area {
  display: block;
  width: 100%;
}

.create-plan-session-tab-container .sidebar .control-label {
  width: 100%;
}

.create-plan-session-tab-container .sidebar .checkbox {
  width: 50%;
  display: inline-block;
}

.create-plan-session-tab-container .fa-calendar {
  color: #10b5c6;
}

.create-plan-session-tab-container .date-picker {
  width: 60%;
  float: right;
}

.create-plan-session-tab-container .users-title {
  padding: 30px 0 10px 0;
  font-size: 18px;
  color: #646464;
  /* font-family: "Roboto"; */
  font-weight: 900;

}

.create-plan-session-tab-container .section-container {
  margin-left: -45px;
}


.create-plan-session-tab-container .user-tile-container .checkbox {
  position: relative;
  margin-left: auto;
  order: 2;
}

.create-plan-session-tab-container .user-tile-container {
  display: flex;
  align-items: center;
  width: auto;
  height: auto;
  margin-top: 30px;
}

.create-plan-session-tab-container .user-link{
  text-align: left;
  width: fit-content;
  margin-left: 35px;
  color: #646464;
  font-weight: 700;
}

.create-plan-session-tab-container .avatar-container {
  display: inline-flex;
  width: 69px;
  height: 69px;

}

.create-plan-session-tab-container .DraftEditor-editorContainer {
  padding: 6px 12px;
  height: 200px;
}

.create-plan-session-tab-container .public-DraftEditor-content {
  height: 190px;
  font-family: sans-serif;
}

.create-plan-session-tab-container .module-select-heading {
  font-size: 18px;
  color: #646464;
}

.create-plan-session-tab-container hr{
  border-top: none;
}

.create-plan-session-tab-container .change-featured-image-button-container {
  display: inline-block;
  text-align: right;
  width: 150px;
}
.create-plan-session-tab-container .change-featured-image-button-container btn {
  width: 20%;
}

.create-plan-session-tab-container .featured-title {
  display: inline-block;
  width: 80% !important;
}

.create-plan-session-tab-container .form-button-container-featureImage {
  display: inline-block;
  text-align: right;
  width: 20%;
  position: relative;
  margin-right: 5px;
}

.create-plan-session-tab-container .attach-button-featureImage {
  width: 80%;
  position: absolute;
  bottom: 0;
  right: 0;
}

.create-plan-session-tab-container .btn {
  background-color: #FEE391;
  font-family: var(--font-main);
  background-image: none;
  color: #CAA32D;
  font-weight: bold;
  text-shadow: none;
  padding: 10px;
  border-radius: 20px;
  width: 100%;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%) !important;
}

.create-plan-session-tab-container .publish-button-container {
  text-align: center;
  clear: both;
}

.create-plan-session-tab-container .publish-button {
  background-color: var(--colour-accent-1);
  font-family: var(--font-main);
  background-image: none;
  color: #ffffff;
  text-shadow: none;
  padding: 10px;
  margin-top: 60px;
  margin-bottom: 10px;
  width: 180px !important;


}

.create-plan-session-tab-container .sub-category-title{
  font-size: 18px;
  color: #646464 !important;
  font-weight: 500;
}

.create-plan-session-tab-container .sub-category-heading{
  font-size: 15px;
  color: #646464 !important;
  font-weight: 400;
  display: inline-block;
}

.create-plan-session-tab-container .col-md-4{
  padding-left: 0px;
}

.create-plan-session-tab-container .multi-select-container {
  display: block;
  text-align: center;
  width: 30%;
  float: right;
  margin-top: 10px;
}

/* multi-button-select chosen-goals-container */


.create-plan-session-tab-container .activity-select {
  display: inline-block;
  width: 70% !important;
  flex:1;
  background: #FFF8E2;
  border-radius: 15px;
}

.create-plan-session-tab-container .sub-categories-title-container {
  display: inline-block;
  vertical-align: top;
  clear: both;
}

.create-plan-session-tab-container .sub-categories-container {
  display: inline-block;
  vertical-align: top;
  float: right;
  width: 70%;
  padding-top: 4px;
}

/* .create-plan-session-tab-container .sub-categories-container .btn{
  float: right;
  margin: 2px;
} */

.create-plan-session-tab-container .activities-container {
  margin-bottom: 15px;
}

.create-plan-session-tab-container .multi-select-container {
  display: block;
  width: 100%;
  float: right;
  margin-top: 2px;
  margin-bottom: 10px;
}

.create-plan-session-tab-container .multi-button-select {
  background-color:#FAC832;
  background-image: none;
  color: #ffffff;
  text-transform: uppercase;
  text-shadow: none;
  font-size: 14px;
  font-weight: normal;
  border: 1px solid transparent;
  border-radius: 20px;
  padding: 5px 15px 5px 15px;
  margin: 5px 0px 10px 0px;
  float: right;
  width: 100px;
}

.create-plan-session-tab-container .selection-icon{
  display: inline-block;
  padding-left: 5px;
}

.create-plan-session-tab-container .selection-container {
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 15px;
  margin: 5px;
  background-color: #FAC832;
  padding: 5px;
}

.create-plan-session-tab-container .selection-text {
  display: inline-block;
  color: white;
}

.create-plan-session-tab-container .react-date-picker__button{
  width: 100%;
  border-radius: 36.5px;
  padding: 0px 12px;
  background-color: #FFF8E2;
  border: 1px solid #ccc;
  color: #555555;
}

.create-plan-session-tab-container .plan-list-container {
  flex-wrap: wrap-reverse;
  margin-top: 20px;
}

.create-plan-session-tab-container .plan{
  display: inline-flex;
  margin-right: 50px;
}

.create-plan-session-tab-container .plan-info-container{
  text-align: center;
}

.create-plan-session-tab-container .plan-image-container {
  height: 120px;
  width: 100%;
  max-width: 210px;
  overflow: hidden;
}

.create-plan-session-tab-container .plan-image{
  height: auto;
  width: 100%;
}

.create-plan-session-tab-container .plan-image-container-selected{
  height: 120px;
  width: 100%;
  max-width: 210px;
  overflow: hidden;
  border:4px solid #FAC832;
}

.create-plan-session-tab-container .plan-title{
  font-weight: bold;
  font-size: 16px;
  margin-top: 5px;
}

.create-plan-session-tab-container .plan-creator {
  font-size: 12px;
  margin-top: 5px;
  color: #646464;
}

.create-plan-session-tab-container .checkbox input[type="checkbox"], .checkbox-inline input[type="checkbox"] {
  transform: scale(1.5);
  filter: invert(70%) hue-rotate(358deg) brightness(1.7);
  margin-right: 5px;
  margin-top: -7px;
}

.img1 {
  display: none;
}

.img1[src] {
 display: block;
}

.create-plan-session-tab-container .input-image-container{
  display: flex;
  float: right;
  align-items: flex-end;
}

.create-plan-session-tab-container .sub-categories{
  clear: both;
  padding-top: 35px;
}

.create-plan-session-tab-container .visibilty {
  /* display: block; */
  flex: 0.30;
  margin: 10px;
  border-radius: 20px;
  background-color: #FFF8E2;
  color: #646464;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 25px 5px 10px;
  height: 3em;
}

.create-plan-session-tab-container .visibility-selected {
  /* display: block; */
  flex: 0.30;
  margin: 10px;
  border-radius: 20px;
  border-style: solid;
  border-color: #FAC832 !important;
  background-color: #FFF8E2;
  color: #646464;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 5px 5px 10px;
  height: 3em;
}

.create-plan-session-tab-container .member-search {
  margin-top: 90px;
}

.create-plan-session-tab-container .back-button {
  background-color: #14B4C8;
  font-family: var(--font-main);
  background-image: none;
  color: white;
  text-transform: uppercase;
  text-shadow: none;
  padding: 10px 20px 10px 20px;
  border-radius: 20px;
  width: auto;
}

@media (max-width: 1000px) {
  .create-plan-session-tab-container {
    margin: 0px 20px;
  }
}

.create-quiz-question {
  padding: 5% 5% 7% 5%;
}

.create-quiz-question .modal-title {
  text-align: center;
  font-weight: bold;
  /* font-family: "Roboto"; */
  padding-bottom: 50px;
  color: #14B4C8;
}

.create-quiz-question .modal-controls {
  text-align: center;
}
.create-quiz-question .modal-control {
  display: inline-block;
  margin: 10px 10px 0 10px;
}

.create-quiz-question .modal-header-container {
  text-align: center;
}

.create-quiz-question .modal-button-container {
  text-align: center;
}

.create-quiz-question .form-group{
  margin-bottom: 25px;
}

.create-quiz-question .control-label {
  display: block;
  /* font-family: "Roboto"; */
  font-weight: bold;
  color: #646464;
  font-size: 20px;
}


.create-quiz-question .form-control {
  background-color: #FFF8E2;
  border-radius: 20px;
  border: none;
}

.create-quiz-question .modal-button {
  background-image: none;
  color: #418E9E;
  text-transform: uppercase;
  text-shadow: none;
  font-weight: bold;
  float: right;
}

.create-quiz-question .add-answer-button {
  display: inline-block;
  float: right;
  background-color: rgba(250, 200, 50, 0.35);
  background-image: none;
  color: #CAA32D;
  text-transform: uppercase;
  border-color: #e59552;
  text-shadow: none;
  font-weight: bold;
  border-radius: 15px;
  padding-right: 20px;
  padding-left: 20px;
}

.create-quiz-question .multi-select {
  display: inline-block;
  width: 80%;
  border-radius: 20px;
}

.create-quiz-question .inline-label {
  display: block;
  width: 20%;
  /* font-family: "Roboto"; */
  font-weight: bold;
  color: #646464;
  font-size: 20px;
}

.create-quiz-question .inline-label-question-type {
  display: inline-block;
  width: 20%;
  /* font-family: "Roboto"; */
  font-weight: bold;
  color: #646464;
  font-size: 20px;
}

.create-quiz-question .answer-text {
  display: inline-block;
  width: 90%;
}

.create-quiz-question .multi-select-container {
  display: inline-block;
  text-align: center;
  width: 30%;
}

.create-quiz-question .selection-text {
  display: inline-block;
}

.create-quiz-question .selection-icon{
  display: inline-block;
  padding-left: 5px;
}

.create-quiz-question .selection-container {
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin: 5px;
  background-color: #eeeeee;
  padding: 5px;
}

.create-quiz-question .chosen-selection-container {
  padding: 10px;
}

.create-quiz-question .section-container {
  padding: 50px 0px 10px 0px;
}

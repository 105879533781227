.top-nav {
  margin-bottom: 0px;
  padding: 10px 0;
  min-width: 320px;
  display: flex;
  align-items: center;
  flex-direction: row;
  padding-left: 235px;
}

.navbar-fixed-top {
  border: none;
}

.navbar-default {
  background-color: white;
  border: none;
  border-bottom: 1px solid #eeeeee;
  box-shadow: none;
  background-image: none;
  border-radius: 0;
}

.container {
  
  display: flex;
  align-items: center;

}

@media (max-width: 1023px) {
  .container {
    flex-direction: column;

  }
}


.top-nav .fa {
  color: black;
}

.top-nav .search-icon {
  display: none;
}

.fa-2x {
  font-size: 1.8em;
}

.top-nav .avatar-menu {
  display: none;
}

.top-nav .container {
  width: 100%;
  padding: 0;
}

.top-nav.navbar.navbar-default.navbar-fixed-top {
  background-color: var(--colour-light-side);
  background-image: none;
}

.top-nav .navbar-default .navbar-brand {
  color: #0C2340;
}

.top-nav .navbar-default .navbar-nav li a {
  color: #0C2340;
  font-size: 20px;
}

.top-nav .navbar-default .navbar-nav li:hover a:hover {
  color: #9fcc62
}

.top-nav .navbar-form {
  display: inline-block;
  padding-left: 0;
  height: 53px;
  margin: 0px;
  width: auto;
  padding-top: 0;
  padding-bottom: 0;
  margin-right: 0;
  margin-left: 0;
  border: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.top-nav .form-group {
  display: flex;
  align-items: center;
  height: inherit;
  width: 100%;
  margin-right: 8px;
  margin-left: 20px;
  padding-left: 10px;
}

.top-nav .nav-search {
  border-radius: 19px;
  padding: 1em;
  border: 1px solid var(--colour-light-extra);
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: none;
  height: 2.5em;
  width: 100px;
}
 .nav-search-signIn {
  border-radius: 19px;
  padding: 1em;
  border: 1px solid var(--colour-light-extra);
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: none;
  height: 2.5em;
  width: 100px;
}

.top-nav .search-button {
  background-color: var(--color-background-darkest-role);
  border-top-right-radius: 19px;
  border-bottom-right-radius: 19px;
  padding: 1em;
  padding-left: 0.5em;
  height: 2.5em;
  cursor: pointer;
}

.top-nav .search-button svg {
  font-size: 1.4em;
  color: white;
  display: block;
  margin-top: -0.25em;
}

.top-nav .navbar-right {
  margin: 8px 0;
  display: flex;
}

.top-nav .navbar-nav>li {
  margin: 0px 5px;
}

.top-nav .navbar-nav>li>a {
  padding: 0px 5px;
  background-image: none !important;
  box-shadow: none !important;
}

.top-nav .burger {
  color: var(--colour-dark-extra) !important;
  float: left;
  margin-left: 25px;
  padding: 10px 0;
  font-size: 1.6em;
  display: none;
}

.top-nav .burger:hover {
  cursor: pointer;
}

.top-nav .username {
  text-transform: capitalize;
}

.top-nav .username a {
  line-height: 30px;
}

.top-nav .avatar-image, .top-nav .avatar-menu-image {
  height: 25px;
  width: 25px;
  border-radius: 5px;
  background-position: center center;
  background-size: cover;
  border: none;
  padding: 0.1em;
}

.top-nav .notification {
  height: 9px;
  width: 9px;
  background-color: red;
  border-radius: 50%;
  position: absolute;
  top: 2px;
  left: 20px;
}

.top-nav .avatar-menu .dropdown-menu {
  position: absolute !important;
  min-width: 0px;
}

.top-nav .username {
  max-width: 200px;
}

.top-nav .logo-container {
  padding-left: 1em;
}

.top-nav .username .dropdown-toggle {
  text-transform: capitalize;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.right-section-nav .search-button {
  background-color: #14b4c8;
  border-top-right-radius: 19px;
  border-bottom-right-radius: 19px;
  padding: 1em;
  padding-left: 0.5em;
  height: 2.5em;
  cursor: pointer;
}

.navbar-search-container {
  display: flex;
  width: 100%;
  margin-top: 10px; /* Adjust for spacing */
}
.left-mid-right-nav-container{
  display: flex;
  flex-direction: row;
  width: 90%;
}
.Navbar-container-signIn {
  width: 100%;
  height: 60px;
  box-shadow: 0 1px 3px rgba(15, 15, 15, 0.13);
  display: flex;
  align-items: center;
}

.Navbar-container-signIn .left-section-nav{
  display: flex;
}

.Navbar-container-signIn .middle-section-nav{
  display: flex;
  flex: 2;
  height: 100%;
  justify-content: center;
}

.Navbar-container-signIn .right-section-nav{
  display: flex;
}
@media (max-width: 778px) {
  .top-nav.navbar.navbar-default.navbar-fixed-top {
    background-color: #EFFBFD;
    border-bottom: none;
  }
}

@media (max-width: 1105px) {
  .top-nav .username {
    max-width: 180px;
  }

  .top-nav .nav-logo {
    height: 40px;
  }
}

@media (max-width: 995px) {
  .top-nav .navbar-nav>li {
    margin: 0px 5px;
  }
}

@media (max-width: 950px) {
  .top-nav .username {
    max-width: 160px;
  }
}

@media (max-width: 885px) {
  .top-nav .username {
    max-width: 150px;
  }
}

@media (max-width: 845px) {
  .top-nav .username {
    max-width: 100px;
  }

  .non-sign-up {
    display: none !important;
  }
}

@media (max-width: 1023px) {
 .nav-search-signIn {
    width: 50%;
    margin-left: 1em;
  }
}

@media (max-width: 1023px) {
  .top-nav .navbar-header {
    float: left;
    width: 100%;
    text-align: center;
    margin-right: 0;
    margin-left: 0;
  }

  .top-nav .nav-search {
    width: 80%;
    margin-left: 1em;
  }

  .nav-icon {
    display: flex !important;
  }
}

@media (max-width: 520px) {
  .create-text {
    display: none;
  }

  .plus-icon {
    margin-right: 0px !important;
    font-size: smaller !important;
  }

  .create-btn {
    padding: 0.5em !important;
  }

  .fa-2x {
    font-size: 1.4em;
  }

  .top-nav .avatar-image, .top-nav .avatar-menu-image {
    margin-right: 10px;
  }

}

@media (max-width: 400px) {
  .top-nav .navbar-header {
    width: 130px;
  }

  .top-nav i {
    font-size: 16pt;
  }

  .top-nav .search-icon {}

  .top-nav .fa-bell-o {
    margin-top: 5px;
  }
}

@media (max-width: 365px) {}

.top-nav .nav-icon {
  display: none;
  max-height: 40px;
}

.top-nav .nav-logo {
  max-height: 50px;
}

.top-nav .navbar-header {
  width: auto;
  height: 53px;
  display: flex;
  align-items: center;
  text-align: center;
}

@media (max-width: 400px) {
  .btn {
    font-size: smaller;
  }
}

.top-nav a#user-drop-down {
  color: #fff !important;
  background-color: transparent !important;
}

.caret {
  display: none !important;
}

.bell-icon {
  color: #646464;
}

.notification-content {
  width: 200px;
}

.notification-title {
  margin-bottom: 0.25em;
  font-weight: bold;
  font-size: 1.25em;
  text-align: center;
}

.notification-item-icon {
  color: grey !important;
  font-size: 0.5em !important;
  margin-right: 0.3em;
}

.small-timestamp {
  color: grey;
  font-size: 0.75em;
}

.view-notifications {
  border-radius: 0;
  background-color: rgb(231, 231, 231);
}

.create-btn {
  font-family: var(--font-main) !important;
  color: white !important;
  font-weight: 100;
  font-size: 0.9em;
  height: 35px;
  background-image: none;
  background-color: var(--colour-dark-main) !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0px;
  border: none !important;
  outline: none !important;
  padding: 1em;
  border-radius: 4px;
}

.create-btn:hover, .bell-icon:hover {
  opacity: 0.8;
}

.create-icon {
  color: grey;
  font-size: 1.75em !important;
  width: 25px !important;
  margin-right: 0.5em;
  text-align: center;
}

.navbar-nav>li>.dropdown-menu {
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  margin-top: 5px;
}

.plus-icon {
  font-size: 1.25em;
  margin-right: 0.25em;
  font-weight: 400;
  padding-bottom: 0.1em;
}

.nav-loggedin {
  align-items: center;
  justify-content: center;
}

.sign-up {
  border: 1px solid var(--colour-dark-main) !important;
  border-radius: 0.5em;
  padding: 0.5em;
}

.login {
  border-radius: 0.5em;
  padding: 0.5em;
  background-color: var(--colour-accent-1);
}

.login a {
  color: var(--colour-light-main) !important;
}

.btn {
  border: none;
  background-image: none !important;
  box-shadow: none !important;
}

.navbar-default .navbar-nav>li>a {
  color: var(--colour-dark-main);
}

.not-registered {
  margin-right: 1em !important;
}

.extender {
  width: 100%;
  height: 1px;
}

.create-hub-btn {
  border-radius: 100px;
  font-weight: bold;
  font-size: 16px;
  padding: 5px 5px;
  border: 2px solid #646464;
  font-size: 1em;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
}

.top-nav .top-nav-user {
  display: flex;
  align-items: center;
  color: #646464;
  font-weight: bold;
  font-size: 0.9em;
  background-color: var(--color-background-darker-role);
  border-radius: 15px;
  padding: 0.5em;
}

.top-nav .top-nav-user span {
  width: 100px;
}

.dropdown-menu hr {
  margin: 0;
}

.dropdown-menu .switch {
  background-color: var(--color-background-darker-role-other)
}

.top-nav .notification-container {
  display: block;
  width: 225px;
  color: #646464;
}

.top-nav .notification-container hr {
  border-width: 3px;
  margin-bottom: 0.5em;
  margin-top: 0.25em;
}

.notification-container .notification-header {
  display: block;
  font-weight: bold;
  font-size: 1.25em;
  text-align: center;
}

.notification-container .notification-item-text {
  padding: 0.5em 1em;
  display: block;
}

.notification-button-view {
  text-align: center;
  background-color: #14B4C8;
  border-radius: 13px;
  color: white;
  font-weight: bold;
  font-size: 1.2em;
  padding: 0.2em;
  cursor: pointer;
}

.notification-container .notification-list{
  height: 250px;
  overflow-y: scroll;
}

.notifications-drop-down .dropdown-menu > li > a:hover {
  background-color: transparent;
  background-image: none;
}



.top-nav .top-nav-signed-out {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.top-nav .top-nav-signed-out > * {
  flex: 25%;
  text-align: center;
}

.top-nav .top-nav-signed-out .top-nav-links {
  flex: 50%;
  display: flex;
  width: 100%;
  gap: 1.5em;
  justify-content: center;
  align-items: baseline;
}

.top-nav .top-nav-signed-out .top-nav-links span {
  cursor: pointer;
  font-weight: bold;
  color: #646464;
  font-size: 16px;
}

.top-nav .top-nav-signed-out .top-nav-links .selected {
  color: #1090A0;
}

.top-nav .top-nav-signed-out .top-nav-buttons {
  display: flex;
  justify-content: right;
  gap: 1em;
  align-items: center;
  padding-right: 1em;
}


.top-nav-buttons .top-nav-register-btn {
  background-color: #14b4c8 !important;
  color: white !important;
  border-radius: 100px;
  box-shadow: 0px 4px 3px #bbb !important;
  font-weight: bold;
  font-size: 16px;
  padding: 10px 20px;
  cursor: pointer;
}

.top-nav-buttons .top-nav-signin-btn {
  text-decoration: underline;
  font-size: 16px;
  font-weight: bold;
  color: #646464;
  cursor: pointer;
}
.top-nav .top-nav-signed-out-mobile {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.top-nav .top-nav-signed-out-mobile .top-nav-hamburger {
  padding-right: 1em;
}

.top-nav .top-nav-signed-out-mobile .nav-links-wrapper {
  background-color: #EFFBFD;
  border-bottom: #14B4C8 2px solid;
}

.top-nav .top-nav-signed-out-mobile .nav-links-item {
  color: #646464;
}

@media (max-width: 921px) {
  .top-nav-buttons svg {
    display: none;
  }
}

@media (max-width: 768px) {

  .top-nav .mobile {
    background-color: #EFFBFD;
  }

  .nav-links-item .top-nav-signin-btn{
    text-decoration: underline;
    font-size: 16px;
    font-weight: bold;
    color: #646464;
  }

  .nav-links-item .top-nav-register-btn{
    background-color: #14b4c8 !important;
    color: white !important;
    border-radius: 100px;
    box-shadow: 0px 4px 3px #bbb !important;
    font-weight: bold;
    font-size: 16px;
    padding: 10px 20px;
    margin-top: 10px;
  }

}

/* @media (max-width: 640px) {
  .top-nav .navbar-header {
    flex: 1%;
  }
} */

@media (max-width: 620px) {

  .top-nav .navbar-header {
    flex: 0%;
    display: none;
  }

  .top-nav .top-nav-signed-out-mobile .navbar-header {
    display: flex;
  }
}

@media (max-width: 438px) {
  .top-nav .top-nav-signed-out .top-nav-buttons div {
    padding: 0.25em 0.5em;
  }
}

@media (max-width: 397px) {
  .top-nav .top-nav-signed-out .top-nav-links span {
    font-size: 14px;
  }
}

@media (max-width: 360px) {
  .top-nav .top-nav-signed-out .top-nav-links span {
    font-size: 12px;
  }

  .top-nav .top-nav-signed-out .top-nav-buttons div {
    padding: 0.125em 0.25em;
  }
}

@media (max-width: 1023px) {
  .top-nav {
    padding-left: 0;
  }
}

.navbar-nav .open .dropdown-menu {
  right: 0;
  left: auto;
  position: absolute;
  margin-top: 5px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px 0px;
  border: 1px solid rgba(0, 0, 0, 0.15);
}

.navbar-nav .open .dropdown-menu a {
  font-size: 14px !important;
  font-weight: 400 !important;
  color: black !important;
  padding: 10px 30px !important;
}

@media (max-width: 544px) {
  .top-nav .navbar-form {
    padding-right: 0 !important;
  }

  .top-nav .navbar-right > li {
    margin: 0 5px;
  }

}

@media (max-width: 477px) {
  #nav-user-text {
    display: none;
  }

  .top-nav .top-nav-user span {
    width: 10px;
  }
}

@media (max-width: 385px) {
  #nav-user-text {
    display: none;
  }

  .top-nav .create-hub-btn span {
    display: none;
  }
}

@media (max-width: 320px) {
  .top-nav .avatar-image {
    display: none;
  }
}

@media (width >= 1023px) {
  .top-nav .nav-search {
    width: 100px;
  }
}


.footer-links {
  display: flex;
  gap: 2em;
  cursor: pointer;
  justify-content: center;
  width: 100%;
  cursor: pointer;
  font-weight: bold;
  color: #646464;
  font-size: 16px;

}

@media (width <= 1160px) {
  .footer-links {
  font-size: 16px;
  }
}
@media (width <= 1159px) {
  .footer-links{
  font-size: 13px;
  }
}

@media (width <= 1110px) {
  .footer-links{
  font-size: 11px;
  }
}


@media (width <= 1000px) {
  .footer-links{
  font-size: 9px;
  }
}

@media (width <= 590px) {
  .footer-links {
    display: none;
  }
}
.hover-blue:hover{
  color: #14B4C8;
}


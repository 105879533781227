.module-page-container {
  margin-bottom: 30px;
}

/* styling for buttons */
.action-button.module {
  background-color: var(--colour-accent-1) !important;
}

.action-button.warning {
  background-color: var(--colour-warning) !important;
}

.action-button {
  font-family: var(--font-main);
  width: 10em !important;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%) !important;
  border-radius: 25px;
  text-transform: none !important;
}

/* pdf logos red */
.module-page-container .fa-5x {
  color: #ff0000;
  padding-bottom: 10px;
}

/* styling of padding line */
.module-page-container .padding-line {
  height: 3px;
  width: 100%;
  background-color: #f5f5f5;
  margin: 30px 0px;
}

.module-page-container .padding-line-black {
  height: 1px;
  width: 100%;
  background-color:var(--colour-light-extra);
  margin: 30px 0px;
}

/* content containder margin */
.module-page-container .content-container{
  margin: 51px 35px 0px 35px;
}

/* text content styling */
.module-page-container .text-content-row {
  padding: 20px 35px;
  background-color: #f5f5f5;
  border-radius: 51px;
}

/* .module-page-container .text-content-row {
  padding: 20px 35px;
  background-color: #f5f5f5;
  border-radius: 51px;
  margin-bottom: 10px;
} */

.module-content {
  font-size: 16px;
  font-family: var(--font-main);
}

.module-content a {
  color: var(--colour-accent-1) !important;
  text-decoration: underline;
}

.module-page-container .module-content > p {
  margin-bottom: 0.5em;
  line-height: 110%;
}

.module-page-container .module-content img {
  max-width: 100%;
  height: auto;
}

/* resource stylings */
.module-page-container .resource-heading {
  margin-bottom: 30px;
}

.module-page-container .module-image {
  height: 100%;
  width: 100%;
}

.module-page-container .module-image-container:hover {
  border: 0px;
  box-shadow: 0 0 2px 1px #01745e;
  cursor: pointer;
  border-radius: 2px;
}

.module-page-container .module-image-container {
  height: 110px;
  width: 196px;
  text-align: center;
  overflow: hidden;
  border: 1px solid var(--colour-dark-side);
  border-radius: 1em;
  background-position: center center;
  background-size: cover;
}


.module-page-container .module-image-col {
  margin-bottom: 20px;
}

.module-page-container .media-row {
  display: flex;
}

.module-page-container .pdf-col {
  text-align: center;
  word-wrap: break-word;
}

.module-page-container .pdf-col:hover {
  cursor: pointer;
}

/* tags styling */
.module-page-container .tag {
  background-color: var(--colour-accent-1);
  border-radius: 20px;
  color: white;
  padding: 3px 10px;
  text-align: center;
  margin: 2px;
}

/* modal module delete */
.module-page-container .module-tags-container{
  margin: 10px 10px;
  display: flex;
  flex-wrap: wrap;
}

.module-modal-container {
  text-align: center;
}

.module-modal-container h3 {
  margin: 30px 0px;
}

.module-modal-container p {
  margin: 20px 0px;
}

.module-modal-container .button-container {
  margin: 30px 0px;
}

.module-modal-container button {
  background-color: var(--colour-accent-1);
  background-image: none;
  color: #ffffff;
  text-transform: uppercase;
  width: 30%;
  margin: 0px 10px;
  text-shadow: none;
}

@media (min-width: 992px) {
  .module-details-container {
      text-align: left;
  }
}

@media (min-width: 1200px) {
  .module-page-container .video-wrapper {
      display: inline-block;
      width: 520px;
      height: 325px;
  }
}

@media (max-width: 1395px) {
  .module-page-container .module-image-col {
      width: 33.33% !important;
  }
}

@media (max-width: 1050px) {
  .module-page-container .module-image-col {
      width: 50% !important;
  }
}

@media (max-width: 425px) {
  .module-page-container .content-container {
    margin: 0px 10px;
  }
}

.module-page-container .comment-content-container {
  margin-top: 2em;
}

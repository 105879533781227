.profile-tab-container {
  margin-top: 2em;
  margin-bottom: 2em;
}

.profile-tab-container > div {
  padding-left: 2.5%;
}

.profile-tab-container .title {
  font-weight: bold;
  font-size: 1.5em;
  color: #646464;
  display: block;
  text-align: left;
  margin-bottom: 1em;
  margin-left: 10px;
}

.profile-tab-container .my-hubs {
  margin-top: 3em;
  padding-top: 2em;
  padding-bottom: 2em;
  background-color: var(--color-background-darker-role);
}

.bookmark-button {
  background-color: var(--colour-accent-1);
  background-image: none;
  color: #ffffff;
  text-transform: uppercase;
  text-shadow: none;
  padding: 5px 15px;
  margin: 10px;
}

.empty-text {
  color: #B4B4B4;
}

.info-icon{
  height: 23px;
  width: 23px;
  margin-top: 10px;
  margin-left: 5px;
}


.bookmark-info-box{
  position: absolute;
  z-index: 99999;
  display: block;
  margin-left: 85px;
  margin-top: -40px;
}

.info-icon:hover{
  opacity: 0.5;
  cursor: pointer;
}


@media (max-width: 425px) {
  .profile-tab-container > div {
    padding-left: 20px;
  }
  .profile-tab-container .hub-tile-outer-container {
    padding-right: 20px;
  }

}

@media (max-width: 590){
  .info-icon{
    height: 23px;
    width: 23px;
    margin-top: 10px;
    margin-left: 5px;
  }

  .info-icon:hover{
    opacity: 0.5;
    cursor: pointer;
  }
}

.table-select-list .module-select-heading {
  font-weight: 900;
  font-size: 12pt;
  margin-top: 40px;
}

.table-select-list .module-row {
  height: 120px;
}

.table-select-list .module-row-lft {
  display: inline-block;
  width: 70%;
}
.table-select-list .module-row-rt  {
  display: inline-block;
  width: 30%;
}

.table-select-list .module-image-container {
  height: 70px;
  width: 100%;
  max-width: 145px;
  overflow: hidden;
}

.table-select-list .module-image {
  height: auto;
  width: 100%;
}

.table-select-list .module-title-container {
  height: 70px;
}

.table-select-list .module-title {
  text-align: left;
  line-height: 16px;
  max-height: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 12% 0;
}

.table-select-list .module-creator-container {
  height: 70px;
}

.table-select-list .module-creator {
  text-align: left;
  line-height: 16px;
  max-height: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 12% 0;
}

.table-select-list .module-checkbox-container {
  height: 70px;
}

.table-select-list .module-checkbox {
  float: right;
  margin: 8% 0;
}

@media (max-width: 992px) {

  .table-select-list .module-checkbox-container {
    height: 70px;
    width: 30px;
    left: 60%;
  }

  .table-select-list .module-row {
    width: 100%;
  }

  .table-select-list .module-row-lft {
    width: 80%;
  }
  
  .table-select-list .module-row-rt {
    width: 20%;
  }
  
  .table-select-list .module-title {
    margin-left: 150px;
    width: 32%;
    margin-top: -70px;
  }

  .table-select-list .module-creator {
    width: 32%;
    margin-left: 67.5%;
    margin-top: -70px;
  }

}

@media (max-width: 900px) {
  
  .table-select-list .module-title {
    margin-left: 150px;
    width: 70%;
    margin-top: -70px;
  }

  .table-select-list .module-creator {
    width: 70%;
    margin-left: 150px;
    margin-top: -40px;
  }

}
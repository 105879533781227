.ResetPassword {
  text-align: center;
  padding-top: 30px;
}

.ResetPassword .instruction-text, .ResetPassword .confirmation-text {
  margin: 20px 0;
  font-size: 1.1em;
}

.ResetPassword .error-message {
  margin: 20px 0;
}

.ResetPassword .action-button {
  background-color: var(--colour-accent-1);
  background-image: none;
  color: #ffffff;
  text-transform: uppercase;
  border-color: #e59552;
  margin: 10px;
  letter-spacing: 1px;
  text-shadow: none;
  padding: 10px 15px;
}

.ResetPassword .form-container {
  text-align: left;
  margin: 10px;
}

.ResetPassword .password-info {
  margin-left: 5px;
}

#password-tooltip > .tooltip-inner {
  background-color: rgb(255, 255, 255, 1);
  border: 1px solid #062e56;
  text-align: left;
  padding: 10px;
}

#password-tooltip > .tooltip-arrow {
  border-right: 5px solid #062e56;
}

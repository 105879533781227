.course-header {
  background-color: #f5f5f5;
  display: flex;
  padding-top: 30px;
  padding-bottom: 30px;
}

.course-header .header-logo {
  height: 150px;
  width: 150px;
  border-radius: 50%;
  box-shadow: #ababab 0px 0px 20px;
  background-position: top center;
  background-size: cover;
  margin-right: auto;
  margin-left: 10%;
  background-color: white;
}

.course-header .header-title-container {
  display: flex;
  flex: 2;
  padding: 20px;
  flex-direction: column;
  justify-content: flex-end;

}

.header-title-container .header-course-title {
  font-size: 32px;
  font-weight: bold;
  align-self: center;
  color: #14B4C8;
  text-align: left;
  width: 100%;
}

.header-title-container .header-hub-title {
  font-size: 24px;
  font-weight: bold;
  align-self: center;
  color: #14B4C8;
  text-align: left;
  width: 100%;
}

.course-description-container {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 30px;
  gap: 10px;
}

.course-description-container .course-description-title {
  font-size: 20px;
  font-weight: bold;
}

.course-description-container .course-description {
  font-size: 20px;

}

.main-container-course .learning-outcome {
    display: block;
    width: fit-content;
    margin: 0 auto;
}


.learning-outcome .learning-outcome-container {
  display: flex;
  gap: 10px;
}

.main-container-course .time-display-container {
  font-size: 20px;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 5px
}

.main-container-course .section-display-container {
  width: 100%;
  padding: 5% 15%;
  box-sizing: border-box;
}

.section-display-container hr {
  border-top: 2px solid #eeeeee;
  margin-top: 10px;
}

.main-container-course .module-table {
  width: 100%;
  margin: 30px;
  font-size: 20px;
  border-spacing: 90% 10px;
  border-collapse: separate;
  table-layout: fixed
}

.main-container-course .start-course-button-container {
  display: flex;
  justify-content: center;
  padding: 30px;
}

.start-course-button-container .start-course-button {
  background-color: #14B4C8;
  color: white;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  padding: 15px;
}

.course-module-page{
  display: flex;
  flex-direction: row;
}

.course-module-page .course-module-side-bar{

  display: block;
  width:235px;
  background-color: white;
  border-right: 1px solid #eeeeee;
  height: 100%;
  z-index: 1031;
  transition: all 0.35s ease;
  min-height: 1000px;
  padding-top: 1em;
  overflow-y: auto;
  flex-direction: column;
}

.course-module-side-bar .progress-bar-container{
  padding: 10px;
}

.course-module-page .course-module-main-page{
  display: flex;
  flex:1;
  flex-direction: column;
}

.course-module-side-bar .progress-bar{
  background-image: linear-gradient(to bottom, #14B4C8 0%, #14B4C8 100%);
}

.course-module-side-bar .progress{
  height: 5px;
  border-radius: 4px;
  background-image: linear-gradient(to bottom, #94CBD6 0%, #94CBD6 100%);
  margin-bottom: 0px;
}

.course-module-side-bar hr{
  border-top: 2px solid #EFEFEF;
  margin: 5px;
}

.course-module-main-page .buttons-container{
  display: flex;
  gap:35%;
  padding-left: 40px;
  padding-bottom: 40px;
}
.course-module-main-page .buttons-container .btn{
  color: white;
  background: #FA6400;
  border-radius: 30px;
  font-size: 18px;
  padding: 10px 20px;
}

.course-header  .action-button-container-top {
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap:20%;
padding-top: 20px;
padding-right: 20px;
}
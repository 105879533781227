.user-tile-container .avatar {
  display: inline-block;
  max-width: 100%;
  height: 100%;
}

.user-tile-container {
  text-align: center;
  width: 170px;
  height: 170px;
  display: inline-block;
  margin-right: 15px;
  color: black;
}

.user-tile-container .avatar-container {
  display: inline-block;
  text-align: center;
  height: 125px;
  width: 125px;
  border-radius: 50%;

  overflow: hidden;
  background-size: cover;
  background-position: center center;
  box-shadow: 0 0 20px #ababab;
}

.user-tile-container .avatar-container:hover {
  -webkit-box-shadow: 0 0 2px 2px #0c808d;
  box-shadow: 0 0 20px 0px #0c808d;
}

.user-tile-container .user-name-container {
  height: 50px;
  width: 170px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 17px;
}

.user-tile-container .user-link {
  line-height: 50px;
}

.user-tile-container .user-link:hover {
  cursor: pointer;
}

.user-tile-container .avatar-container:hover {
  cursor: pointer;
}


.analytics-container .block-container {
  width: 100%;
  padding-bottom: 20px;
}

.analytics-container .totals {
  display: inline-block;
  width: 100%;
  border: 12px solid;
  border-radius: 50%;
  width: 160px;
  height: 160px;
  text-align: center;
  padding-top: 22px;
  margin-top: 20px;
}

.analytics-container .hub-totals {
  border-color: #ffc739;
}

.analytics-container .module-totals {
  border-color: #5acab6;
}

.analytics-container .user-totals {
  border-color: #6bb376;
}

.analytics-container .plan-totals {
  border-color: #2a9ad3;
}

.analytics-container .session-totals {
  border-color: #877fcb;
}

.analytics-container .totals:not(:last-child) {
  margin-top: 11px;
  padding-bottom: 20px;
  margin-bottom: 40px;
  border-bottom: solid 2px #eeeeee;
}

.analytics-container .total-count {
  font-size: 40pt;
  margin-bottom: -10px;
}


.analytics-container .total-title {
  font-size: 12pt;
}

.home-cont {
  background: linear-gradient(to bottom, #EFFBFD, #DBFAFF);
  padding: 10px 0px 0px 30px;
}

.popular-theme-container-personal {
  background-color: var(--color-background-darkestest-role);
  padding: 75px 75px 25px 75px;
}

.popular-theme-container-personal .theme-text-sub-heading {
  font-size: 42px;
  font-weight: bolder;
  color: var(--colour-light-explore) !important;
  /* font-family: 'Roboto'; */
}

.popular-theme-container-personal .theme-text {
  font-size: 20px;
  color: var(--colour-light-explore) !important;
  /* font-family: 'Roboto'; */
  padding-right: 20px;
}

.second {
  background-color: var(--color-background-role);
}

#rcorners1 {
  font-size: 15px;
  /* font-family: 'Roboto'; */
  color: #646464;

  border-radius: 25px;
  background: #fff;
  padding: 10px 18px 30px 20px;
  width: 150px;
  height: 15px;
}

.page-heading-container h1 {
    font-weight: 700;
    color: #2D6773;
    font-size: 4em;
}

.page-heading-container p {
    color: var(--colour-dark-side);
    margin-top: 1.2em;
    font-size: 1.5em;
}

.page-heading-container {
  margin-top: 6em;
  margin-right: 2em;
  min-width: 200px;
  padding-right: 40px;
}

.page-heading-container .page-description {
  display: block;
  color: #646464;
  font-size: 20px;
  margin-bottom: 2em;
}

.page-heading-container .top-text {
  display: block;
  font-weight: bolder;
  color: #14B4C8;
  text-transform: uppercase;
  font-size: 42px;
}

.compass-icon {
    margin-right: 0.2em;
}

.what-we-do {
  display: flex;
  justify-content: space-between;
}

.image-container {
  position:relative;
  min-height: 407px;
  min-width: 600px
}


#all-modules {
  font-family: var(--font-headings) !important;
  font-weight: bold;
  font-size: 2em;
  margin: 20px 20px 20px 0px;
  display: inline-block;
  color: var(--colour-dark-extra);
  padding-left: 2.5%;
  color: #14B4C8;
}

.home-cont .explore-visual {
  content:url('../../resources/img/Explore_Visual.svg');
  position:absolute;
  bottom:-6px;
  left:0;
}

.home-containers {
  padding-left: 20px;
  padding-bottom: 20px;
}

/* Media Queries */
@media (max-width: 1000px) {
  .page-title {
    font-size: 40px !important;
  }

  .page-description {
    font-size: 18px !important;
  }

  .popular-theme-container-personal .theme-text {
    font-size: 18px !important;
  }
}

@media (max-width: 768px) {
  .page-heading-container .top-text {
    display: block;
    font-weight: bolder;
    color: #14B4C8;
    text-transform: uppercase;
    font-size: 35px;
  }

  .page-title {
    font-size: 35px !important;
  }

  .home-cont {
    padding: 10px 0px 0px 15px;
  }

  .home-cont .image-container {
    display: none;
  }

  .home-cont .page-title{
   margin-right: 35px;
   display: inline;
  }

  .home-cont .explore-visual-mobile {
   content: url('../../resources/img/explore_visual_acrobatics.svg');
   right: 20px;
   margin-top: -20px;
   display: inline;
  }

  .home-cont .what-we-do {
    display: flex;
    justify-content: none;
  }

  .home-cont .page-heading-container{
    width: 100%;
    margin-top: 3em;
  }

  .popular-theme-container-personal {
    background-color: var(--color-background-darkestest-role);
    padding: 0;
    padding: 30px 0px 10px 15px;
  }
  .popular-theme-container-personal .theme-text-sub-heading, .theme-text {
    font-size: 35px;
  }
}

@media (max-width: 425px) {
  .home-cont .page-heading-container{
    padding-right: 0px;
  }

  .home-containers {
    padding-left: 10px;
  }


  .home-cont .explore-visual-mobile {
    display: inline-block;
    margin-top: -70px;
    margin-left: 10px;
  }

  .home-cont .page-title{
    width: 135px;
    display: inline-block;
    margin-right: 17px;

  }

  .popular-theme-container-personal > .home-containers {
    padding: 20px 0px 20px 10px;
  }

  .theme-pills-container {
    display: flex;
    overflow-x: scroll;
    flex-wrap: nowrap;
    padding: 0;
    justify-content: left;
  }

  .section-container .section-title {
    font-size: 1.8em;
  }


}




.add-user-container {
  text-align: center;
  padding: 40px;
}

.add-user-container .button-container {
  align-items: center;
  padding-top: 25px;
}

.add-user-container button {
  background-color: #ff0803;
  text-transform: uppercase;
  border-color: #ff0803;
  color: white;
  margin: 10px;
  letter-spacing: 2px;
  text-align: center;
}

.add-user-container h5 {
  font-weight: bold;
}

.add-user-container .control-label {
  margin-bottom: 20px;
}

.add-user-container .avatar-image {
  height: 25px;
  width: 25px;
  display: inline-block;
}

.add-user-container .name-container {
  display: inline-block;
  padding-left: 10px;
}

.add-user-container .success-text {
  color: green;
  font-size: 18px;
}

.adduser-button {
  background-color: var(--colour-accent-1) !important;
  color: white !important;
  font-family: var(--font-main);
}

.add-user-container .form {
  margin-bottom: 20px;
}

.add-user-container .form-input {
  width: 100%;
  margin-bottom: 10px;
}

.add-user-container .external-invite-feedback {
  margin-top: 10px;
  color: red;
}

.typeahead__container .rbt-input-multi {
  display: flex;
  flex-wrap: wrap; /* Allow tokens to wrap to multiple lines */
  align-items: flex-start; /* Align tokens to the top */
  gap: 5px;
  max-height: 60px; /* Set a fixed max height, adjust as needed */
  overflow-y: auto; /* Enable vertical scrolling */
  width: 100%; /* Ensure it takes full width of the container */
  padding: 5px;
}

.typeahead__container .rbt-input-multi .rbt-token {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis; 
  max-width: 100%;
  height: 100%;
  flex-shrink: 0; 
}

.typeahead__container .rbt-input-multi .rbt-input-main {
  flex-grow: 1;
  min-width: 60px;
  border: none;
  outline: none;
  max-width: 100%; 
}
.typeahead__container .rbt-input-multi::-webkit-scrollbar {
  width: 8px;
}

.typeahead__container .rbt-input-multi::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 4px;
}


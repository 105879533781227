.section-container {
  margin: 0px;
  /* padding: 0% 2.5% 0% 2.5%; */
}

.section-container .tiles-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  flex-direction: row;
}

.section-container .section-title {
  font-family: var(--font-headings) !important;
  font-weight: bold;
  font-size: 2em;
  margin: 20px 20px 20px 0px;
  display: inline-block;
  color: #14B4C8;
}

.section-container .top-link-view-all {
  margin-left: 0.5em;
}


.section-container .section-title:hover {
  cursor:default;
}

.section-container .empty-text-container {
  font-size: 1.2em;
}

.section-container .section-link-container {
  display: none;
  margin-top: 10px;
  font-size: 18px;
}

@media (max-width: 520px) {
  .section-container .top-link {
    display: inline-block;
  }
}

.react-icons {
  vertical-align: middle;
}

.section-container .top-link {
    display: inline-block;
    background-color: var(--colour-accent-1) !important;
    color: #fff;
    position: relative;
    top: -4px;
    left: 0px;
    font-size: 14px;
    border-radius: 15px;
    padding :5px 10px 5px 10px;
}

.section-container .view-button{
  background-color: #14B4C8;
  font-family: var(--font-main);
  background-image: none;
  color: white;
  font-weight: bold;
  text-shadow: none;
  padding: 10px 20px 10px 20px;
  border-radius: 20px;
  display: flex;
  gap:5px
}
.section-container .circle{
  border-radius: 50%;
  background-color: white;
  color:#14B4C8;
  font-weight: bold;
  height:20px;
  width: 20px;
  padding-bottom: 2px;

}
/* @media (max-width: 500px) {
  .section-container .top-link{
    margin-right: 180px;
    margin-bottom: 20px;
  }
} */

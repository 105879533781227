.conversation {
  /* padding: 2.5%; */
  text-align: center;
}

.conversation .avatar-container {
  padding: 10px 0px;
  padding-bottom: 10px;
}

/*.conversation .header {
  width: 100%;
}*/
.conversation .avatar-image {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-position: center center;
  background-size: cover;
  border: 1px solid var(--colour-light-extra);
}

.conversation button {
  background-color: var(--colour-accent-1);
  background-image: none;
  color: #ffffff;
  text-transform: uppercase;
  text-shadow: none;
  font-size: 14px;
  font-weight: normal;
  border: 1px solid transparent;
  border-radius: 4px;
}

.conversation .chat-history-container {
  height: 420px;
  border: 1px solid #eeeeee;
  /* padding: 20px; */
  overflow: auto;
  text-align: left;
}

.conversation .user-name {
  margin-right: 20px;
}

.conversation .message-header {
  color: #418E9E;
  font-size: 10pt;
  margin-bottom: 2px;
}

.conversation .date {
  margin: 0px 0px 0px 0px;
  text-align: right;
}

.conversation .message-container {
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
  margin-right: 40%;
  background-color: #cfcfcf;
  word-wrap: break-word;
  margin-top: 10px;
}

.conversation .message-container.rgt {
  margin-right: 0px;
  margin-left: 40%;
}

.conversation .respond-button-container {
  text-align: center;
}

.conversation .send-message-content {
  display: flex;
  background-color: #DBDBDB;
  gap: 1em;
}

.conversation .form-text-area {
  margin: 12px 10px 0px 10px;
  border-radius: 20px;
}

.conversation .form-group {
  width: 75%;
}

.conversation .send-button {
  margin : 17px 10px 20px 10px;
  border-radius: 15px;
  width: 15%;
  cursor: pointer;
  background-color: rgb(20, 180, 200);
  display: table;
}

.conversation .send-button-text {
  font-size: 22px;
  font-weight: bold;
  color: white;
  vertical-align: middle;
}


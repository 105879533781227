.error-404-container {
  text-align: center;
  padding-top: 30px;
}

.error-404-container .error-message {
  margin: 20px 0;
}

.error-404-container .home-button {
  background-color: var(--colour-accent-1);
  background-image: none;
  color: #ffffff;
  text-transform: uppercase;
  border-color: #e59552;
  margin: 10px;
  letter-spacing: 1px;
  text-shadow: none;
  padding: 10px 15px;
}

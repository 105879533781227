.users-tab-container {
  padding: 0% 2.5%;
  font-family: var(--font-main);
}

.users-tab-container table{
  width: 100%;
}

.users-tab-container .add-user {
  padding-right: 5px;
  color: var(--colour-accent-1);
  font-size: 17px;
  background-color: var(--color-background-darkest-role-other);
}

.users-tab-container .add-user:hover {
  cursor: pointer;
}

.users-tab-container .avatar-image {
  height: 75px;
  width: 75px;
  border-radius: 50%;
  background-size: cover;
  background-position: center center;
}

.users-tab-container th {
  padding: 25px;
  text-align: left;
}

.users-tab-container tr {
  border-bottom: solid 2px #eeeeee;
}

.users-tab-container td {
  padding: 10px;
}

.users-tab-container .role-heading {
  padding: 15px;
}
.users-tab-container table button {
  height: 40px;
}

.users-tab-container .add-user-button-container {
  text-align: right;
}

.add-user-button-container button{
  background-color: var(--colour-accent-1);
  border: none;
  font-family: var(--font-main);
}

.add-user-button-container button:hover{
  background-color: var(--colour-accent-1);
  border: none;
  color: white;
}

.users-tab-container button {
  text-transform: uppercase !important;
  background-color: var(--colour-accent-1);
  color: white;
  text-shadow: none;
}

.users-tab-container button:hover {
  text-transform: uppercase !important;
  background-color: var(--colour-accent-1);
  color: white;
  text-shadow: none;
}

.users-tab-container .warning-button {
  background: red;
  color: white;
  text-shadow: none;
  font-size: 14px;
  margin-left: 5px;
}

.users-tab-container .warning-button:hover {
  cursor: pointer;
  background: red
}

.users-tab-container .user-name:hover {
  cursor: pointer;
}

.users-tab-container .facaretdown:hover {
  cursor: pointer;
}

@media (max-width: 600px) {
  .users-tab-container{
    overflow-x: scroll;
  }
}

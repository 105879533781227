/**
 * This CSS file contains style declarations which relate to each component of the chat
 * page. This has been sectioned into the relevant components.
 */

/* ================= */
/* General popups */

.rce-button.primary {
    background-color: #118194 !important;
    border: 1px solid #118194 !important;
    color: white !important;
  }

  .rce-button.secondary {
    background-color: white !important;
    border: 1px solid grey !important;
    color: #333 !important;
  }

  /* ================= */
  /* Alert */

  .chat-alert {
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    padding: 10px;
    margin-top: 10px;
    border-radius: 5px;
    z-index: 2;
    box-shadow: 0px 5px 8px -2px rgba(0, 0, 0, 0.5);
  }

  .chat-alert.info {
    background-color: #d9edf7;
    color: #31708f;
  }

  .chat-alert.success {
    background-color: #dff2bf;
    color: #4f8a10;
  }

  .chat-alert.error {
    background-color: #ffbaba;
    color: #d8000c;
  }

  .chat-alert-message {
    margin-right: 20px;
  }

  .chat-alert-message.no-dismiss {
    margin-right: 0px;
  }

  .chat-alert-dismiss {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
  }

  /* ================= */
  /* Landing Page */

  .chat-landing {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .chat-landing i {
    font-size: 8em;
  }

  .chat-landing p {
    margin-bottom: 50px;
  }

  .chat-landing button {
    padding: 0;
    vertical-align: inherit;
  }

  /* ================= */
  /* Sidebar */

  .chat-list {
    border-right: 1px solid #e7e7e7;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    background-color: #f4f4f4;
  }

  .chat-list-create {
    display: flex;
    align-items: center;
    align-self: end;
    gap: 10px;
    width: max-content;
    background: none;
    border: none;
    padding: 4px;
    padding-left: 8px;
    color: #118194;
  }

  .chat-list-create span {
    font-size: 12px;
  }

  .rce-container-clist {
    height: 92%;
    overflow-y: auto;
  }

  .rce-container-citem {
    border-bottom: 1px solid #eae8ea;
  }

  .rce-container-citem .rce-citem {
    background-color: #f4f4f4;
  }

  .rce-container-citem:first-child {
    border-top: 1px solid #eae8ea;
  }

  .rce-container-citem.selected {
    border-bottom: 1px solid #269ba6;
  }

  .rce-container-citem.selected .rce-citem {
    background-color: white;
  }

  .rce-citem-body {
    border: none;
  }

  .rce-citem-body--top-title {
    font-weight: 500;
  }

  .rce-citem-body--bottom-title {
    font-size: 12px;
  }

  .rce-citem-avatar img {
    background-color: white;
  }

  /* ================= */
  /* Messages */

  .chat-messages {
    position: relative;
  }

  .chat-messages-main {
    height: calc(100% - 54px);
    overflow: auto;
    display: flex;
    flex-direction: column;
  }

  .chat-messages-fetch-more {
    margin: 8px 16px;
    background-color: white;
    color: #118194;
    border: none;
  }

  .chat-messages-fetch-more i {
    display: block;
  }

  .chat-messages-box-time-divider {
    margin: 10px 0;
    text-align: center;
  }

  .chat-messages-box-time-divider span {
    padding: 8px;
    border-radius: 20px;
    background-color: #eae8ea;
    color: #333;
  }

  .chat-messages-input {
    position: absolute;
    padding: 2px 10px;
    bottom: 0;
  }

  .rce-button.input-button {
    width: 70px;
    height: 35px;
  }

  .rce-container-mbox {
    position: relative;
  }

  .rce-mbox {
    max-width: 50%;
  }

  .rce-mbox-right {
    right: 0;
    background-color: #e7fdff !important;
  }

  .rce-mbox-right-notch path {
    fill: #e7fdff !important;
  }

  .rce-mbox.icon-container {
    position: absolute;
    z-index: 1;
    background: none !important;
    box-shadow: none;
  }

  .chat-messages-box-delete {
    padding: 1px;
    float: right;
    opacity: 0.3;
    transition: 0.1s;
    cursor: pointer;
  }

  .chat-messages-box-delete:hover {
    opacity: 1;
  }

  .rce-mbox-body {
    padding-bottom: 16px;
    padding-right: 20px;
  }

  .rce-mbox-text::after {
    content: '';
  }

  .rce-container-input {
    padding: 10px;
    padding-top: 0;
  }

  .rce-input {
    padding: 10px 10px;
    border-radius: 20px;
    background-color: white;
    border: 1px solid #6c6c6c;
    color: #333;
    overflow: hidden;
    height: 40px;
    font-size: 0.9em;
  }

  .rce-input::placeholder {
    color: #6c6c6c;
  }

  /* ================= */
  /* Info bar */

  .chat-info {
    border-left: 1px solid #e7e7e7;
  }

  .chat-info-wrap {
    position: relative;
    width: 300px;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: #f4f4f4;
  }

  .chat-info-toggle {
    background: none;
    border: none;
    padding: 4px;
    padding-left: 8px;
    width: 24px;
    color: #118194;
  }

  .chat-info-leave {
    display: flex;
    align-items: center;
    background: none;
    border: none;
    padding: 8px;
    color: #118194;
    text-align: left;
  }

  .chat-info-leave span {
    flex: 1;
    margin-right: 8px;
  }

  .chat-info-settings {
    display: flex;
    align-items: center;
    background: none;
    border: none;
    padding: 8px;
    top: 0;
    right: 110px;
    color: #118194;
    text-align: left;
  }

  .chat-info-settings span {
    flex: 1;
    margin-right: 8px;
  }

  .chat-info-flyout-show {
    background: none;
    border: none;
    padding: 4px;
    position: absolute;
    top: 0;
    right: 16px;
    color: #118194;
  }

  .chat-info-flyout {
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 0;
    top: 32px;
    background-color: white;
    font-size: 12px;
    margin: 1em;
    box-shadow: 0 2px 5px 1px #333;
  }

  .chat-info-flyout > *:hover {
    background-color: #eae8ea;
  }

  .chat-info-invite {
    background: none;
    border: none;
    padding: 4px;
    margin-right: 16px;
    color: #118194;
  }

  .chat-avatar {
    width: 64px !important;
    height: 64px !important;
    margin-right: 10px;
    float: left;
  }

  .chat-member-avatar {
    width: 32px !important;
    height: 32px !important;
    margin: 5px;
    float: left;
  }

  .chat-admin-indicator {
    color: #e59552;
    position: absolute;
    left: 48px;
    top: 3px;
  }

  .chat-info-item.center {
    display: flex;
    flex-direction: column;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  }

  .chat-info-avatar {
    margin-left: 9px;
    transition: 0.3s;
  }

  .chat-expanded .chat-info-avatar {
    margin-left: 100px;
    width: 96px;
    height: 96px;
  }

  .chat-info-title {
    margin-bottom: -40px;
    padding-bottom: 10px;
    text-align: center;
    transition: 0.3s;
    word-break: break-word;
    text-align: left;
    margin: 10px 20px -40px 20px;
  }

  .chat-expanded .chat-info-title {
    margin-bottom: 10px;
  }

  .chat-info-subtitle {
    margin-bottom: -40px;
    text-align: center;
    transition: 0.3s;
    word-break: break-word;
    text-align: left;
    margin: 10px 20px -40px 20px;
  }

  .chat-expanded .chat-info-subtitle {
    margin-bottom: 20px;
  }

  .chat-info-members {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
  }

  .chat-info-members-header {
    text-align: center;
    margin-bottom: -4px;
    text-align: left;
    margin-left: 16px;
    display: flex;
    align-items: center;
  }

  .chat-info-members-header b {
    flex: 1;
  }

  .chat-info-members-list {
    height: 95%;
    overflow: auto;
    transition: 0.3s;
  }

  .chat-expanded .chat-info-members-header {
    margin-bottom: 4px;
  }

  .chat-info-member {
    height: 50px;
    display: flex;
    align-items: center;
    transition: 0.3s;
    position: relative;
  }

  .chat-expanded .chat-info-member {
    border-top: 1px solid #d1d1d1;
  }

  .chat-info-member:first-child {
    height: 50px;
    display: flex;
    align-items: center;
    border-top: none;
  }

  .chat-info-member-avatar {
    margin-left: 17px;
    float: left;
  }

  .chat-info-member-avatar img {
    background-color: grey;
  }

  .chat-info-member-name {
    margin: 0;
    margin-left: 20px;

    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .rce-button.circle {
    min-width: 23px;
    min-height: 23px;
  }

  .rce-input-buttons {
    align-self: flex-end;
  }
  .rce-mbox.rce-mbox-right {
    background-color: #b9f2f9;
  }
  .rce-mbox-right-notch path {
    fill: #b9f2f9;
  }

  .fade {
    opacity: 0;
  }

  .expanded .fade {
    opacity: 1;
  }

  /* ================= */
  /* Chat Management Popup*/

  .chat-manage .rce-popup {
    width: 50%;
  }

  .chat-manage .rce-popup-header {
    font-size: 16px;
  }

  .chat-manage-modal-content {
    display: grid;
    grid-template-areas:
      'avatar members'
      'title members'
      'description members';
    grid-template-columns: 50% 50%;
  }

  .chat-manage-modal-content > div {
    margin: 0 3px;
    margin-bottom: 10px;
  }

  .chat-manage-modal-content label {
    display: block;
    margin: 4px 0;
  }

  .chat-manage-modal-content input[type='text'],
  .chat-manage-modal-content textarea {
    width: 100%;
    border: 1px solid grey;
    border-radius: 5px;
    padding: 4px;
  }

  .chat-manage-avatar {
    grid-area: avatar;
    display: flex;
    align-items: center;
  }

  .chat-manage-avatar label {
    margin-left: 10px;
    font-weight: lighter;
  }

  #avatar-input {
    display: none !important;
  }

  #avatar-input-proxy {
    width: 75px;
    height: 75px;
    border: 1px solid;
    border-radius: 75px;
    position: relative;
    overflow: hidden;
  }

  #avatar-preview {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  .chat-manage-title {
    grid-area: title;
  }

  #title-input {
  }

  .chat-manage-description {
    grid-area: description;
    position: relative;
  }

  #description-input {
    resize: none;
  }

  #description-char-limit {
    position: absolute;
    bottom: 12px;
    right: 4px;
    font-size: 11px;
  }

  .chat-manage-members {
    display: grid;
    grid-template-rows: auto 1fr;
    grid-area: members;
  }

  .module-members {
    display: flex;
    flex-direction: column;
    grid-template-rows: auto;
    position: relative;
    width: 50%;
    top: 50%;
    left: 50%;
  }

  #members-search-icon {
    position: absolute;
    font-size: 1.5em;
    top: 4px;
    right: 10px;
    pointer-events: none;
  }

  #members-search {
    height: 30px;
    border: 1px solid #FAC832;
    border-radius: 20px;
  }

  @keyframes spinner {
    from {
      transform: rotateZ(0deg);
    }
    to {
      transform: rotateZ(360deg);
    }
  }

  #members-search-pending {
    position: absolute;
    top: 1px;
    right: 3px;
    animation: spinner 2s linear infinite normal;
    pointer-events: none;
  }

  .chat-members-search-results {
    position: absolute;
    top: 32px;
    left: 0;
    width: 100%;

    background-color: white;
    border: 1px solid var(--colour-light-extra);
    border-radius: 5px;
    overflow: hidden;
    z-index: 1;
  }

  .chat-members-search-result-item {
    width: 100%;
    background: none;
    border: none;
    padding: 4px 0;
  }

  .chat-members-search-result-item:hover {
    background-color: lightgrey;
  }

  .chat-members-admin-label {
    font-size: 10px;
    text-align: right;
    padding-right: 4px;
    position: relative;
    top: 8px;
  }

  .chat-members-admin-label.invis {
    visibility: hidden;
  }

  .chat-members-list {
    height: 200px;
    margin-top: 10px;
    overflow-y: auto;
  }

  .chat-members-list-item {
    display: flex;
    border-bottom: 1px solid var(--colour-light-extra);
    align-items: center;
    height: 32px;
  }

  .chat-members-list-item-name {
    flex-grow: 1;
  }

  .chat-members-list-item-remove {
    background: none;
    border: none;
    padding: 0;
    margin-right: 10px;
  }

  .chat-members-list-item-remove:active {
    padding: 0;
  }

  .not-found-users {
    color: #d8000c;
  }

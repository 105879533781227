.main-group-container{
    padding-left: 20px;
}

.main-group-container .header-container{
    display : flex;
}

.header-container .main-heading{
    font-size: 25px;
    font-weight: bold;
    color: #646464;
    font-style: "Roboto";
    display: block;
    flex: 1;
}

.header-container .create-button-container{
    display: flex;
    flex:1;
    justify-content: flex-end;
    padding-right: 10%;
}

.header-container .create-group-button {
    background-color: #14B4C8;
    font-family: var(--font-main);
    background-image: none;
    color: white;
    text-transform: uppercase;
    text-shadow: none;
    padding: 10px 20px 10px 20px;
   
  }

  .main-group-container .group-table{
    width: 100%;
    
  }
  .group-table .group-row{
    display: flex;
    padding-top: 5px;
    padding: 20px 0px 20px 0px;
    border-bottom: solid 2px #eeeeee;
  } 
  
  .group-row .group-col{
display: flex;
flex: 1 ;

  }

  .group-table .quiz-button-container{
    display:flex;
    flex:1;
    justify-content: flex-end;
    padding-right: 10%;
  }

  .group-table .group-name{
    display: flex;
    flex : 1;
    font-style: "Roboto";
    color: #14B4C8;
    font-weight: bold;
    background-color: white;
    
  }

  .group-table .group-name:active{
    background-color: white;
  }

  .group-table .quiz-results-button{
    background-color: #14B4C8;
    font-family: var(--font-main);
    background-image: none;
    color: white;
    text-transform: uppercase;
    text-shadow: none;
    padding: 5px 10px 5px 10px;
  }

  .quiz-button-container .warning-button {
    background: red;
    color: white;
    text-shadow: none;
    font-size: 14px;
    margin-left: 20px;
    text-transform: uppercase;
}
  
  
  .quiz-button-container .warning-button:hover {
    cursor: pointer;
    background: red
  }

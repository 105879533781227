.checkbox-question-container .answered-drag {
  display: inline-block;
  margin: 5px;
  text-align: center;
  border: 1px dashed black;
  border-radius: 2px;
  min-width: 85px;
  height: 25px;
  padding: 1px;
  vertical-align: middle;
  background-color: white;
}

.checkbox-question-container .correct-answer-text {
  margin-left: 15px;
}

.checkbox-question-container .fa-check {
  padding-right: 20px;
}

.create-radio-question .question-container {
  font-weight: bold;
  margin: 35px 0;
}

.page-1-container .radio input[type="radio"], .radio-inline input[type="radio"], .checkbox input[type="checkbox"], .checkbox-inline input[type="checkbox"] {
  transform: scale(1.5);
  filter: invert(70%) hue-rotate(358deg) brightness(1.7);
  margin-top: 7px;
  margin-left: -35px;
}

.page-1-container .radio label, .checkbox label {
  color: #646464;
  font-weight: bold;
  font-size: 20px;
  margin: 0px 0px 4px 30px;
}

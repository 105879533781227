.tile-container {
  text-align: left;
  width: 23%;
  height: auto;
  display: inline-flex;
  flex-direction: column;
  border-radius: 2px;
  margin: 0.5vw;
  overflow: hidden;
  vertical-align: top;

  cursor: pointer;
  border-radius: 20px;
  border: 1px solid #eeeeee;
}

.tile-container.carousel {
  width: 90%;
  height: 100%;
}

  @media (max-width:1600px) {
    .tile-container {
      width: 23%;
    }
  }

  @media (max-width:1300px) {
    .tile-container {
      width: 48%;
    }
  }

  @media (max-width:1100px) {
    .tile-container {
      width: 31%;
    }
  }

  @media (max-width:900px) {
    .tile-container {
      width: 31.3%;
    }
  }

  @media (max-width: 700px) {
    .tile-container {
      width: 48%;
    }
  }

  @media (max-width: 500px) {
    .tile-container {
      width: 48%;
    }
  }

.tile-container:hover {
  background-color: #00000008;
}

.tile-container .banner-image {
  display: inline-block;
  margin: auto;
}

.tile-container .tile-image-container {
  display: inline-block;
  text-align: center;
  max-width: 100%;
  border-radius: 2px;
  overflow: hidden;
  position: relative;
  background-size: cover;
  background-position: center center;
  padding: 0px;
  padding-bottom: 56.25%;
  border-radius: 20px 20px 0px 0px;
}

.hub-name-container {
  display:flex;
  padding-bottom: 5px;
}

.tile-container .module-name-container {
  margin: 6px 0;
  padding: 0px 0px 0px 5px;
}

.tile-container .module-name {
  font-size: 20px;
  font-family: var(--font-headings) !important;
  font-weight: bold;
  max-width: calc(var(--width-scalar) * var(--scalar));
  color: var(--colour-dark-main);
  padding-left: 5px;
}

.tile-container .plan-name {
  font-size: 20px;
  font-family: var(--font-headings) !important;
  font-weight: bold;
  max-width: calc(var(--width-scalar) * var(--scalar));
  color: white;
  padding-left: 5px;
}

.hub-name-container .tile-hub-image {
  text-align: center;
  max-height: 20px;
  max-width: 20px ;
  height: 20px ;
  width: 20px ;
  border-radius: 50%;
  margin-top: 8px;
  margin-bottom: 4px;
  margin-left: 15px;
  border: none;
  overflow: hidden;
  background-size: cover;
  background-position: center center;
  border: 1px solid var(--colour-light-extra);

}

.hub-name-container .fill-check {
  padding-left: 15px;
}

.tile-container .hub-name {
  font-family: var(--font-main) !important;
  font-weight: 700;
  line-height: 1.3em;
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-top: 8px;
  margin-bottom: 4px;
  color: rgb(110, 110, 110);
  cursor: pointer;
}

.tile-container .hub-name a {
  color: rgb(110, 110, 110);
  padding-left: 20px;
  padding-bottom: 5px;
}

.tile-container .hub-name a:hover {
  color: #b4b3b3;
}


.tile-container .module-type {
  margin: 0 0;
  color: rgb(110, 110, 110);
  margin-top: 4px;
  padding-left: 21px;
  display: inline;
  padding-bottom: 10px;
}

.tile-container h5 {
  font-size: 13px;
  font-family: var(--font-main) !important;
  font-weight: 400;
}

.privacy-icon {
  width: 20px;
  height: auto;
  padding-right: 4px;
}

.main-container-elearning-tab .heading{
    padding-top: 30px;
    padding-left: 20px;
}

.table-container{
    padding: 20px;
}
.table-container table {
    width: 100%;
    padding: 10%;
  }
  
  .table-container th {
    padding: 10px;
    text-align: left;
    color: #646464;
    font-weight: bold;
  }
  
  .table-container tr {
    border-bottom: solid 2px #eeeeee;
  }
  
  .table-container td {
    padding: 10px;
    font-weight: bold;
  }
  
  .table-container .module-image-container {
    height: 70px;
    width: 100%;
    max-width: 145px;
    overflow: hidden;
  }
  
  .table-container .module-image {
    height: auto;
    width: 100%;
    min-width: 120px;
  }
  
  .table-container td:last-child {
    text-align: left;
  }
  
  .table-container table button {
    height: 35px;
    text-align: right;
    color: white;
    text-transform: uppercase;
    background-color: #14B4C8;
    margin: 5px;
  }

  @media (max-width: 600px) {
    .main-container-elearning-tab{
      overflow-x: scroll;
    }
  }

.manage-site {
  padding: 2.5%;
}

.manage-site .export-data {
  display: inline-block;
  font-size: 0.75em;
  text-decoration: underline;
  color: blue;
  cursor: pointer;
}

.manage-users-container table{
  width: 100%;
}

.manage-users-container .avatar-image {
  height: 75px;
  width: 75px;
  border-radius: 50%;
  background-size: cover;
  background-position: center center;
}

.manage-users-container th {
  padding: 25px;
  text-align: left;
}

.manage-users-container tr {
  border-bottom: solid 2px #eeeeee;
}

.manage-users-container td {
  padding: 10px;
}

.manage-users-container table button {
  height: 40px;
}

.manage-users-container .warning-button-container {
  text-align: right;
}

.manage-users-container .add-user-button-container {
  text-align: right;
  margin-top: 40px;
}

.manage-users-container .warning-button {
  background: var(--colour-warning);
  color: white;
  text-shadow: none;
}

.manage-users-container button {
  background-color: var(--colour-accent-1);
  background-image: none;
  color: #ffffff;
  text-transform: uppercase;
  text-shadow: 0 1px 0 #fff;
  font-size: 14px;
  font-weight: normal;
  border: 1px solid transparent;
  border-radius: 4px;
  text-shadow: none;
}


.modal-content .error-feedback {
  text-align: center;
  margin: 50px 0px;

}

.modal-content .error-feedback p {
  margin-bottom: 0px;
  font-size: 18px;
}

.modal-content .fa-exclamation-circle {
  color: var(--colour-warning);
  margin-bottom: 25px;
}

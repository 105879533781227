.Navbar-container {
  width: 100%;
  height: 60px;
  box-shadow: 0 1px 3px rgba(15, 15, 15, 0.13);
  display: flex;
  align-items: center;
  flex-direction: column;
}

.Navbar-container .left-section-nav{
  display: flex;
}

.Navbar-container .middle-section-nav{
  display: flex;
  flex: 2;
  height: 100%;
  justify-content: center;
}

.Navbar-container .right-section-nav{
  display: flex;
}


.quiz .title {
  margin-bottom: 30px;
  color: #10b5c6;
}

.quiz .new-button-container{
    justify-content: center;
}

.quiz .new-button {
  background-color: #FA6400;
  background-image: none;
  color: #ffffff;
  text-transform: uppercase;
  margin-bottom: 40px;
  text-shadow: none;
  border-radius: 20px;
  text-align: center;
  padding: 10px;
}

.quiz .question-container {
  padding-bottom: 20px;
}

.quiz .word-container {
  display: inline-block;
  padding: 0px 2px;
}

.quiz .blank-input {
  padding: 0px 2px;
  width: 100px;
  text-align: center;
}

.quiz .draggable {
  display: inline-block;
  border: 1px solid black;
  padding: 5px;
  margin: 0px 10px;
  margin-bottom: 10px;
  border-radius: 0%;
  min-width: 100px;
  text-align: center;
}

.quiz .instruction-text {
  font-weight: bold;
  margin: 20px 0px;
}

.quiz .question-word-space {
  display: inline-block;
  background-color: white;
  width: 100px;
  height: 20px;
  margin: 0px 2px;
  border: 1px;
  position: relative;
  top: 5px;
}

.quiz .icon-container {
  text-align: right;
  max-width: 600px;
  width: 20%;
  display: inline-block;
}

.quiz .icon-container .fa-times {
  padding-right: 20px;
  position: relative;
  left: 0px;
  top: 0px;
}

.quiz .quiz-feedback-container {
  max-width: 600px;
  background-color: #10b5c6;
  padding-left: 20px;
  padding-bottom: 20px;
  border-radius: 5px;
  color: white;
}

.quiz .quiz-feedback-heading {
  padding-top: 15px;
  font-weight: bold;
  font-size: 18px;
  letter-spacing: 0.5px;
}

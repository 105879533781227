.message-user {
  text-align: center;
}

.message-user .avatar-container {
  padding: 25px 0px;
}

.message-user .avatar-image {
  height: 175px;
  width: 175px;
  border-radius: 50%;
  background-position: center center;
  background-size: cover;
  margin: auto;
}

.message-user button {
  background-color: #e59552;
  background-image: none;
  color: #ffffff;
  text-transform: uppercase;
  border-color: #e59552;
  margin: 10px;
  text-shadow: none;
}

.message-user .user-info-container {
  padding-bottom: 25px;
}

.message-user .send-message-content {
  width: 100%;
  text-align: center;
  padding: 10px;
}

.message-user .form {
  display: inline-block;
}

.message-user .form-text-area {
  width: 540px;
  min-height: 100px;

}


.modal-dialog {
  width: 75%;
}

@media (max-width: 768px){
  .modal-dialog {
      width: 95%;
      margin: 30px auto;
  }
}

.modal-content {
  border-radius: 10px;
}

.new-sign-up .modal-content {
  border-radius: 10px;
}
.new-sign-up .sign-up-container {
  padding: 0;
}
.new-sign-up .flexbox {
  display: flex;
  flex-wrap: wrap;
  /* height:550px; */
}
.new-sign-up .infographic {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  padding: 0;
}
.new-sign-up .run-hill {
  background-image: url('../../../resources/img/sign_up_graphic.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.new-sign-up .sit-ups {
  background-image: url('../../../resources/img/Group_38.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.new-sign-up .intro-message {
  background-color: #14b4c8;
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 10px;
  color: white;
  border-bottom-left-radius: 10px;
  font-weight: bold;
  font-size: 14px;
}

.new-sign-up .intro-message-mobile{
  background-color: #14b4c8;
  color: white;
  font-weight: bold;
  font-size: 14px;
  bottom: 0;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 10px 10%;
  text-align: center;
}

.new-sign-up .orange-input {
  border: 1px solid #418e9e;
  border-radius: 25px !important;
  color: #85c4d1;
  height: 40px;
  padding-right: 35px;
}

.new-sign-up .blue-input {
  background-color: white !important;
  border: 1px solid #418e9e;
  border-radius: 25px !important;
  color: #85c4d1;
  height: 40px;
  padding-right: 35px;
}

.new-sign-up .input-label,
.new-sign-up .password-requirements {
  padding-left: 13px;
}
.new-sign-up .form-padding {
  padding: 1em 14%;
  padding-bottom: 0px;
}

.new-sign-up .logo-container {
  text-align: center;
  padding: 25px 40px;
}

.new-sign-up .img-fluid {
  width: 80%;
}

.tc-image {
  height: 3.5em;
  margin-right: -1em;
  width: auto !important;
}

.new-sign-up .blue-link,
.new-sign-up .blue-link:link,
.new-sign-up .blue-link:visited {
  color: #418e9e;
  text-decoration: underline;
  cursor: pointer;
  padding: 0;
}

.new-sign-up .already-have-acc {
  text-align: center;
}

.new-sign-up .input-icon-group {
  position: relative;
}

.new-sign-up .input-icon {
  position: absolute;
  right: 0;
  top: 0;
  margin-top: 38px;
  margin-right: 15px;
}

.new-sign-up .blue-button {
  background-color: #14b4c8 !important;
  color: white !important;
  border-radius: 100px;
  box-shadow: 0px 4px 3px #bbb !important;
  font-weight: bold;
  font-size: 16px;
  padding: 10px 20px;
  margin-bottom: 15px;
}

ul.designed-ul {
  list-style: none; /* Remove default bullets */
  padding-left: 10px;
}

ul.designed-ul li {
  font-size: 12px;
  padding-left: 0px;
}

ul.designed-ul li::before {
  content: '\2022'; /* Add content: \2022 is the CSS Code/unicode for a bullet */
  color: #b4b4b4; /* Change the color */
  font-weight: bold; /* If you want it to be bold */
  display: inline-block; /* Needed to add space between the bullet and the text */
  width: 1em; /* Also needed for space (tweak if needed) */
  margin-left: -0.5em; /* Also needed for space (tweak if needed) */
  font-size: 30px;
  position: absolute;
  margin-top: -13px;
}

ul.designed-ul li.checked::before {
  color: green;
}

.new-sign-up .password-requirements {
  color: #418e9e;
}

.new-sign-up .mb-20 {
  margin-bottom: 20px;
}

.new-sign-up .mt-20 {
  margin-top: 20px;
}

.new-sign-up .input-g {
  margin-top: 10px;
}

.new-sign-up .hlayout-title {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #646464;
}

.new-sign-up .hlayout-copy {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #777777;
}

.new-sign-up .hlayout-bottom {
  border-top: 2px solid #14b4c8;
  background-color: #c4ecf1;
  width: 100%;
  padding: 0;
}

.new-sign-up .no-padding {
  /* padding: 0; */
}

.new-sign-up .inline-label {
  color: #418e9e;
  padding: 4px 0px;
}

.new-sign-up .link-button {
  color: #418e9e;
  vertical-align: baseline;
  text-decoration: underline;
  padding: 0px 5px;
}

.new-sign-up .terms-checkbox input {
  border-radius: 8px !important;
  border: 1px solid #418e9e !important;
  background: #ffffff !important;
}

.new-sign-up .form-checkbox {
  display: inline-flex;
}

.new-sign-up .checkbox{
  margin: 0px;
}

.new-sign-up .title-section {
  margin: 5em;
  margin-top: 3em;
  margin-bottom: 1.5em;
}

@media (max-width: 992px){
  .new-sign-up .title-section {
    margin-left: 2em;
    margin-right: 2em;
  }

  .new-sign-up .options-container {
    padding: 0.5em !important;
    gap: 0.5em !important;
  }

  .new-sign-up .hlayout-bottom {
    padding-left: 2em;
    padding-right: 2em;
  }

  .new-sign-up .modal-button-container button {
    margin-bottom: 2em;
    margin-right: 2em;
  }
}

.new-sign-up .navigation-buttons {
  display: flex;
  background-color: #c4ecf1;
  width: 100%;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 1em;
  padding-top: 2em;
}

.new-sign-up .navigation-buttons .next {
  flex: 1;
  text-align: right;
}

.new-sign-up .navigation-buttons .back {
  flex: 1;
  text-align: left;
}

.new-sign-up .navigation-buttons .navigation-button {
  background: transparent;
  color: #418e9e;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  text-decoration: underline;
}

.new-sign-up .navigation-buttons .finish-pers {
  color: #FFFFFF;
  background-color: #14B4C8;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 25px;
  text-decoration: none;
}
.new-sign-up .navigation-buttons .finish-prac {
  color: #FFFFFF;
  background-color: #FAC832;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 25px;
  text-decoration: none;
}
.new-sign-up .navigation-buttons .back-terms {
  color: #FFFFFF;
  background-color: #14B4C8;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  border-radius: 25px;
  text-decoration: none;
  font-size: 16px;
  padding: 10px 20px;
}

.new-sign-up .about-user {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #2d6773;
}

.new-sign-up .no-colour {
  background-color: white;
  border: 0;
}

.new-sign-up .account-option-box {
  padding: 1em;
  border-radius: 10px;
  margin-bottom: 1em;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #646464;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.new-sign-up .center {
  text-align: center;
}

.new-sign-up .account-option-box img {
  height: 60px;
}

.new-sign-up .prac-colour {
  background-color: #fff8e2;
  border: 2.5px solid #fff8e2;
}
.new-sign-up .prac-colour-text {
  color: #fff8e2;
}
.new-sign-up .prac-colour-dark {
  background-color: #FEE9A7;
  border: 2.5px solid #FEE9A7;
}
.new-sign-up .prac-colour-dark-text {
  color: #FAC832;
  font-size: 22px;
}
.new-sign-up .prac-selected {
  border: 2.5px solid #fac832;
}

.new-sign-up .pers-colour {
  background-color: #e6f8fb;
  border: 2.5px solid #e6f8fb;
}
.new-sign-up .pers-colour-dark {
  background-color: #C2DCE1;
  border: 2.5px solid #C2DCE1;
}
.new-sign-up .pers-colour-dark-text {
  color: #418E9E;
  font-size: 22px;
}
.new-sign-up .pers-selected {
  border: 2.5px solid #81c3d0;
}

.new-sign-up .mix-colour {
  background-color: #fff0e7;
  border: 2.5px solid #fff0e7;
}
.new-sign-up .mix-selected {
  border: 2.5px solid #fb7720;
}

.new-sign-up .options-container {
  display: inline-flex;
  flex-wrap: wrap;
  flex: 0 0 30%;
  padding: 1em 4em;
  gap: 1em;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #646464;
  text-align: center;
  justify-content: center;
  cursor: pointer;
  width: fit-content;
}

.new-sign-up .big-options > div {
  height: 100px;
  display: flex;
  align-items: center;
}

.new-sign-up .options-container > div {
  width: 30%;
  text-align: center;
  flex-grow: 0;
  border-radius: 25px;
  padding: 1em 2em;
  position: relative;
}

.new-sign-up .option {
  display: block;
  text-align: center;
  width: 100%;
}

.new-sign-up .selected-option {
  background-image: url('../../../resources/img/tick.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  height: 2em;
  width: 2em;
  right: 5px;
  top: 0.5em;
}

.new-sign-up .type-ahead {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #646464;
  border-radius: 25px;
  height: 35px;
  box-shadow: none !important;
}

.new-sign-up .type-ahead > div {
  background-color: transparent;
  border: none;
  box-shadow: none;
  margin-left: 1em;
  margin-right: 1em;
}

.new-sign-up .type-ahead ul {
  display: none !important;
}

.new-sign-up .add-type-ahead {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #646464;
  border-radius: 25px;
  padding: 0.5em !important;
  height: 35px;
  cursor: pointer;
}

.new-sign-up .options-container .type-ahead-option {
  width: 25%;
  flex-grow: 1;
  border-radius: 25px;
  padding: 0.25em;
  position: relative;
  padding-right: 2em;
  padding-left: 0.7em;
  display: table;
}

.new-sign-up .options-container .type-ahead-option > p {
  vertical-align: middle;
  display: table-cell;
}

.new-sign-up .type-ahead-delete {
  position: absolute;
  height: 2em;
  width: 2em;
  right: 5px;
  top: 0.25em;
}

.new-sign-up .rc-slider-dot, .new-sign-up .rc-slider-track {
  display: none;
}

.new-sign-up .sitting-man {
  height: 4em;
  margin: 0 auto;
  display: block;
}
.new-sign-up .walking-man {
  height: 4em;
  margin: 0 auto;
  display: block;
}
.new-sign-up .running-man {
  height: 4em;
  margin: 0 10px;
  display: block;
}

.new-sign-up .preference > div {
  padding: 3em;
  margin: 0.5em;
  cursor: pointer;
  border-radius: 36.5px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #646464;
}

.new-sign-up .preference .selected-option {
  top: 1em;
  right: 2em;
  height: 2.5em;
  width: 2.5em;
}

.new-sign-up .pers-feedback-image {
  margin: 0 auto;
  display: block;
  text-align: center;
  margin-top: 2em;
  margin-bottom: 2em;
}

.new-sign-up .suggested-hubs-container {
  display: flex;
  flex-wrap: wrap;
  flex: 0 0 30%;
  padding: 1em 8em;
  gap: 1em;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.new-sign-up .suggested-hubs-container > div {
  width: 30%;
  flex-grow: 1;
  padding: 1em;
}

.new-sign-up .suggested-hubs-container .sug-hub-name {
  font-style: normal;
  font-weight: bolder;
  font-size: 18px;
  text-align: center;
  padding-top: 1em;
  color: #646464;
}

.new-sign-up .sug-hub-logo > img {
  aspect-ratio: 1 / 1;
  width: 100%;
  border-radius: 50%;
}

.new-sign-up .sub-hub-logo-pers > img {
  border: 12px solid #E6F8FB;
}
.new-sign-up .sub-hub-logo-prac > img {
  border: 12px solid #FFF8E2;
}

.new-sign-up .sug-hub-follow-btn {
  margin-top: 1em;
  margin-bottom: 0;
  cursor: pointer;
  border-radius: 36.5px;
  padding: 0.3em;
  padding-left: 1.1em;
  padding-right: 1.1em;
  display: inline-block;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
}

.new-sign-up .sug-hub-follow-btn-pers {
  background-color: #C4ECF1;
  color: #418E9E;
}
.new-sign-up .sug-hub-follow-btn-pers-selected {
  background-color: #418E9E;
  color: white;
}

.new-sign-up .sug-hub-follow-btn-prac {
  background-color: #FEE391;
  color: #CAA32D;
}
.new-sign-up .sug-hub-follow-btn-prac-selected  {
  background-color: #FAC832;
  color: white;
}

.new-sign-up .terms-content-container {
  max-height: 500px;
  overflow-y: scroll;
  overflow-x: hidden;
  border: 3px solid #14B4C8;
  border-radius: 20px;
  padding: 0 1em;
}

.new-sign-up .terms-content-container ol {
  padding-left: 20px;
  padding-bottom: 0px;
}

.new-sign-up .terms-content-container li {
  padding-bottom: 10px;
}

.new-sign-up .terms-content-container .ordered-letters {
  padding-left: 40px;
  list-style-type: lower-alpha;
}

.new-sign-up .terms-content-container .ordered-letters li{
  padding-bottom: 0;
}

.terms-content-container::-webkit-scrollbar {
  width: 1em;
}

.terms-content-container::-webkit-scrollbar-track {
  background-color: #F4F3F3;
  border-radius: 10px;
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
  margin: 1em;
}

.terms-content-container::-webkit-scrollbar-thumb {
  background-color: #14B4C8;
  border-radius: 10px;
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
}

.welcome-message {
  margin-left: 1em;
  margin-top: 2em;
  color: #14B4C8;
  font-weight: bold;
}

.welcome-message .welcome-big {
  font-size: 3.5em;
}

.welcome-message .welcome-small {
  font-size: 25px;
  display: block;
  color: #6DCCD8;
}

.welcome-message .underline {
  display: inline-block;
}

.welcome-message .underline hr {
  width: 100%;
  border-radius: 15px;
  border-color: #90D2DF;
  border-width: 6px;
  margin-top: 0;
  margin-bottom: 0;
}

.new-sign-up .progress-container {
  margin: 0 5em;
  position: relative;
}

.new-sign-up .col-md-12 {
  padding:0;
}

/* .new-sign-up .col-md-4 {
  width:34%;
} */

.new-sign-up .make-flex {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
}

.new-sign-up .progress-container hr {
  width: 100%;
  border-radius: 15px;
  border-color: #CBEBF0;
  border-width: 12px;
  margin-bottom: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  transition: width 1s ease;
}

.new-sign-up .progress-container .highlighted-progress {
  width: 20%;
  margin: 0;
  margin-top: -12px;
  border-color: #418E9E;
}

.new-sign-up .progress-container .sections_progress {
  display: flex;
  background-color: #F4F3F3;
  border-radius: 15px;
  padding: 0.5em;
  color: #B4B4B4;
  font-size: 1.1em;
  font-weight: bold;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  gap: 1em;
  justify-content: center;
  align-items: center;
  text-align: center;
}

@media (max-width: 550px){
  .new-sign-up .progress-container .sections_progress {
      display: none;
  }
}

.new-sign-up .progress-container .sections_progress span {
  flex: 1;
}

.new-sign-up .progress-container .sections_progress .selected {
  color: #83BFCB;
}

.running_man_progress {
  background-image: url('../../../resources/img/running_man.svg');
  background-position: right;
  background-repeat: no-repeat;
  position: absolute;
  height: 50px;
  width: 20%;
  top: calc(-50px - 5px);
  transition: width 1s ease;
}

.flag_progress {
  background-image: url('../../../resources/img/flag.svg');
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  height: 33px;
  width: 25px;
  top: calc(-33px - 5px);
  right: 0;
}

.welcome-back {
  color: #14B4C8;
  text-align: center;
  font-weight: bold;
}

.new-sign-up .modal-header-container{
  text-align: center;
  font-weight: 700;
}

.new-sign-up .terms-container{
  margin: 3em 4em 3em 4em;
}

.new-sign-up .h3{
  font-size: 20px;
}

.new-sign-up .birthday-input{
  display: flex;
  gap: 10px;
}

.new-sign-up .slider-label {
  width: 77px;
  margin: 8px auto 0px auto;
  height: 40px;
  color: #777777;
}

@media (max-width: 1200px){
  .new-sign-up .options-container>div{
      width: 45%
  }


}

@media (max-width: 500px){
  .new-sign-up .options-container>div{
      width: 100%
  }

  .new-sign-up .suggested-hubs-container{
    padding: 1em 1em;
  }

}

.input-g  > select, .birthday-input > select, .new-sign-up .no-padding > select {
  -webkit-appearance: none;
  appearance: none;
}

/* Custom arrow */
.input-g  > select, .birthday-input > select, .new-sign-up .no-padding > select {
  background-image: url('../../../resources/img/custom_dropdown.svg') !important;
  background-size: 16px;
  background-repeat: no-repeat;
  background-position: calc(100% - 8px) center;
}

@media (max-width: 992px) {
  .new-sign-up .flexbox {
    display: block;
  }

  .new-sign-up .suggested-hubs-container{
    padding: 1em 3em;
  }
}

@media (max-width: 425px) {
  .new-sign-up .suggested-hubs-container{
    padding: 1em 3em;
    display: block;
  }

  .new-sign-up .terms-container{
    margin: 3em 1.5em;
  }

  .new-sign-up .suggested-hubs-container > div {
    width: 100%;
    padding: 1em;
  }
}

.new-sign-up .row {
  margin: 0px;
}

.fill-question-container {
  background-color: rgba(250, 200, 50, 0.35);;
  padding-left: 20px;
  padding-bottom: 20px;
  border-radius: 25px;
  margin-top: 20px;
}

.fill-question-container .question-text-container {
  font-weight: bold;
  padding-top: 15px;
  padding-bottom: 20px;
  display: inline-block;
  width: 80%;
  padding-left: 10px;
  color: #646464;
}

.fill-question-container .control-label {
  margin-bottom: 10px;
  width: 100% !important;
}

.fill-question-container .answers-container {
  padding-left: 10px;
}

.fill-question-container ol {
  padding-left: 20px;
  list-style: outside;
  list-style-type: decimal;
  display: block;
}

.fill-question-container .correct {
  color: green;
}

.fill-question-container .incorrect {
  color: red;
}

.fill-question-container .correct-answer-container {
  margin: 15px 0px;
}

.fill-question-container .correct-answer-heading {
  font-weight: bold;
}

.fill-question-container .correct-answer-text {
  margin-left: 15px;
}

.fill-question-container .fa-check {
  padding-right: 20px;
}



.support-div {
  width: 80%;
  margin: 0 auto;
  margin-top: 5%;
  margin-bottom: 5%;
}

.support-div .support-subtitle {
  font-weight: bolder;
  color: #FA6400;
  font-size: 14px;
}

.support-div .support-title {
  font-weight: bold;
  color: #2D6773;
  font-size: 44px;
}

.support-div .support-text {
  color: #646464;
  font-size: 14px;
  font-weight: 0;
}

.support-div .support-package {
  margin-top: 25px;
}

.support-div .blocks{
  display: flex;
  gap: 20px;
  margin-top: 15px;
  flex-wrap: wrap;
}

.support-div .support-package .title {
  color: #2D6773;
  font-size: 22px;
  font-weight: bold;
  margin-top: 10px;
}

.support-div .tiers {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 5% 10%;
}

.support-div .tier-title {
  text-align: center;
  color: #2D6773;
  font-size: 18px;
  font-weight: bold;
  margin-top: 10px;
}

.support-div .contact-form-subtitle,
.support-div .contact-form-title,
.support-div .contact-form-desc,
.support-div .contact-img-container,
.support-div .mobile-contact-img {
  display: none;
}

.support-div .contact-form-container {
  display: block;
  max-width: 650px;
}

@media (max-width: 1024px) {
  .support-div .blocks{
    gap: 10px;
  }
  .support-div .block {
    height: 185px;
    padding-top: 40px;
  }

  .support-div .block-title {
    margin: 0 5px;
  }

  .support-div .block-text {
    font-size: 14px;
    margin: 3px 15px;
  }
}

@media (max-width: 768px){
  .support-div .tiers {
    padding: 5% 5%;
  }
  .support-div .tiers img {
    width: 85%;
  }
}

@media (max-width: 768px){
  .support-div .tiers {
    padding: 5% 0%;
  }
}

@media (max-width: 425px){
  .support-div {
    width: 90%;
  }

  .support-div .get-in-touch {
    text-align: center;
  }
}


.featured-module-modal .typeahead-container .form-control {
  height: auto;
}

.featured-module-modal .typeahead-container .rbt-input-wrapper {
  width: auto;
}

.featured-module-modal .typeahead-container .rbt-token {
  background-color: #e7f4ff;
  border: 0;
  border-radius: 2px;
  color: #1f8dd6;
  display: inline-block;
  line-height: 1em;
  margin: 0 3px 3px 0;
  padding: 4px 7px;
  position: relative;
}

.featured-module-modal .typeahead-container .rbt-token .rbt-token-remove-button {
  bottom: 0;
  color: inherit;
  font-size: inherit;
  opacity: 1;
  right: 6px;
  position: absolute;
  text-shadow: none;
  top: -2px;
}

.featured-module-modal .typeahead-container  .rbt-token-removeable {
  cursor: pointer;
  padding-right: 21px;
}

.manage-featured-container td, .manage-featured-container th {
  font-family: var(--font-main);
}

#page-wrapper {
  padding-left: 235px;
  transition: all 0.35s ease;
  overflow: hidden;
  min-height: calc(100vh - 209.5px);
}

.side-bar {
  position: fixed;
  background-color: white;
  border-right: 1px solid #eeeeee;
  height: 100%;
  margin-top: -78px; /* offset for height of nav incl. padding */
  z-index: 1031;
  width: 235px;
  transition: all 0.35s ease;
  min-height: 550px;
  padding-top: 1em;
  overflow-y: auto;
}

.side-bar .actify-logo {
  background-image: url('../../resources/img/actify_icon.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: auto;
  height: 64px;
  cursor: pointer;
  margin-bottom: 1em;
}

.side-bar .selected-home {
  filter: drop-shadow(0 0 2em var(--color-background-darkest-role));
}

.side-bar .nav-buttons {
  width: 70%;
  margin: 0 auto;
}

.side-bar .nav-buttons > div {
  padding: 0.25em 1em;
  font-size: 1.125em;
  font-weight: bold;
  color: #B4B4B4;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 0.25em;
}

.side-bar .nav-buttons > div:hover {
  background-color: var(--color-background-role);
  border-radius: 15px;
  color: #646464;
}

.side-bar .nav-buttons svg {
  font-size: 1.25em;
  border-radius: 6px;
}

.side-bar .nav-buttons .selected, .side-bar .followed-hubs .selected {
  background-color: var(--color-background-role);
  border-radius: 15px;
  color: #646464;
}

.side-bar .nav-buttons .icon {
  min-width: 40px;
  text-align: center;
  padding: 5px;
  margin-right: 5px;
}
.side-bar .nav-buttons .selected .icon {
  color: var(--color-background-darkest-role);
  background-color: var(--color-background-darker-role);
  border-radius: 15px;
}

.side-bar .side-bar-title {
  font-size: 1.2em;
  font-weight: bold;
  text-align: center;
  display: block;
  color: #B4B4B4;
}

.side-bar .followed-hubs {
  width: 80%;
  margin: 0 auto;
}

.side-bar .followed-hubs > div {
  padding: 0.25em 0;
  font-weight: bold;
  color: #646464;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 0.25em;
}

.side-bar .followed-hubs > div:hover {
  background-color: #EFFDFF;
  border-radius: 15px;
  color: #646464;
}

.side-bar .followed-hubs .hub-icon {
  height: 35px;
  min-width: 35px;
  max-width: 35px;
  border-radius: 50%;
  background-position: center center;
  background-size: cover;
  margin-right: 0.5em;
  margin-left: 0.5em;
  transition: all .35s ease;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.side-bar .followed-hubs .hub-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.side-bar .view-more {
  text-align: center;
  display: block !important;
  width: 100%;
}

.side-bar .supported-scotland {
  background-image: url('../../resources/img/gov_scotland.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 20px;
  margin-top: 0.5em;
  margin-bottom: 1em;
}

@media (max-width: 1023px) {
  .side-bar {
    display: none;
  }

  #page-wrapper {
    padding-left: 0;
    min-height: calc(100vh - 280px);
  }
}

@media (max-width: 600px) {
  #page-wrapper {
    min-height: calc(100vh - 523px);
    margin-top: 0px !important;
  }
}

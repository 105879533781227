.assign-course-modal-container {
    text-align: center;
    padding: 0px 40px 20px 40px;
  }
  
  .assign-course-modal-container .text-container {
    margin-top: 60px;
  }
  
  .assign-course-modal-container p {
    font-size: 30px;
    font-weight: bolder;
    color: #14B4C8;
  }

  .assign-course-modal-container .dropdown-containers{
    padding-top: 30px;
    padding-bottom: 20px;
    justify-content: center;
  }

  .assign-course-modal-container .form-group{
    display:flex
  }

  .assign-course-modal-container .label-1{
    flex: 0.6;
    justify-content: flex-start;
    padding-top: 5px;
    font-size: 18px;
    font-weight: bold;
    color: #646464;
  }

  .assign-course-modal-container .form-control{
    display:flex;  
  background-color: #fff7e0; /* Light cream background */
  border: 2px solid #e8b954; /* Border color */
  border-radius: 25px; /* Rounded edges */
  padding: 7px 40px 7px 15px; /* Add padding for content and arrow */
  color: #333; /* Text color */
  cursor: pointer;
    width: 50%;
    height: 38px;
  }

  .assign-course-modal-container .control-label{
    justify-content: flex-start;
    padding-top: 5px;
    font-size: 18px;
    font-weight: bold;
    color: #646464;
    display: flex;
    flex: 0.22;
    padding-left: 10%;
  }

  .assign-course-modal-container  .react-date-picker__button{
    width: 100%;
    border-radius: 36.5px;
    padding: 0px 12px;
    background-color: #FFF8E2;
    border: 2px solid #e8b954;
    color: #555555;
    justify-content: flex-start; /* Horizontally center */
    align-items: center;
  }
 
  .assign-course-modal-container .react-date-picker {
    display: flex;
    position: relative;
    flex: 0.56;
}

.assign-course-modal-container .save-button{
  text-transform: uppercase;
  font-size: medium;
  font-weight: bold;
  color: #14B4C8;
  background-color: white;
  display: flex;
  justify-self: flex-end;
  margin-right: 40px;
  border: none;
}

.assign-course-modal-container .save-button:active{
  color: #14B4C8;
  background-color: white;
  border: none;
}
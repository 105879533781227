.main-container {
  padding-left: 18px;
  display: flex;
}

.message-tab {
  /* padding: 0 2.5%; */
  min-height: 500px;
  flex:1;
  max-width: 50%;
}

.message-tab2 {
  /* padding: 0 2.5%; */
  min-height: 500px;
  flex:1;
  max-width: 50%;
}

.message-tab .title {
  font-weight: bold;
  font-size: 1.5em;
  color: #646464;
  display: block;
  text-align: left;
}

.message-tab .avatar-image {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-position: center center;
  background-size: cover;
  border: 1px solid var(--colour-light-extra);
}

.inline-display {
  flex-direction: column;
}

.message-tab .message-content {
  overflow: hidden;
  text-overflow: ellipsis;
  padding-bottom: 0.5em;
  padding-left: 5px;
  padding-right: 5px;
}

.message-tab .date-row {
  margin: 0 0 auto auto;
}

.message-tab .name-row {
  font-weight: bold;
  max-height: 80px;
  overflow: hidden;
  text-overflow: ellipsis;
  /* white-space: nowrap; */
  padding-top: 0.25em;
  padding-bottom: 0.5em;
  padding-left: 5px;
  padding-right: 5px;
}

.message-tab .view-message-container {
  text-align: right;
}

.messageRow {
  display: flex;
  padding: 18px 18px 10px 18px;
  margin-left: -18px;
  margin-right: -18px;
  border-bottom: 0.5px solid #EFEFEF;
}

.message-tab .messageRow:hover {
  background-color: var(--color-background-darker-role);

}

.heading1 {
  background-color: #FAF9F9;
  margin-left: -18px;
  margin-right: -18px;
}

.heading {
  font-weight: 700;
  font-size: 1.5em;
  color: #646464;
  display: block;
  text-align: left;
  padding: 5px 0px 5px 18px;
  margin-bottom: 0px;
}

.message-tab button {
  background-color: var(--color-background-darkest-role);
  background-image: none;
  color: #ffffff;
  text-transform: uppercase;
  text-shadow: none;
  font-size: 14px;
  font-weight: normal;
  border: 1px solid transparent;
  border-radius: 4px;
}

.message-tab table {
  width: 100%;
}

.message-tab th {
  font-size: 1.25em;
}

.message-tab td {
  padding-top: 0.25em;
  padding-bottom: 0.5em;
  padding-left: 5px;
  padding-right: 5px;
}

.tableHeading th {
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 0.25em;
  border-bottom: 1px solid;
}

.message-tab tr {
  transition: all ease 0.3s;
}

.message-tab .add-user {
  padding-top: 0.25em;
  padding-bottom: 0.75em;
  display: flex;
  gap: 1em;
}


.message-tab .add-user .form-control{
  border-radius: 15px;
  min-width: 25vw;
}

.message-tab2 .message-image {
  vertical-align: middle;
  width: 100%;
}

.message-tab2 .message-image {
  vertical-align: middle;
  width: 100%;
}

.message-image > img {
  max-width: max(220px, 50%);
  height: auto;
}

.message-image-title {
  text-align: center;
  margin-top: 112px;
}

.message-tab2 .click-on-title {
  background-color: var(--color-background-darker-role);
  color: var(--colour-light-message);
  border-radius: 25px;
  font-weight: bold;
  padding: 8px;
  font-size: 15px;
  margin: auto;
  margin-top: 20px;
  max-width: max(220px, 50%);
}

.message-tab2 .mobile-conversation-header{
  display: none;
}

@media (max-width: 600px) {
  .message-image-title{
    display: none;
  }

  .main-container{
    display: block;
  }

  .message-tab{
    max-width: none;
  }

  .message-tab2{
    max-width: none;
    margin-left: -18px;
    display: block;
    min-height: max-content;

  }

  .no-toggle {
    display: block;
  }

  .toggle {
    display: none !important;
  }

  .message-tab2 .mobile-conversation-header{
    display: flex;
    height: 60px;
    align-items: center;
    margin: 10px 18px;
  }

  .mobile-conversation-header .avatar-image {
    height: 55px;
    width: 55px;
  }

  .mobile-conversation-header .name-row {
    font-weight: bold;
    max-height: 80px;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    padding-left: 10px;
    padding-right: 5px;

  }

  .mobile-conversation-header .back-button{
    margin-left: auto;
  }

  .back-button {
    color: var(--colour-accent-1);
    text-decoration: underline;
  }
}

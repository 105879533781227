.plan-page-container .row {
  margin-left: 0px;
  margin-right: 0px;
}

.plan-page-container .plan-content-container {
  padding: 0% 2%;
}

.plan-page-container .plan-content-sidebar {
  padding: 0% 2%;
}

.plan-page-container .sidebar-content-container{
  background-color: #f5f5f5;
  padding: 1% 6%;
}

.plan-page-container .content-container {
  background-color: white;
}

.plan-page-container .plan-image {
  height: 100%;
  width: 100%;
}

.plan-page-container .plan-image-container:hover {
  border: 0px;
  box-shadow: 0 0 2px 1px #01745e;
  cursor: pointer;
  border-radius: 2px;
}

.plan-page-container .fa-5x {
  color: #ff0000;
  padding-bottom: 10px;
}

.plan-page-container .plan-image-container {
  height: 110px;
  width: 196px;
  text-align: center;
  overflow: hidden;
  border: 1px solid black;
}

.plan-page-container .video-wrapper {
  display: inline-block;
  width: 400px;
  height: 250px;
}

.plan-page-container .video-col {
  text-align: left;
  margin-top: 10px;
  margin-left: -15px;
  min-width: 430px;
}
.plan-page-container .pdf-col {
  text-align: center;
}

.plan-page-container .pdf-col:hover {
  cursor: pointer;
}

.plan-page-container .padding-line {
  height: 3px;
  width: 100%;
  background-color: #f5f5f5;
  margin: 30px 0px;
}

.plan-page-container .text-content-row {
  margin-bottom: 30px;
  padding: 30px 10% 20px 30px;
  background-color: #f5f5f5;
}

.plan-page-container .resource-heading {
  margin-bottom: 30px;
}

.plan-page-container .sub-category-container {
  margin: 20px 0px;
}

.plan-page-container .list-text {
  padding-left: 10px;
}

.plan-modal-container {
  text-align: center;
}

.plan-modal-container h3 {
  margin: 30px 0px;
}

.plan-modal-container p {
  margin: 20px 0px;
}

.plan-modal-container .button-container {
  margin: 30px 0px;
}

.plan-modal-container button {
  background-color: #e59552;
  background-image: none;
  color: #ffffff;
  text-transform: uppercase;
  border-color: #e59552;
  width: 30%;
  margin: 0px 10px;
  text-shadow: none;
}

.action-button.normal {
  background-color: var(--colour-accent-1);
}

.action-button {
  font-family: var(--font-main);
}

.plan-page-container .plan-content {
  line-height: normal;
  text-align: left;
  padding-left: 0px;
}

@media (min-width: 992px) {
  .plan-details-container {
    text-align: left;
  }

}


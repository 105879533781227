.home-container {
  height: 100%;
  margin-left: 2em;
  margin-right: 2em;
  margin-bottom: 5em;
}

.first {
  background-color: var(--colour-light-main);
}

.info-icon {
  color: var(--colour-accent-2);
  font-size: 2.7em;
  margin-top: 0.5em;
  margin-bottom: 0.25em;
}

.info-title {
  font-weight: bold;
  font-size: 1.4em;
  color: var(--colour-accent-2);
  font-family: var(--font-headings);
}

.info-desc {
  color: rgb(153, 153, 153);
  font-size: 1.2em;
  font-family: var(--font-main);
}

.theme-pills-container {
  padding: 0 5em 1em 5em;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
}

.faq-container {
  width: 60%;
  margin: 0 auto;
}

.bottom-join-btn {
  padding: 0 4.5em 3em 4.5em;
  display: flex;
  width: 30%;
  margin: 0 auto;
}

.home-container .what-we-do {
  background-color: #DBFAFF;
  display: flex;
  flex-wrap: wrap;
  width: auto;
  margin: 0 -2em;
  padding: 0 7em;
}

.home-container .what-we-do>div {
  flex: 50%;
}

.home-container .what-we-do .text-container {
  padding: 2em 6em 2em 7em;
  text-align: center;
}

.home-container .what-we-do .title-text {
  display: block;
  font-weight: bolder;
  color: #2D6773;
  font-size: 2em;
  padding-top: 1em;
  line-height: 35px;
}

.home-container .what-we-do .main-text {
  display: block;
  color: #646464;
  font-size: 1.2em;
  padding: 1.5em 2em;
}

.home-container .blue-button {
  background-color: #14b4c8 !important;
  color: white !important;
  border-radius: 100px;
  box-shadow: 0px 4px 3px #bbb !important;
  font-weight: bold;
  font-size: 16px;
  padding: 10px 20px;
}

.home-container .support-container {
  color: #2D6773;
  font-weight: bold;
  float: right;
  margin-top: 2em;
  display: flex;
  margin-right: 3em;
  position: relative;
}

.home-container .video-container {
  position: relative;
}

.home-container .supported-scotland {
  background-image: url('../../resources/img/gov_scotland.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 24px;
  width: 160px;
  margin-left: 15px;
}

.home-container .video iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
}

.home-container .text {
  max-width: 700px;
  display: block;
  margin: 0 auto;
  color: #646464;
}

.home-container .how-to-use-container {
  text-align: center;
  clear: right;
  margin-top: 3em;
}

.home-container .how-to-use-container .how-to-use-title {
  font-weight: bolder;
  color: #2D6773;
  font-size: 1.5em;
  line-height: 35px;
  display: block;
}

.home-container .how-to-use-container .text {
  display: block;
  color: #646464;
  font-size: 1.2em;
  margin-top: 1em;
}

.home-container .how-use {
  padding: 1.5em 10em;
  font-size: 1.2em;
}

.home-container .choices {
  display: flex;
  margin: 0 5em;
  gap: 20px;
}

.home-container .choices>div {
  flex: 50%;
  cursor: pointer;
}

.home-container .helping-others {
  background-image: url('../../resources/img/helping_others_scene.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  height: 220px;
  max-width: 350px;
  margin: 0 auto;
  border-radius: 70px;
  border: 0px solid #FAC832;
}

.home-container .running-treadmill {
  background-image: url('../../resources/img/running_treadmill.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  height: 220px;
  max-width: 350px;
  margin: 0 auto;
  border-radius: 70px;
  border: 0px solid #14B4C8;
}

.home-container .selected {
  border-width: 10px;
}

.home-container .pill-select {
  margin-top: 2em;
}

.home-container .pill-select>span {
  padding: 0.5em 2.5em;
  border-radius: 100px;
  position: relative;
}

.home-container .prac-choice {
  border-color: #FAC832;
  background-color: #FFF8E2;
  color: #646464;
}

.home-container .pers-choice {
  background-color: #E6F8FB;
  color: #646464;
}

.home-container .prac-choice-selected {
  background-color: #FAC832;
  color: white;
}

.home-container .pers-choice-selected {
  background-color: #14B4C8;
  color: white;
}

.home-container .how-to-use-content-pers {
  border-style: solid;
  border-color: #418E9E;
  border-radius: 97px;
  margin: 4em 15em;
  padding-top: 3em;
  padding-bottom: 1em;
}

.home-container .how-to-use-content-prac {
  border-style: solid;
  border-color: #FAC832;
  border-radius: 97px;
  margin: 4em 15em;
  padding-top: 3em;
  padding-bottom: 1em;
}

.home-container .what-can-do {
  border: 2px solid #14B4C8;
  padding: 2em;
}

.home-container .sign-up-button-container {
  margin-top: 2em;
  margin-bottom: 2em;
}

.div-back-to-top {
  text-align: right;
}

.home-container .icons-container {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin: 3em 0;
}

.home-container .carousel-container {
  margin-top: 1em;
  margin-bottom: 3em;
}

.home-container .react-multi-carousel-dot-list {
  position: relative;
  margin: 1em;
}

/* Mobile responsiveness */

@media (max-width: 1024px) {
  .home-container .what-we-do .title-text {
    font-size: 1.5em;
  }

  .home-container .what-we-do .text-container {
    padding: 1em 2em 1.5em 1em;
  }

  .home-container .what-we-do .main-text {
    padding: 0.8em;
  }

  .home-container .what-we-do {
    padding: 0em 0em 0em 4em;
  }

  .home-container .how-use {
    padding: 1.5em 1em;
  }

  .home-container .how-to-use-content-prac {
    margin: 4em;
  }

  .home-container .how-to-use-content-pers {
    margin: 4em;
  }
}

@media (max-width: 768px) {

  .home-container .what-we-do .title-text {
    font-size: 2em;
  }

  .home-container .what-we-do {
    display: block;
    width: auto;
    margin: 0 -2em;
    padding: 0 10%;
    background: linear-gradient(to bottom, #EFFBFD, #DBFAFF);
  }

  .home-container .what-we-do>div {
    display: block;
  }

  .home-container .video-container{
    height: 330px;
  }

  .home-container .support-container{
    margin-top: 1em;
    margin-right: 1em;
  }

  .home-container .how-to-use-container{
    margin-top: 4em;
  }

  .home-container .choices {
    display: flex;
    margin: 0 1em;
    gap: 20px;
  }

  .home-container .how-to-use-content-prac {
    margin: 1em;
    padding-bottom: 2em;
  }

  .home-container .how-to-use-content-pers {
    margin: 1em;
    padding-bottom: 2em;
  }

  .home-container .how-to-use-content-prac .how-to-use-title, .home-container .how-to-use-content-pers .how-to-use-title{
    padding: 0em 1.5em;
  }

  .home-container .icons-container {
    margin: 1em 1.5em;
    padding: 1em;
  }

  .home-container .icons-container img {
    max-width: 32%;
  }

  .choices .pill-select>span {
    display: inline-block;
}

.home-container .how-to-use-content-prac .text,  .how-to-use-content-pers .text {
  padding: 0em 5em;
  font-size: 1em;
}

.home-container .sign-up-button-container {
  margin-top: 1em;
  margin-bottom: 2.5em;
}

}
@media (max-width: 425px) {
  .home-container {
    margin-left: 5px;
    margin-right: 5px;
  }

  .home-container .what-we-do .title-text {
    font-size: 1.8em;
    margin-bottom: 1em;
  }

  .home-container .what-we-do .main-text {
    display: none;
  }

  .home-container .what-we-do{
    padding: 0;
  }

  .home-container .choices {
    display: flex;
    margin: 0;
    gap: 5px;
  }

  .home-container .running-treadmill, .home-container .helping-others {
    height: 150px;
    max-width: 175px;
    border-radius: 50px;

  }

  .home-container .how-to-use-content-prac {
    margin: 1em;
    border-radius: 55px;
    padding-top: 1.5em;
  }

  .home-container .how-to-use-content-pers {
    margin: 1em;
    border-radius: 55px;
    padding-top: 1.5em;
  }

  .home-container .how-to-use-content-prac .how-to-use-title, .how-to-use-content-pers .how-to-use-title{
    padding: 0em 1.5em;
  }

  .home-container .how-to-use-content-prac .text,  .how-to-use-content-pers .text{
    padding: 0em 2em;
  }

  .home-container .icons-container {
    margin: 1em 1.5em;
    padding: 1em;
    text-align: center;
    display: block;
  }

  .home-container .icons-container img {
    max-width: 100%;
    display: block;
    margin: auto;
    margin-bottom: 1em;
  }

  .home-container .video-container{
    height: 225px;
  }
  .home-container .what-we-do .text-container {
    padding: 1em 3em 1.5em 3em;
  }

  .home-container .support-container {
    float: none;
    width: 100%;
    justify-content: center;
  }

  .div-back-to-top {
    text-align: right;
    margin-right: 2em;
  }
}

@media (max-width: 375px){
  .choices .pill-select>span {
    padding: 0.5em 1em;
}
}

@media (max-width: 320px) {
  .home-container .video-container{
    height: 180px;
  }

  .home-container .what-we-do .title-text {
    font-size: 1.7em;
}



.home-container .what-we-do .main-text {
  padding: 0.5em 1.5em;
}

}

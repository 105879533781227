.profile-tab-container {
  margin-top: 2em;
  margin-bottom: 2em;
}

.profile-tab-container > div {
  padding-left: 2.5%;
}

.profile-tab-container .title {
  font-weight: bold;
  font-size: 1.5em;
  color: #646464;
  display: block;
  text-align: left;
  margin-bottom: 1em;
  margin-left: 10px;
}

.profile-tab-container .my-hubs {
  margin-top: 3em;
  padding-top: 2em;
  padding-bottom: 2em;
  background-color: var(--color-background-darker-role);
}

.bookmark-button {
  background-color: var(--colour-accent-1);
  background-image: none;
  color: #ffffff;
  text-transform: uppercase;
  text-shadow: none;
  padding: 5px 15px;
  margin: 10px;
}

.empty-text {
  color: #B4B4B4;
}

.profile-info-icon{
  height: 23px;
  width: 23px;
  margin-left: 5px;
}

.info-box{
  position: absolute;
  z-index: 99999;
  display: block;
  margin-left: 112px;
  margin-top: -25px;
}

.profile-info-icon:hover{
  opacity: 0.5;
  cursor: pointer;
}

.walkthrough-container{
  position: fixed;
  height: 80px;
  right: 0px;
  bottom: 100px;
  margin-right: 40px;
}

.walkthrough-button {
  height: 40px;
  width: 40px;
  cursor: pointer;
  border-radius: 50%;
}

.walkthrough-button:hover, .walkthrough-button:focus{
  box-shadow: 0 0.5em 1em -0.4em black;
  transform: translateY(-0.25em);
}

.profile-tab-container .top-title{
  font-weight: bold;
  font-size: 1.5em;
  color: #646464;
  display: block;
  text-align: left;
  margin-bottom: 1em;
  margin-left: 10px;
}

@media (max-width: 590px){
  .profile-info-icon{
    height: 23px;
    width: 23px;
    margin-left: 8px;
  }

  .profile-info-icon:hover{
    opacity: 0.5;
    cursor: pointer;
  }

  .profile-tab-container .top-title{
    display: inline-block;
    width: 55%;
    margin-left: 20px;
  }

  .info-box{
    position: absolute;
    z-index: 99999;
    display: block;
    margin-left: -20px;
    margin-top: -25px;
  }
  .walkthrough-button {
    height: 45px;
    width: 45px;
  }
}

@media (max-width: 425px) {
  .profile-tab-container > div {
    padding-left: 20px;
  }
  .profile-tab-container .hub-tile-outer-container {
    padding-right: 20px;
  }
}

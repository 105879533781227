.my-sessions-container {
  padding: 2.5%;
  text-align: center;
}

.my-sessions-container .sessions-calendar {
  width: 100%;
  height: min(100vw, 500px);
}

.my-sessions-container .calendar-title {
  /* text-align: center;
  position: relative;
  margin-bottom: 25px;
  font-weight: 600;
  background-color: var(--color-background-role);
  width: 202px;
  border-radius: 26.5px;
  padding: 9px;
  color: #646464; */
  font-weight: bold;
  font-size: 1.5em;
  color: #646464;
  display: block;
  text-align: left;
  margin-bottom: 1em;
  margin-left: 10px;
}

.rbc-btn-group {
  position: relative;
  float: right;
  top: -5px;
  margin-right: -15px;
}

.rbc-toolbar {
  display: inline-block;
  text-align: right;
  width: 100%;
}

.rbc-toolbar-label {
  vertical-align: -webkit-baseline-middle;
  margin-right: -20px;
}

.rbc-btn-group > button:not(:first-child):not(:last-child) {
  position: relative;
  float: left;
  top: 3px;
  left: 20px;
  border: 0px;
  visibility: hidden;
}
.rbc-btn-group > button:not(:first-child):not(:last-child):before {
  font-size: 20pt;
  visibility: visible;
  font-family: 'FontAwesome';
  content: "\f104";
}

.rbc-btn-group > button:first-child:not(:last-child) {
  border-radius: 5px;
  width: 120px;
  padding: 8px 0;
  background-color: var(--colour-session-calendar-today);
  color: white;
}

.rbc-btn-group > button:last-child {
  position: relative;
  left: -20px;
  border: 0px;
  visibility: hidden;
}

.rbc-btn-group > button:last-child:after {
  font-size: 20pt;
  visibility: visible;
  font-family: 'FontAwesome';
  content: "\f105";
}

.my-sessions-container .rbc-month-view {
  border: 0px;
}

.my-sessions-container .rbc-month-view .rbc-header {
  border: 0px;
}

.my-sessions-container .rbc-day-bg {
  border: 0px;
  background-color: var(--colour-session-calendar-day);
  margin: 5px;
  border-radius: 5px;
}

.my-sessions-container .rbc-day-bg.rbc-today {
  background-color: var(--colour-session-calendar-today);
}

.my-sessions-container .rbc-date-cell {
  margin: 10px 0 10px 10px;
  padding-left: 2px;
}

.my-sessions-container .rbc-event {
  margin: 0px 2px;
}

.my-sessions-container .rbc-day-bg.rbc-off-range-bg {
  border: 0px;
  background-color: var(--colour-light-extra);
  margin: 5px;
}

.my-sessions-container .rbc-month-row {
  border: 0px;
}

.my-sessions-container .rbc-date-cell {
  text-align: left;
}
.my-sessions-container .rbc-off-range-bg .rbc-event, .my-sessions-container  .rbc-off-range-bg .rbc-event.rbc-selected {
  color: #666;
  font-size: 11pt;
  font-weight: bold;
  background: none;
}

.my-sessions-container .rbc-event, .my-sessions-container .rbc-event.rbc-selected {
  color: black;
  font-size: 11pt;
  font-weight: bold;
  background: none;
  text-align: left;
  position: relative;
  top: -10px;
}

.my-sessions-container .rbc-event-content:before {
  font-family: 'FontAwesome';
  content: '\f111';
  font-size: 10pt;
  padding: 0 7px;
  color: var(--color-background-darkest-role);
}

.session-details-modal .modal-content {
  max-width: 550px;
  margin: auto;
  position: relative;
}

.session-details-modal .modal-dialog {
  margin: 0px auto;
  top: 25%;
  width: 95%;
}

.info-icon{
  height: 23px;
  width: 23px;
  margin-top: 10px;
  margin-left: 5px;
}

.session-info-box{
  position: absolute;
  z-index: 99999;
  display: block;
  margin-left: 47px;
  margin-top: -40px;
}

.info-icon:hover{
  opacity: 0.5;
  cursor: pointer;
}

@media (max-width: 1024px) {
  .my-sessions-container {
    text-align: left;
  }
}

@media (max-width: 425px){
  .rbc-btn-group > button:first-child:not(:last-child){
    width: 90px;

  }
}

@media (max-width: 590){
  .info-icon{
    height: 23px;
    width: 23px;
    margin-top: 10px;
    margin-left: 5px;
  }

  .info-icon:hover{
    opacity: 0.5;
    cursor: pointer;
  }
}

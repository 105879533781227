.contact-form-container {
  display: flex;
  gap: 3em;
  width: 80%;
  margin: 0 auto;
  margin-top: 5%;
  margin-bottom: 5%;
}

.contact-form-container .help-block{
  padding-left:1em;
  color: #FA6400;
}

.contact-form-container .person-icon {
  padding-left: 1em;
}

.contact-form-container .success-text-title {
  font-weight: bolder;
}

.contact-form-container > div {}

.contact-form-container .contact-form {
  width: 100%;
  padding: 0.5em;
}

/* title/details */

.contact-form-container span {
  display: block;
  margin-bottom: 0.5em;
}

.contact-form-container .contact-form-subtitle {
  font-weight: bolder;
  color: #FA6400;
  font-size: 14px;
}

.contact-form-container .contact-form-title {
  font-weight: bold;
  color: #2D6773;
  font-size: 48px;
}

.contact-form-container .contact-form-desc {
  color: #646464;
}

.contact-form-container .contact-form-name-email {
  display: flex;
  gap: 1em;
}

/* form */

.contact-form-container .contact-form-name-email > * {
  flex: 50%;
}

.contact-form-container .form-input {
  background-color: var(--color-background-role);
  border-radius: 25px;
  border: 1px solid var(--color-background-darkest-role);
  resize: none;
  box-shadow: none !important;
  padding-left: 1em;
}

.contact-form-container .form {
  position: relative;
}

.contact-form-container .input-icon {
  position: absolute;
  right: 0;
  top: 0;
  margin-top: 9px;
  margin-right: 1em;
}

.contact-form-container .character-count {
  text-align: right;
  color: var(--color-background-darkest-role-other);
  font-weight: bold;
  font-size: 1.1em;
  padding-right: 1em;
}

.contact-form-container .message-text-area {
  padding: 1em;
}

.contact-form-container .submit-button-container {
  text-align: right;
}

.contact-form-container .submit-button-container .submit-button {
  background-color: var(--color-background-darkest-role-other);
  border-radius: 25px;
  color: white;
  font-weight: bold;
  font-size: 1.25em;
  padding: 0.5em 0.75em;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%) !important;
}

/* image */

.contact-form-container .contact-img-container {
  width: 325px;
  height: 309px;
}

.contact-form-container .contact-img {
  background-image: url('../../resources/img/contact_image.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 89.5px;
  width: 382px;
  height: 363px;
  margin-top: 50px;
}

/* form feedback */

.contact-form-container .form-success {
  border: 1px solid #FAC832;
  border-radius: 17px;
  padding: 1em;
  position: relative;
}

.contact-form-container .form-failure {
  border: 1px solid #FA6400;
  border-radius: 17px;
  padding: 1em;
  position: relative;
}

.contact-form-container .success {
  position: absolute;
  bottom: 1em;
  right: 1em;
}

.contact-form-container .failure {
  position: absolute;
  bottom: 1em;
  right: 1em;
}

.contact-form-container .feedback-top {
  display: flex;
  max-width: 500px;
  margin: 0 auto;
}

.contact-form-container .feedback-desc {
  color: #646464;
  max-width: 500px;
  margin: 0 auto;
  margin-top: 1.5em;
}

.contact-form-container .feedback-top > span {
  font-size: 36px;
}

.contact-form-container .feedback-top .success-text {
  color: #FAC832;
  font-weight: bolder !important;
}

.contact-form-container .feedback-top .failure-text {
  color: #FA6400;
  font-weight: bolder !important;
}

.contact-form-container .feedback-top .feedback-subtext {
  color: #646464;
  font-size: 20px;
  font-weight: 700;
}

/* media queries */

@media (max-width: 1100px){
  .contact-form-container {
    width: 90%;
  }
}

@media (max-width: 990px){
  .contact-form-container .contact-img-container {
    width: 250px;
    height: 238px;
    margin: 0 auto;

  }

  .contact-form-container .contact-img {
    width: 250px;
    height: 238px;
  }
}

@media (max-width: 911px){
  .contact-form-container .contact-img-container  {
    display: none;
  }
}

@media (max-width: 767px){
  .contact-form-container{
    display: block;
    margin-top: 0;
  }
  .contact-form-container .contact-form .contact-form-subtitle{
    text-align: center;
    padding-top: 46px;
  }

  .contact-form-container .contact-form .contact-form-title{
    text-align: center;
  }
  .contact-form-container .contact-form .contact-form-desc{
    margin-top: 32px;
    text-align: center;
  }

  .mobile-contact-img {
    background-image: url('../../resources/img/contact_image.svg');
    background-position: center 67%;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 200px;
  }
}


.bottom-nav {
  bottom: 0;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: var(--color-background-role);
  color: #CCCCCC;
  font-weight: bold;
  height: 5em;
  z-index: 1001;
}

.bottom-nav > div {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
  font-size: 1.1em;
  cursor: pointer;
}

.bottom-nav > div > span > div {
  display: inline-block;
  padding: 0.25em 0.5em;
  border-radius: 6px;
}

.bottom-nav svg {
  font-size: 1.5em;
}

.bottom-nav .active {
  color: #646464;
}

.bottom-nav .active > span > div {
  background-color: var(--color-background-darker-role);
  color: var(--color-background-darkest-role);
}

@media (min-width: 785px) {
  .bottom-nav {
    display: none;
  }
}

@media (max-width: 440px) {
  .bottom-nav > div .nav-text {
    display: none;
  }
}

.myprofile-container .nav-tabs {
  border: none;
  padding-left: 2.5%;
  padding-top: 15px;
  padding-bottom: 15px;
  display: flex;
  border-bottom: 1px solid #eeeeee;
}

.myprofile-container .nav-tabs > li.active > a, .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus {
  border: none;
  color: black;
  background-color: var(--color-background-darker-role);
  border-radius: 18px;
  border: 2.5px solid var(--color-background-darkest-role);
  color: #646464;
}

.myprofile-container .tab-container a {
  color: #B4B4B4;
  white-space: nowrap;
  padding: 8px 15px;
  font-weight: bold;
  border: 2.5px solid transparent;
}

.myprofile-container .tab-container span {
  display: flex;
  align-items: center;
  gap: 0.5em;
}

.myprofile-container .tab-container svg {
  font-size: 1.2em;
}

.myprofile-container .tab-container a:hover {
  color: #B4B4B4;
  white-space: nowrap;
  padding: 8px 15px;
  font-weight: bold;
  background-color: var(--color-background-darker-role);
  border-radius: 18px;
  border: 2.5px solid transparent;
}

.myprofile-container ::-webkit-scrollbar {
  height: 8px;
}

.myprofile-container ::-webkit-scrollbar-thumb {
  background-color: var(--colour-light-extra);
  transition: ease-in-out 1.5s background-color;
}

.myprofile-container ::-webkit-scrollbar-thumb:hover {
  background-color: var(--colour-dark-side);
}

.icon-container {
  position: relative;
  display: inline-block;
}

.screen-lines {
  position: absolute;
  top: 20%;
  left: 25%;
  width: 50%;
  height: 20%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap:5%;
}

.line {
  height: 4px;
  background-color: #666;
  border-radius: 2px;
}


@media (max-width: 900px) {
  .myprofile-container .nav-tabs {
    overflow: scroll clip;
  }
}

.about-container {
}
.about-container .title-header h2 {
  display: block;
  font-weight: bold;
  color: #2D6773;
  font-size: 25px;
  padding-top: 1em;
  margin-bottom: 1em;
  text-align: center;
}

.about-container .header {
  color: #14B4C8;
  font-weight: bolder;
  font-size: 18px;
  text-align: left;
}

.about-container .our-story .middle-text{
  margin: 10px 20% 20px;
}

.about-container .learn-share-connect {
  display: flex;
}

.about-container .learn-share-connect-text {
  color: #646464;
}


.about-container .our-story {
  background-image: url('../../resources/img/about_page/background1.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 95% auto;
}

.about-container .how-can-we-help {
  background-image: url('../../resources/img/about_page/background2.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 95% auto;
}

.about-container .our-vision-mission {
  background-color: #E6F8FB;
  padding: 10px 20%;
  margin-top: 20px;
}

.about-container .how-can-we-help .header {
  color: #FA6400;
}

.about-container .how-can-we-help .middle-text {
  margin: 10px 20% 30px;
}

.about-container .how-can-we-help .quote {
  color: #14B4C8;
  text-align: center;
  margin: 10px 10%;
}

.about-container .how-can-we-help h1{
  font-size: 23px;
}

.about-container .how-can-we-help h3{
  font-size: 16px !important;
}


.about-container>p {
  font-size: 16px;
}

.about-container .group-image img {
  width: 60%;
}

.about-container .group-image-div {
  background-image: url('../../resources/img/about_page/Group 96.svg');
  background-position: center;
  background-repeat: no-repeat;
  text-align: center;
  background-size: contain;
}

.about-container .group-image-div-video {
  background-image: url('../../resources/img/about_page/video-background.svg');
  background-position: center;
  background-repeat: no-repeat;
  text-align: center;
  background-size: cover;

}

.about-container iframe {
  width: 100%;
  height: 100%;
}

.about-container .video {
  aspect-ratio: 3 / 1;
}

.about-container .find-out-more {
  margin: 40px 20% 40px;
}

.about-container .logos {
  display: flex;
  justify-content: space-around;
  padding: 20px;
  gap: 20px;
}

.about-container .logos img {
  height: auto;
  min-width: 30%;
}

@media (max-width: 768px) {
  .about-container .our-story .middle-text{
    margin: 10px 10% 20px;
  }

  .about-container .our-story {
    background-image: none;
  }

  .about-container .group-image img {
    width: 80%;
  }

  .about-container .group-image-div {
    background-size: auto;
  }

  .about-container .how-can-we-help {
    background-image: none;
  }

  .about-container .our-vision-mission {
    padding: 10px 10%;
  }

  .about-container .how-can-we-help .middle-text {
    margin: 10px 10% 30px;
  }

  .about-container .how-can-we-help .quote {
    margin: 10px 5%;
  }

  .about-container .group-image-div-video {
    background-size: auto;
  }

  .about-container .find-out-more {
    margin: 20px 10% 20px;
  }

  .about-container .video {
    aspect-ratio: 2.25 / 1;
  }

}




.module-header-container .banner-image {
  display: inline-block;
  max-width: 100%;
  height: auto;
}

.module-header-container .banner-image-container {
  text-align: center;
  height: 250px;
  width: 100%;
  overflow: hidden;
  background-color: #dadada;
  background-position: top center;
  background-size: cover;
}

.module-header-container .module-logo {
  height: 150px;
  width: 150px;
  border-radius: 50%;
  box-shadow: #ababab 0px 0px 20px;
}

.module-header-container .header-container {
  text-align: center;
  background-color: #f5f5f5;
  padding: 30px 0px;
  margin-bottom: 50px;
}

.module-header-container  button {
  background-color: #e59552;
  background-image: none;
  color: #ffffff;
  text-transform: uppercase;
  border-color: #e59552;
  width: 60%;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  text-shadow: none;
}

.module-header-container .warning {
  background-color: red;
  border-color: red;
}

.session-feedback-container button {
  width: 150px;
  border: 1px solid #ccc;
  color: white;
  background: var(--color-background-darkest-role);
  text-shadow: none;
  border-radius: 25px;
}

.heading-container {
  width: 100%;
  padding: 2.5%;
  display: inline-block;
  text-align: center;
  margin-top: -20px;
  margin-bottom: 10px;
}

.session-feedback-container {
  width: 100%;
  padding: 5%;
  display: inline-block;
  margin-top: -20px;
  margin-bottom: 10px;
  color: #2D6773;
}

.title {
  font-weight: bold;
  font-size: 28px;
  padding-bottom: 5px;
  color: var(--color-background-darkest-role);
}

.session-feedback-container .form-input {
  background: #FFF8E2;
  border-radius: 18.5px;
}

.session-description {
  font-size: 15pt;
}

.form-inline {
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
  margin-bottom: 25px;
}

.session-feedback-container label.control-label {
  color: #2D6773;
  font-weight: bold;
}

p.category-title {
  color: #2d6773;
  font-weight: bold;
  margin-bottom: -2px;
}

.session-feedback-container .row p {
  color: #2D6773!important;
}

.form-inline > label.control-label {
  margin-top: auto;
  margin-bottom: auto;

}

.radio-group-container{
  width: 65%;
  display: inline-flex;
  justify-content: flex-start;
  padding-left: 5%;
}

.session-feedback-container .form-input.inline.form-control{
  width: 65%;
}

.session-feedback-container .form-inline .attendees .form-input.inline.form-control{
  color: #2D6773;
}

.session-feedback-container .checkbox label {
  color: #2D6773;
  margin: 0px 55px 0px 0px;
  font-size: 16px;
}
.session-feedback-container .user-rows {
  margin-left: 5px;
}
.session-feedback-container .radio, .checkbox {
  margin-bottom: 0px;
}

.session-feedback-container .checkbox input[type="checkbox"], .checkbox-inline input[type="checkbox"] {
  transform: scale(1.5);
  filter: invert(70%) hue-rotate(358deg) brightness(1.7);
  margin-right: 8px;
}


@media (max-width: 767px){
  .session-feedback-container > .radio + .radio, .checkbox + .checkbox {
    margin-top: 10px;
  }
}




.results-container {
  /* min-width: 600px; */
  display: flex;
  flex-direction: column;
  background-color: var(--color-background-role);
}

/* .results-container .content-container {
  border-bottom: 3px #f5f5f5  solid;
} */

.results-container .heading-container {
  padding: 0% 2.5%;
  padding-top: 55px;
}

.results-container .filters-container {
  padding: 0% 2.5%;
  padding-bottom: 15px;
  margin-bottom: -20px;
}

/* .results-container .filters-row {
  border-bottom: 3px #f5f5f5  solid;
  margin: 0px;

} */

/* .results-container .header-div {
  border-bottom: 3px #f5f5f5  solid;
} */

.results-container .text-container {
  display: inline-block;
  width: 85%;
}

.results-container .filter-toggle-container {
  display: inline-block;
  width: 15%;
  text-align: right;
}

.results-container .filter-text {
  display: inline-block;
  margin-left: 5px;
  font-size: 20px;
  line-height: 1.1;
}

.results-container .checkbox label {
  color: #646464;
  font-weight: bold;
  font-size: 16px;
  margin: 0px 0px 4px 10px;
}

.results-container .hid {
  height: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  overflow: hidden;
}

.results-container .expanded {
  height: auto;
  padding-bottom: 25px;
  overflow: hidden;
}

.results-container .category-title {
  margin-top: 0px;
}

.results-container .checkbox {
  margin-top: 0px;
}

.results-container .fa-plus, .results-container .fa-minus {
  font-size: 20px;
}

.results-container .fa-plus:hover {
  cursor: pointer;
  color: #10b5c6;

}

.results-container .fa-minus:hover {
  cursor: pointer;
  color: #10b5c6;
}

.expand-results {
  margin-left: 2.6%;
  margin-top: 1.5em;
  width: fit-content;

  color: var(--colour-dark-side);
  text-transform: uppercase;
  font-weight: 700;

}

.expand-results:hover {
  cursor: pointer;
  color: rgb(83, 83, 83);
}

.display-results-container {
  display: flex;
  flex-direction: column;
}

.results-container .section-container {
  padding: 0% 2.5% 0% 2.5%;
}

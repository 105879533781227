.manage-featured-container .custom-heading {
  display: inline-block;
  padding-right: 10px;
}

.manage-featured-container .fa {
  font-size: 20px;
}

.manage-featured-container .fa:hover {
  cursor: pointer;
  color: #10b5c6;
}

.featured-module-modal .heading-container {
  text-align: center;
}

.featured-module-modal {
  padding: 5%;
}

.featured-module-modal h4 {
  margin-bottom: 25px;
}

.featured-module-modal .button-container {
  margin-top: 25px;
}

.featured-module-modal .heading-container, .button-container {
  text-align: center;
}

.featured-module-modal .add-button {
  background-color: #e59552;
  background-image: none;
  color: #ffffff;
  text-transform: uppercase;
  border-color: #e59552;
  padding: 5px 50px;
  text-shadow: none;
}

.featured-module-modal .typeahead-container .form-control {
  height: auto;
}

.featured-module-modal .typeahead-container .rbt-input-wrapper {
  width: auto;
}

.featured-module-modal .typeahead-container .rbt-token {
  background-color: #e7f4ff;
  border: 0;
  border-radius: 2px;
  color: #1f8dd6;
  display: inline-block;
  line-height: 1em;
  margin: 0 3px 3px 0;
  padding: 4px 7px;
  position: relative;
}

.featured-module-modal .typeahead-container .rbt-token .rbt-token-remove-button {
  bottom: 0;
  color: inherit;
  font-size: inherit;
  opacity: 1;
  right: 6px;
  position: absolute;
  text-shadow: none;
  top: -2px;
}

.featured-module-modal .typeahead-container  .rbt-token-removeable {
  cursor: pointer;
  padding-right: 21px;
}

.block-title {
  font-size: 18px;
  font-weight: bold;
  margin: 0px 20px;
}

.block-text {
  font-size: 16px;
  margin: 5px 20px;
}

.block {
  aspect-ratio: 1.44 / 1;
  max-width: 305px;
  color: white;
  text-align: center;
  padding-top: 50px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.block-blue {
  background-image: url('../../resources/img/support_page/BlockBlue.svg');
}

.block-yellow {
  background-image: url('../../resources/img/support_page/BlockYellow.svg');
}

.block-orange {
  background-image: url('../../resources/img/support_page/BlockOrange.svg');
}

.block-pink {
  background-image: url('../../resources/img/support_page/BlockPink.svg');
}

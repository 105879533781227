.user-modal-container {
  text-align: center;
}

.user-modal-container .avatar-container {
  padding: 25px 0px;
}

.user-modal-container .avatar-image {
  height: 175px;
  width: 175px;
  border-radius: 50%;
  border: 1px solid black;
  background-position: center center;
  background-size: cover;
  margin: auto;
}

.user-modal-container button {
  background-color: var(--colour-accent-1);
  background-image: none;
  color: #ffffff;
  text-transform: uppercase;
  border-color: #e59552;
  margin: 10px;
  text-shadow: none;
  border-radius: 15px;
}

.user-modal-container .user-info-container {
  padding-bottom: 25px;
}

.user-modal-container .send-message-content {
  width: 100%;
  text-align: center;
  padding: 10px;
}

.user-modal-container .form {
  display: inline-block;
}

.user-modal-container .form-text-area {
  width: 540px;
  min-height: 100px;
  color: var(--colour-accent-1);
}

.invite-modal-form-container {
  text-align: center;
}

.invite-modal-form-container .button-container {
  margin: 20px 0px;
}

.invite-modal-form-container .heading-container {
  margin: 25px 0px;
}

.invite-modal-form-container .hub-tile-container {
  width: 92px;
  height: 150px;
}

.invite-modal-form-container .hub-name-container {
  width: 92px;
  font-size: 12px;
}

.invite-modal-form-container .banner-image {
  height: 92px;
  width: 92px;
}

.invite-modal-form-container .banner-image-container {
  height: 92px;
  width: 92px;
}

.invite-modal-form-container  .modal-button {
  background-color: #e59552;
  background-image: none;
  color: #ffffff;
  text-transform: uppercase;
  border-color: #e59552;
  width: 30%;
  text-shadow: none;
}

.invite-feedback-container {
  text-align: center;
  margin: 50px 0px;
}

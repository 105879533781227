.notification-tab-container .title {
    font-size: 1.5em;
    font-weight: bold;
    padding: 35px;
    padding-top: 30px;
    padding-bottom: 30px;
    display: block;
    text-align: left;
    border-bottom: 3px solid #EFEFEF;
    color: #646464;
}

.notification-tab-container .section-title {
    display: block;
    font-weight: bold;
    color: #646464;
    padding: 35px;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #FAF9F9;
    font-size: 1.25em;
}

.notification-tab-container .no-notifications {
    display: block;
    color: #646464;
    padding: 35px;
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: 1.125em;
}

.messages .message {
    display: flex;
    padding: 35px;
    padding-top: 15px;
    padding-bottom: 15px;
    gap: 1.5em;
    align-items: center;
}

.messages .message .message-picture {
    height: 69px;
    width: 69px;
    min-height: 69px;
    min-width: 69px;
    border-radius: 50%;
    background-position: center center;
    background-size: cover;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    border: 1px solid rgba(0, 0, 0, 0.25);
}

.messages .message .message-content {
    width: 65%;
    color: #646464;
    overflow-wrap: break-word;
}

.messages .message .message-date {
    color: #2D6773;
    width: 10%;
}

.messages .message .message-button {
    background-color: var(--color-background-darkest-role);
    border-radius: 25px;
    font-weight: bold;
    color: white;
    padding: 1em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    border: 0;
}

.invites .invites-container {
    display: flex;
    flex-wrap: wrap;
    padding: 25px;
    padding-top: 10px;
    padding-bottom: 10px;
    gap: 1.5em;
    align-items: center;
}

.invites .invites-container .invite {
    border: 1px solid #E7E7E7;
    border-radius: 39.5px;
    padding: 1em;
}

.invites .invites-container .invite-inner {
    display: flex;
    align-items: center;
    gap: 1.5em;
}

.invites .invites-container .invite .hub-picture {
    height: 45px;
    width: 45px;
    border-radius: 50%;
    background-position: center center;
    background-size: cover;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    border: 1px solid rgba(0, 0, 0, 0.25);
}

.invites .invites-container .invite .hub-name {
    max-width: 200px;
}

.invites .invites-container .invite .hub-button {
    background-color: var(--color-background-darkest-role);
    border-radius: 25px;
    font-weight: bold;
    color: white;
    padding: 1em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    border: 0;
}

.invites .invites-container .invite .hub-accept-decline {
    display: flex;
    gap: 0.5em;
    justify-content: center;
    padding-top: 0.5em;
}

.invites .invites-container .invite .hub-accept-decline button {
    padding: 0.75;
    padding-top: 0.3em;
    padding-bottom: 0.3em;
    font-weight: normal;
}

.invites .invites-container .invite .decline {
    background-color: #FA6400;
}

.notification-info-icon{
  height: 23px;
  width: 23px;
  margin-left: 15px;
}


.notification-info-box{
  position: absolute;
  z-index: 99999;
  display: block;
  margin-left: 38px;
  margin-top: -35px;
}

.notification-info-icon:hover{
  opacity: 0.5;
  cursor: pointer;
}

@media (max-width: 1350px) {
    .messages .message .message-content {
        width: 60%;
    }
    .messages .message .message-date {
       font-size: 13px;
    }
}

@media (max-width: 350px) {
    .messages .message .message-content {
        width: 40%;
    }
}


@media (max-width: 590){
  .notification-info-icon{
    height: 23px;
    width: 23px;
    margin-left: 15px;
  }

  .notification-info-icon:hover{
    opacity: 0.5;
    cursor: pointer;
  }
}

.edit-permissions-container {
  text-align: center;
}

.edit-permissions-container button {
  text-transform: uppercase;
}

.edit-permissions-container .success {
  color: #4BB543;
}

.edit-permissions-container .radio label {
  text-transform: capitalize;
}

.edit-permissions-container .heading-container {
  margin-bottom: 10px;
}

.edit-permissions-container .content-container {
  margin: 25px 0px;
  text-align: left;
  margin-left: 45%;
}

.edit-permissions-container .button-container {
  margin: 25px 0px;
}


.theme-btn {
  border-radius: 500px;

  font-size: 1.25em;
  font-weight: lighter;
  outline: none !important;
  background-image: none;
  background-color: white !important;
  padding: 10px 30px 10px 30px;
  margin: 0.3em;
  border: 1px solid transparent;
}

.theme-btn:hover {
  border: 1px solid var(--colour-accent-1);
  color: var(--colour-accent-1);
}

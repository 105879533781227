.react-multi-carousel-list.carousel-container {
    overflow-x: clip;
    overflow-y: visible;
}

.remove-padding {
  padding: 0;
}

.remove-padding .section-container {
  padding: 0;
}

.carousel-title .info-icon {
  margin: 0px;
  margin-bottom: 10px;
}

.info-box {
  position: absolute;
  z-index: 99999;
  display: block;
  margin-left: 85px;
  margin-top: -40px;
}

.carousel-title .info-icon:hover{
  opacity: 0.5;
  cursor: pointer;
}

.carousel-title .info-container span {
  top: 70px;
}

@media screen and (max-width: 590px) {
  .full-width-mobile-view {
    width: 100%;
  }
}

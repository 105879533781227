.info-container{
  position: relative;
  /* width: 335px; */
  height: auto;
}

.info-container .info-box-image{
  width: 400px;
}

.info-container h1 {
  position: absolute;
  float: left;
  top: 8px;
  left: 12px;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  margin-right: 12px;
}

.info-container span {
  position: absolute;
  top: 90px;
  left: 12px;
  text-align: center;
  margin-right: 12px;
}

.rating-controls {
  max-width: 50px;
  margin-bottom: -10px;
}

.rating-controls .fa {
  font-size: 24px;
  padding-right: 8px;
}

.rating-controls .thumbs-col {
  text-align: center;
  padding-left: 0;
  width: 100%
}

.rating-controls .thumbs-col:hover {
  color: black !important;
  -webkit-transition: 1s; /* Safari */
  transition: 0.5s;
}

.rating-controls .icon {
  display: inline-block;
}

.rating-controls .count {
  display: inline-block;
  position: relative;
  top: -2px;
}

.rating-controls .down-col {
  margin-left: -15px;
}

.rating-controls .clickable:hover {
  cursor: pointer;
}

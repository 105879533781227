.users-stats-container {
  padding: 0% 2.5%
}

.users-stats-container .user-header-stat {
  text-align: left;
  cursor: pointer;
  padding-bottom: 30px;
  display:flex;
  height: 180px;
}

.users-stats-container .user-name {
  display: inline-block;
  align-self: center;
  font-size: 1.3em;
}
.users-stats-container .avatar-container {
  display: inline-block;
  padding-right: 25px;
  padding-top: 25px;
}

.users-stats-container .quiz-container {
  padding-top: 25px;
}

.users-stats-container .no-results {
  width: 100%;
  text-align: center;
  font-weight: bold;
  padding-top: 25px;
}

.users-stats-container table {
  width: 100%;
  margin-top: -80px;
}

.users-stats-container a {
  cursor: pointer;
}
.users-stats-container .avatar-image {
  height: 75px;
  width: 75px;
  border-radius: 50%;
}

.users-stats-container th {
  padding: 25px 10px;
  text-align: left;
}

.users-stats-container tr {
  border-bottom: solid 2px #eeeeee;
}

.users-stats-container td {
  padding: 10px;
}

.users-stats-container .module-image-container {
  height: 70px;
  width: 100%;
  max-width: 145px;
  overflow: hidden;
}

.users-stats-container .module-image {
  height: auto;
  width: 100%;
  min-width: 120px;
}

.users-stats-container td:last-child {
  text-align: left;
}

.users-stats-container table button {
  height: 40px;
  text-align: right;
}

.users-stats-container .back-button-container {
  margin-left: auto;
  align-self: center;
}

.users-stats-container button {
  background-color: var(--colour-accent-1);
  background-image: none;
  color: #ffffff;
  text-transform: uppercase;
  border-color: #e59552;
  text-shadow: none;
  font-size: 14px;
  font-weight: normal;
  border: 1px solid transparent;
  border-radius: 4px;
}

@media (max-width: 600px) {
  .users-stats-container{
    overflow-x: scroll;
  }
}
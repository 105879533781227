.blue-button {
  background-color: #14B4C8;
  font-family: var(--font-main);
  background-image: none;
  color: white;
  text-transform: uppercase;
  text-shadow: none;
  padding: 10px 20px 10px 20px;
  border-radius: 20px;
    float: right;
    font-weight: bold;
  
  }

  /* Ensure the button retains the same color on hover */
.blue-button:hover {
    background-color: #14B4C8;
    color: white;
  }
  
  /* Retain color on focus */
  .blue-button:focus {
    background-color: #14B4C8;
    color: white;
    outline: none; /* Optional: To remove the focus outline */
  }
  
  /* Retain color on active (when clicked) */
  .blue-button:active {
    background-color: #14B4C8;
    color: white;
    outline: none;
  }
  .blue-button-center {
    background-color: #14B4C8;
  font-family: var(--font-main);
  background-image: none;
  color: white;
  text-transform: uppercase;
  text-shadow: none;
  padding: 10px 20px 10px 20px;
  border-radius: 20px;
    display: block;
    margin: 0 auto;
  margin-bottom: 2%;
  font-weight: bold;
  }

  /* Ensure the button retains the same color on hover */
.blue-button-center:hover {
    background-color: #14B4C8;
    color: white;
  }
  
  /* Retain color on focus */
  .blue-button-center:focus {
    background-color: #14B4C8;
    color: white;
    outline: none; /* Optional: To remove the focus outline */
  }
  
  /* Retain color on active (when clicked) */
  .blue-button-center:active {
    background-color: #14B4C8;
    color: white;
    outline: none;
  }

.content-label{
  font-size: 25px;
  font-weight: bold;
  color: #646464;
  font-style: "Roboto";
  display: block;
  flex: 0.6;
  margin-top: 80px;
}

.delete-button{
  color: red;
  border: none;
  background-color: white;
  font-weight: bold;
  float: right;
}

.module-creator-display{
    display: flex;
    align-items: center;
    gap: 400px; /* Adjust the gap as needed */
}

/* Style the select dropdown */
.dropdown select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  
  background-color: #fff7e0; /* Light cream background */
  border: 1px solid #e8b954; /* Border color */
  border-radius: 25px; /* Rounded edges */
  padding: 7px 40px 7px 15px; /* Add padding for content and arrow */
  color: #333; /* Text color */
  cursor: pointer;
  
  
  /* Positioning for text */
  display: inline-block;
  width: 400px;
}

/* Center the dropdown */
.dropdown {
  position: relative;
  display: flex;
  justify-content: center; /* Horizontally center */
  align-items: center; /* Vertically center */
}

/* Position the arrow inside the yellow box */
.module-selection .dropdown::after {
  content: '▼';
  font-size: 11px;
  color: #53c1c8; /* Arrow color */
  position: absolute;
  right: 39%; /* Adjust this value to stay within the box */
  top: 20px;
  transform: translateY(-50%);
  pointer-events: none; /* Prevent arrow from being clicked */
}

.module-selection .dropdown .disabled{
  text-decoration: line-through;
  color: gray;
  cursor: not-allowed;
}

/* Style the select dropdown */
.dropdown-group select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  
  background-color: #fff7e0; /* Light cream background */
  border: 1px solid #e8b954; /* Border color */
  border-radius: 25px; /* Rounded edges */
  padding: 7px 40px 7px 15px; /* Add padding for content and arrow */
  color: #333; /* Text color */
  cursor: pointer;
  
  
  /* Positioning for text */
  display: block;
  flex: 1;
  width: 100%;
  
}

/* Center the dropdown */
.dropdown-group  {
  position: relative;
  display: block;
  flex: 1;
  justify-content: flex-start; /* Horizontally center */
  padding-left: 20%;
  align-items: center; /* Vertically center */
}

/* Position the arrow inside the yellow box */

.deadline-selection{
  position: relative;
  display: flex ;
  justify-content: flex-start;
  align-items: center;  /* Vertically center */


}



select:focus {
  outline: none; /* Remove focus outline */
}


.arrow-button{
  background-color: white;
  color:#14B4C8;
  border: none;
  font-size: 10px;
  bottom: 4px;
  position: relative;
}

.create-module-tab-container 
.label-1 {
  display: flex;
  justify-content: flex-end;
  flex:1;
  font-size: 17px;
}

.create-module-tab-container  .fa-calendar {
  color: #10b5c6;
}
.create-module-tab-container .react-date-picker__button{
  width: 100%;
  border-radius: 36.5px;
  padding: 0px 12px;
  background-color: #FFF8E2;
  border: 1px solid #e8b954;
  color: #555555;
  justify-content: flex-start; /* Horizontally center */
  align-items: center;
}
.create-module-tab-container .react-date-picker {
  width: 100%;
}
.create-module-tab-container .group-div .form-group {
  display: flex;
  padding-left: 20%;
  flex: 1;  /* Ensure the form group takes the remaining space */
  width: 100%; /* Form group fills the remaining width */
}


.section-label{
  color: #14B4C8;
  font-size: 25px;
  font-weight: bold;
}

.create-module-tab-container .group-div{
  position: relative;
  display: flex ;
  justify-content: flex-start;
  align-items: center;  /* Vertically center */
  
}

/* css  from module.css */
label {
  font-weight: 500;
}

.create-module-tab-container .padding {
  padding: 0 2.5%;
}

.img1 {
  display: none;
}

.img1[src] {
  display: block;
}

.form-button-container-featureImage .img1 {
  width: 300px;
  height: 150px;
  float: right;
}

.create-module-tab-container .multi-select-container {
  display: block;
  text-align: center;
  float: right;
  margin-top: 10px;
  text-align: right;
}

.create-module-tab-container .multi-button-select {
  background-color: #FAC832;
  background-image: none;
  color: #ffffff;
  text-transform: uppercase;
  text-shadow: none;
  font-size: 14px;
  font-weight: normal;
  border: 1px solid transparent;
  border-radius: 20px;
  padding: 5px 15px 5px 15px;
  margin: 5px 0px 10px 0px;
  width: 80px;
}

.create-module-tab-container .selection-text {
  display: inline-block;
  color: white;
}

.create-module-tab-container .inline {
  display: block;
  float: right;
  padding-right: 20px;
}

.create-module-tab-container .selection-icon {
  display: inline-block;
  padding-left: 5px;
}

.create-module-tab-container .selection-container {
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 15px;
  margin: 5px;
  background-color: #FAC832;
  padding: 5px;
}

.create-module-tab-container .chosen-selection-container {
  padding: 10px;
}


.create-module-tab-container .chosen-goals-container {
  display: block;
  float: right;
  margin-top: 1.5em;
}

.create-module-tab-container .targetGroup-select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  display: inline-block;
  width: 70%;
  flex: 1;
  background: #FFF8E2;
  border-color: #FAC832;
  border-radius: 15px;
}

.create-module-tab-container .activity-select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  display: inline-block;
  width: 400px;
  background: #FFF8E2;
  border-color: #FAC832;
  border-radius: 15px;
}



.create-module-tab-container .group-div .control-label {
  font-size: 25px;
  font-weight: bold;
  color: #646464;
  font-style: "Roboto";
  display: block;
  flex: 0.7;
}

.create-module-tab-container .sidebar-col .padding {
  padding: 2.5% 5% 0 0;
}

.create-module-tab-container .form-group {
  display: flex;
  margin-top: 40px;
}

.create-module-tab-container .control-label {
  font-size: 25px;
  font-weight: bold;
  color: #646464;
  font-style: "Roboto";
  display: block;
  flex: 0.6;
}

.create-module-tab-container .sidebar-col .control-label {
  width: 100%;
}

.create-module-tab-container .form-input {
  display: inline-block;
  /* width: 80%; */
  background: #FFF8E2;
  border-radius: 18.5px;
}
.create-module-tab-container .form-input-small {
  /* width: 80%; */
  width: 50%;
  background: #FFF8E2;
  border-radius: 18.5px;
  float: right;
  text-align: center;
  margin-right: 20%;
  font-weight: bold;
  font-size: medium;
}

.create-module-tab-container .form-control {
  display: inline-block;
  /* width: 50%; */
  flex: 1;
  border-color: #FAC832;
}

.create-module-tab-container .text-area {
  width: 100%;
}

.create-module-tab-container .form-text-area {
  display: block;
  width: 100%;
}

.create-module-tab-container .form-upload {
  display: inline-block;
  background: #FFF8E2;
  border-radius: 18.5px;
}

.create-module-tab-container .form-button-container-featureImage {
  display: inline-block;
  text-align: right;
  width: 25%;
  position: relative;
  margin-right: 5px;
}

.create-module-tab-container .attach-button-featureImage {
  width: 75%;
  /* position: absolute; */
  bottom: 0;
  right: 0;
  margin-top: 10px;
}

.create-module-tab-container .form-button-container {
  display: inline-block;
  text-align: right;
  width: 25%;
}

.create-module-tab-container .attach-button {
  width: 75%;
}

.create-module-tab-container .form-heading {
  margin: 20px 0px 40px 0px;
  font-weight: bold;
  color: #14B4C8;
}

.create-module-tab-container .container-sidebar {
  padding: 20px;
  margin-bottom: 20px;
}

.create-module-tab-container .button {
  background-color: #FEE391;
  font-family: var(--font-main);
  background-image: none;
  color: #CAA32D;
  font-weight: bold;
  text-transform: uppercase;
  text-shadow: none;
  padding: 10px;
  border-radius: 20px;
}

.create-module-tab-container .publish-button-container {
  text-align: right;
  margin-top: 40px;
  justify-content: center;
  display: flex;
  padding-bottom: 30px;
}

.create-module-tab-container .publish-button {
  background-color: #14B4C8;
  font-family: var(--font-main);
  background-image: none;
  color: white;
  text-transform: uppercase;
  text-shadow: none;
  padding: 10px 20px 10px 20px;
  border-radius: 20px;
}

.create-module-tab-container .back-button {
  background-color: #14B4C8;
  font-family: var(--font-main);
  background-image: none;
  color: white;
  text-transform: uppercase;
  text-shadow: none;
  padding: 10px 20px 10px 20px;
  border-radius: 20px;
}

.create-module-tab-container .attach-button:disabled {
  opacity: 1;
}



.create-module-tab-container .count-text {
  display: inline-block;
  margin-right: 5px;
  font-size: 26px;
}

.create-module-tab-container .upload-counter-container {
  padding: 20px;
}

.create-module-tab-container .fa-times {
  color: red;
  position: absolute;
  top: -15px;
  left: 65%;
  z-index: 5;
}

.create-module-tab-container .fa-5x {
  color: #FAC832;
}

.create-module-tab-container .pdf-col {
  text-align: center;
}

.create-module-tab-container .clickable:hover {
  cursor: pointer;
}

.create-module-tab-container .asset-title {
  padding: 10px;
  word-wrap: break-word;
}

.create-module-tab-container .resource-heading {
  margin-top: 40px;
  margin-bottom: 40px;
}

.container-sidebar .hid {
  height: 0px;
  transition: height 0.5s ease, padding-bottom 0.5s ease, overflow 0.5s ease;
  padding-top: 0px;
  padding-bottom: 0px;
  overflow: hidden;
}

.container-sidebar .expanded {
  height: auto;
  transition: height 0.5s ease, padding-bottom 0.5s ease, overflow 0.5s ease;
  overflow: hidden;
}

.container-sidebar .filter-text {
  display: inline-block;
  margin-left: 5px;
  font-size: 18px;
  line-height: 1.1;
  font-weight: 500;
}

.container-sidebar .sub-category-heading {
  margin: 15px 0px;
  display: inline-block;
  margin-left: 5px;
  font-size: 16px;
  line-height: 1.1;
}

.category-container .hid {
  height: 0px;
  transition: height 0.5s ease, padding-bottom 0.5s ease, overflow 0.5s ease;
  padding-top: 0px;
  padding-bottom: 0px;
  overflow: hidden;
}

.category-container .expanded {
  height: auto;
  transition: height 0.5s ease, padding-bottom 0.5s ease, overflow 0.5s ease;
  overflow: hidden;
}

.container-sidebar .icon-container {
  display: inline-block;
  text-align: center;
  width: 20%;
}

.container-sidebar .filter-text-container {
  display: inline-block;
  width: 80%;
}

.container-sidebar .fa-plus {
  position: relative;
  font-size: 18px;
  top: 3px;
}

.container-sidebar .fa-plus:hover {
  position: relative;
  cursor: pointer;
  color: #10b5c6;
}

.container-sidebar .fa-minus {
  position: relative;
  font-size: 18px;
  top: 3px;
}

.container-sidebar .fa-minus:hover {
  position: relative;
  cursor: pointer;
  color: #10b5c6;
}

.container-sidebar .change-featured-image-button-container {
  display: inline-block;
  text-align: right;
  width: 40%;
}

.container-sidebar .featured-title {
  display: inline-block;
  width: 60% !important;
}

.not-approved {
  padding: 2.5%;
  font-size: 12pt;
  text-align: center;
  width: 100%;
}

.create-module-tab-container .DraftEditor-editorContainer {
  padding: 6px 12px;
  /* height: 500px; */
}

.create-module-tab-container .public-DraftEditor-content {
  height: 420px;
  font-family: sans-serif;
}

.radio-sub-text {
  color: grey;
  margin: 10px;
  font-size: small;
  padding-left: 5px;
}

.publish-feedback {
  margin: 1em 0px;
  font-family: var(--font-main);
  color: var(--colour-accent-1);
}

.comments {
  display: flex;
}

.public-DraftStyleDefault-ltr {
  direction: initial;
  text-align: initial;
}

.rich-editor {
  height: 500px;
}

.gif {
  height: 500px;
  width: 900px;
}

.gif-container {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}


.create-module-tab-container .targets {
  display: block;
  flex: 0.30;
  margin: 10px;
  border-radius: 15px;
  background-color: #FFF8E2;
  color: #646464;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 0px 5px 0px;
}

.create-module-tab-container .targets-selected {
  display: block;
  flex: 0.30;
  margin: 10px;
  border-radius: 15px;
  border-style: solid;
  border-color: #FAC832 !important;
  background-color: #FFF8E2;
  color: #646464;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 0px 5px 0px;
}

.create-module-tab-container .visibilty {
  /* display: block; */
  flex: 0.30;
  margin: 10px;
  border-radius: 20px;
  background-color: #FFF8E2;
  color: #646464;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 25px 5px 10px;
  height: 3em;
}

.create-module-tab-container .visibility-selected {
  /* display: block; */
  flex: 0.30;
  margin: 10px;
  border-radius: 20px;
  border-style: solid;
  border-color: #FAC832 !important;
  background-color: #FFF8E2;
  color: #646464;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 5px 5px 10px;
  height: 3em;
}



.create-module-tab-container .chat-members-list{
  height: auto;
  max-height: 200px;
}



.blur {
  position: fixed;
  padding: 0;
  margin: 0;

  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.7);
}

.create-module-tab-container .visibility-options {
  display: flex;
  flex: 1;
}

@media (max-width: 600px) {
  .create-module-tab-container .targets {
    display: block;
    flex: 0.60;
    margin: 10px;
    border-radius: 15px;
    background-color: #FFF8E2;
    color: #646464;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 0px 5px 0px;
  }

  .create-module-tab-container .visibilty {
    display: block;
    flex: 0.30;
    margin: 10px;
    margin-top: -5px;
    border-radius: 20px;
    background-color: #FFF8E2;
    color: #646464;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 1px 5px 1px;
  }



  .create-module-tab-container .pdf-form{
    display: block;
    flex-direction: column;
  }

  .create-module-tab-container .video-form{
    display: block;
    flex-direction: column;
  }

  .create-module-tab-container .upload-counter-container {
    display: flex;
    flex-direction: row;
    width: 200px;
    height: 100px;
  }
}

.create-module-tab-container .help-block{
color: #a94442;
}

@media (max-width: 425px) {
  .create-module-tab-container .form-group{
    display: block;
  }

  @media (max-width: 425px) {
    .create-module-tab-container .group-div{
      display: block;
    }
  }

  @media (max-width: 425px){
  .create-module-tab-container 
.label-1 {
justify-content: flex-start;
}
  }

  .create-module-tab-container .visibility-options {
    display: block;
  }

  .form-button-container-featureImage .img1 {
    float: none;
  }
}

.settings-container {
  margin: 2% 0;
  padding: 0 1.8em;
}

.settings-container .button-row {
  text-align: center;
}

.settings-container .social {
  position: relative;
  left: 10px;
  top: 2px;
}

.settings-container .social-link-container {
  display: flex;
  width: 100%;
  margin-bottom: 15px;
}

.settings-container .social-fb {
  color: rgba(82, 82, 82, 0);
}

.settings-container .block-header {
  display: block;
}

.settings-container .control-label{
  font-size: 25px;
  font-weight: bold;
  color: #646464;
  /* font-style: "Roboto"; */
  display:block;
  flex:1;
  margin-top:10px;
}

.settings-container .form-control{
  width: 80%;
  flex:1;
  border-color: #FFF8E2;
  background-color: #FFF8E2;
  border-radius: 20px;
  color: #94CBD6;
}

.settings-container .form-control:focus{
  border: 2px solid #FAC832;
}

.settings-container .form-input-social {
  display: inline-block;
  width: 80%;
  margin-left: 35px;
  margin-top: 15px;
  background-color: #D9D9D9;
  border-radius: 0px;
  border-color: #D9D9D9;
}

.settings-container .form-input-social:focus {
  border-color: rgba(82, 82, 82, 0.445);
}

.settings-container .si-container {
  display: inline-block;
  width: 5%;
}

.settings-container .button-container {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: left;
  margin-left: 100px;
}

.settings-container .browse-button{
  width: 100px;
  border-radius: 20px;
}

.settings-container .form-text-area {
  margin: 12px 10px 0px 0px;
  border-radius: 5px;
  width: 80%;
  border-color: #FFF8E2;
  border-radius: 20px;
  background-color: #FFF8E2;
}

.settings-container .form-input-upload {
  display: inline-block;
  width: 40%;
  border-color: #FFF8E2;
  border-radius: 20px;
  background-color: #fff8e200;
  color: black;
}

.settings-container .form-input-address {
  margin-bottom: 20px;
  border-color: #FFF8E2;
  border-radius: 20px;
  background-color: #FFF8E2;
}

/* .settings-container button {
  background-color: var(--colour-accent-1);
  font-family: var(--font-main);
  background-image: none;
  color: #ffffff;
  text-transform: uppercase;
  text-shadow: none;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
} */

.settings-container .save-settings-button{
  background-color: #14B4C8;
  font-family: var(--font-main);
  background-image: none;
  color: white;
  text-transform: uppercase;
  text-shadow: none;
  padding: 10px;
  border-radius: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.settings-container .save-settings-button:hover{
  background-color: #D9D9D9;
  color: #14B4C8;
}

.settings-container .banner-preview{
  text-align: center;
  float: right;
  width: 500px;
  height: auto;
  margin-left: 500px;
  margin-top: -20px;
  padding-bottom: 100px;
}

.settings-container .logo-preview{
  text-align: center;
  float: right;
  width: 500px;
  height: auto;
  margin-left: 500px;
  margin-top: -20px;
  padding-bottom: 100px;
}

.settings-container .logo-container{
  margin-left: 175px;
}

.settings-container .logo-container img{
  font-size: 0;
  border-radius: 20px;
}

.settings-container .banner-container{
  margin-left: 100px;
}

.settings-container .banner-container img{
  margin-top: -40px;
  font-size: 0;
  border-radius: 20px;
}

.settings-container .image-group{
  padding-top: 50px;
}

.settings-container button {
  background-color: #FAC832;
  color: #ffffff;
}

.settings-container .character-count {
  float: right;
  text-align: right;
  margin-right: 20%;
}

.settings-container .selection-container {
  display: inline-block;
  border: 1px solid #FAC832;
  border-radius: 4px;
  margin-top: 5px;
  background-color: #FAC832;
  padding: 5px;
  border-radius: 20px;
  width: 80%;
}

.settings-container .selection-text {
  display: inline-block;
  background-color: #FAC832;
  border-radius: 20px;
  color:white;
}

.settings-container .selection-icon {
  padding-left: 5px;
  margin-right: 5px;
  float: right;
}

.settings-container .multi-select {
  display: inline-block;
  width: 80%;
}

.settings-container .multi-select-container {
  display: inline-block;
  text-align: center;
  width: 20%;
}

.settings-container .multi-button-select {
  width: 80%;
  border-radius: 20px;
}

.settings-container .block-label {
  display: block;
}

.modulestable td {
  font-family: var(--font-main);
}

.settings-container .feature-group{
  margin-top:100px;
}

.settings-container .targets {
  display: block;
  flex : 0.30;
  border-radius: 15px;
  background-color: #FFF8E2;
  color: #646464;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.settings-container .input[type="checkbox"] {
  transform: scale(1.5);
  filter: invert(70%) hue-rotate(358deg) brightness(1.7);
  margin-top: 7px;
  margin-left: -50px !important;
}

.settings-container .targets-selected {
  flex : 0.30;
  border-radius: 15px;
  border-style: solid;
  border-color: #FAC832 !important;
  background-color: #FFF8E2;
  color: #94CBD6;
  display: flex;
  align-items: center;
  padding: 8px;
  justify-content: center;
}

@media (max-width: 600px) {
  .settings-container .banner-preview{
    width: 500px;
    margin-top: 50px;
  }

  .settings-container .logo-preview{
    width: 500px;
    margin-top: 50px;
  }
}

.error-message{
  color: red;
    
}

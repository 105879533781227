.login-prompt a {
  color: var(--colour-accent-1)!important;
  text-decoration: underline;
}

.comment-section-header {
  color: #646464;
  padding-bottom: 20px;
  padding-left: 31px;
  font-size: 18px;
  text-decoration: underline;
  text-decoration-color: #14B4C8;
  text-decoration-thickness: 5px;
  text-underline-offset: 5px;
}

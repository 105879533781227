.manage-featured-container table{
  width: 100%;
}

.manage-featured-container th {
  padding: 25px 10px;
}

.manage-featured-container .delete-heading {
  text-align: right;
}

.manage-featured-container tr {
  border-bottom: solid 2px #eeeeee;
}

.manage-featured-container td {
  padding: 10px;
}

.manage-featured-container table button {
  height: 40px;
}

.manage-featured-container .add-button-container {
  text-align: right;
  margin-top: 40px;
}

.manage-featured-container .add-button {
  background-color: var(--colour-accent-1);
  background-image: none;
  color: #ffffff;
  text-transform: uppercase;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  text-shadow: none;
}


.manage-featured-container .warning-button-container {
  text-align: right;
}

.manage-featured-container .warning-button {
  background: var(--colour-warning);
  color: white;
  text-shadow: none;
}

.manage-featured-container .limit-text {
  color: #ffbf00;
  text-align: right;
}

.featured-hub-modal {
  padding: 5%;
}

.featured-hub-modal h4 {
  margin-bottom: 25px;
}

.featured-hub-modal h4 {
  margin-bottom: 25px;
}

.featured-hub-modal .heading-container, .button-container {
  text-align: center;
}

.featured-hub-modal .add-button {
  background-color: #e59552;
  background-image: none;
  color: #ffffff;
  text-transform: uppercase;
  border-color: #e59552;
  padding: 5px 50px;
  text-shadow: none;
}

.quiz-builder {
  padding: 0 2.5%;
}

.quiz-builder .title {
  margin-bottom: 30px;
}

.quiz-builder .new-button {
  background-color: #14B4C8;
  background-image: none;
  color: white;
  text-transform: uppercase;
  border-color: #e59552;
  margin-bottom: 40px;
  text-shadow: none;
  border-radius: 15px;
  padding-left: 50px;
  padding-right: 50px;
  font-weight: bold;
  float: center;
  display: block;
  margin: auto;

}

.quiz-builder .question-container {
  padding-bottom: 20px;
}

.quiz-builder .word-container {
  display: inline-block;
  padding: 0px 2px;
}

.quiz-builder .blank-input {
  padding: 0px 2px;
  width: 100px;
}

.quiz-builder .draggable {
  display: inline-block;
  border: 1px solid black;
  padding: 5px;
  margin: 0px 10px;
  margin-bottom: 10px;
  border-radius: 0%;
  min-width: 100px;
  text-align: center;
}

.quiz-builder .instruction-text {
  font-weight: bold;
  margin: 20px 0px;
}

.quiz-builder .question-word-space {
  display: inline-block;
  background-color: white;
  width: 100px;
  height: 20px;
  margin: 0px 2px;
  border: 1px;
  position: relative;
  top: 5px;
}

.quiz-builder .icon-container {
  text-align: right;
  max-width: 600px;
  width: 20%;
  display: inline-block;
}

.quiz-builder .icon-container .fa-times {
  padding-right: 20px;
  position: relative;
  left: 0px;
  top: 0px;
}

.quiz-builder .icon-container .edit-quiz {
  color: #646464;
  text-decoration: underline;
  padding-right: 10px;
  font-size: 16px;
  font-weight: bold;
}

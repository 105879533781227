.user-guide-container {
  text-align: center;
}

.user-guide-container .VideoWrapper {
  display: inline-block;
}

.user-guide-container h1 {
  margin: 30px 0px;
}

.user-guide-container .edit-button {
  background-color: #e59552;
  background-image: none;
  color: #ffffff;
  text-transform: uppercase;
  border-color: #e59552;
  padding: 5px 50px;
  margin-top: 50px;
  text-shadow: none;
}

@media (min-width: 768px) and (max-width: 991px) {
  .user-guide-container .video-wrapper {
    height: 250px;
    width: 500px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .user-guide-container .video-wrapper {
    height: 250px;
    width: 500px;
  }
}

@media (min-width: 1200px) {
  .user-guide-container .video-wrapper {
    height: 300px;
    width: 600px;
  }
}





.settings-tab-container {
  margin-top: 2em;
  margin-bottom: 2em;
}

.settings-tab-container > div {
  padding-left: 2.5%;
}

.settings-tab-container > hr {
  border-width: 3px;
}

.settings-tab-container .title {
  font-weight: bold;
  font-size: 1.5em;
  color: #646464;
  display: block;
  text-align: left;
  margin-bottom: 1em;
}

.settings-tab-container .sub {
  font-size: 1.125em;
  margin-bottom: 1em;
  color: #B4B4B4;
}

.settings-tab-container .settings-security {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.settings-tab-container .settings-security .settings {
  flex: 55%;
}

.settings-tab-container .settings-security .security {
  flex: 45%;
}

.settings-tab-container .settings-security .settings-area {
  display: flex;
  gap: 2.5em;
}

.settings-tab-container .settings-security .settings-area .avatar-image {
  height: 150px;
  width: 150px;
  min-height: 150px;
  min-width: 150px;
  position: relative;
  border: 1px solid #B4B4B4;
  cursor: pointer;
}

.settings-tab-container .settings-security .settings-area .edit-pencil {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  right: 0;
  position: absolute;
  border: 1px solid white;
  background-color: #B4B4B4;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.settings-tab-container .settings-security .settings-area .edit-pencil svg {
  height: 25px;
  width: 25px;
  color: white;
}

.settings-tab-container .text-input {
  background-color: white !important;
  border: 1px solid #B4B4B4;
  border-radius: 25px !important;
  color: black;
  height: 40px;
  padding-right: 35px;
  min-width: 150px;
  max-width: 400px;
}

.settings-tab-container .choose-option {
  background-color: white !important;
  border: 1px solid #B4B4B4;
  border-radius: 25px !important;
  color: black;
  height: 35px;
  margin-bottom: 0.5em;
}

.settings-tab-container .settings-security .input-icon-group {
  position: relative;
  width: 90%;
  min-width: 150px;
  max-width: 400px;
}

.settings-tab-container .settings-security .input-icon {
  position: absolute;
  right: 0;
  top: 0;
  margin-top: 12px;
  margin-right: 15px;
}

.settings-tab-container .settings-btn {
  background-color: var(--color-background-darkest-role);
  border-radius: 25px;
  color: white;
  font-weight: bold;
  padding: 0.5em;
  width: 90%;
  min-width: 150px;
  max-width: 400px;
}

.settings-tab-container .settings-feedback {
  margin-left: 1em;
  font-size: 0.9em;
  color: #B4B4B4;
}

.settings-tab-container .extra-settings .extra-settings-options {
  display: flex;
  flex-wrap: wrap;
  gap: 3em;
}

.settings-tab-container .extra-settings .extra-settings-options > div {
  max-width: 300px;
}

.settings-tab-container .extra-settings .extra-settings-options .selected-options {
  margin-bottom: 1.5em;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5em;
}

.settings-tab-container .extra-settings .extra-settings-options .selected-options .option-container {
  position: relative;
  padding: 0.5em;
  padding-right: 2.25em;
  border-radius: 18px;
  border: 1px solid #C4C4C4;
  color: #646464;
}

.settings-tab-container .extra-settings .extra-settings-options .selected-options .option-container .delete-icon {
  display: flex;
  position: absolute;
  right: 0.5em;
  top: calc(50% - 10px);
  padding: 0.125em;
  border-radius: 50%;
  border: 2px solid #C4C4C4;
  color: #646464;
  font-weight: bold;
  height: 20px;
  width: 20px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

@media (max-width: 600px) {
  .settings-tab-container .settings-security{
    margin-left: 10px;
  }

  .settings-tab-container .extra-settings .extra-settings-options{
    justify-content: center;
  }

  .settings-tab-container .extra-settings .extra-settings-options > div {
    width: 100%;
    max-width: 600px;
    margin-left: 10px;
  }

  .settings-tab-container .extra-settings .extra-settings-options .settings-btn{
    width: 600px;
  }

  .settings-area {
    flex-direction: column;
  }

  .settings-tab-container .settings-security {
    display: flex;
    flex-direction: column;
  }
}

.footer-container {
  width: 100%;
  z-index: 1033;
  padding-left: 235px;
}

.footer-container .footer-top,
.footer-container .footer-bottom {
  align-items: center;
  padding: 1.25em 0;
  display: flex;
}

.footer-container .footer-top {
  background-color: #E7E7E7;
  color: #616161;
  font-weight: bold;
}

.footer-container .footer-bottom {
  background-color: #595959;
  color: white;
  height: 100%;
}

.footer-container .footer-links {
  display: flex;
  gap: 2em;
  text-decoration: underline;
  cursor: pointer;
  width: 100%;
}


.footer-container .footer-bottom .footer-links {
  gap: 1em;
  margin-left: -230px;
}

.footer-container .footer-bottom .link-button {
  vertical-align: baseline;
  text-decoration: underline;
  padding: 0px 5px;
  background-color: #595959;
  color: white;
}

.footer-container .footer-bottom .footer-links a {
  color: white;
}

.footer-container .footer-top .actify-logo {
  background-image: url('../../resources/img/actify_logo_small.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 35px;
  width: 200px;
  cursor: pointer;
  float: left;
}

.footer-container .footer-bottom .linked-in-logo {
  background-image: url('../../resources/img/icons8-linkedin.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
  float: left;
  height: 35px;
  width: 35px;
}

.footer-container .footer-bottom .x-logo {
  background-image: url('../../resources/img/icons8-twitterx.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
  float: left;
  height: 35px;
  width: 35px;
}

.footer-container .copyright {
  text-align: center;
  font-weight: bold;
  width: 250px;
  width: 230px;
}

.modal-header-container{
  text-align: center;
  font-weight: 700;
}

.terms-container{
  margin: 3em 4em 3em 4em;
}

.terms-content-container {
  max-height: 500px;
  overflow-y: scroll;
  overflow-x: hidden;
  border: 3px solid #14B4C8;
  border-radius: 20px;
  padding: 0 1em;
}

.terms-content-container ol {
  padding-left: 20px;
  padding-bottom: 0px;
}

.terms-content-container li {
  padding-bottom: 10px;
}

.terms-content-container .ordered-letters {
  padding-left: 40px;
  list-style-type: lower-alpha;
}

.terms-content-container .ordered-letters li{
  padding-bottom: 0;
}

.terms-content-container::-webkit-scrollbar {
  width: 1em;
}

.terms-content-container::-webkit-scrollbar-track {
  background-color: #F4F3F3;
  border-radius: 10px;
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
  margin: 1em;
}

.terms-content-container::-webkit-scrollbar-thumb {
  background-color: #14B4C8;
  border-radius: 10px;
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
}

.back-button-container {
  text-align: right;
  padding: 1em;
}

.back-button-container .back-button {
  color: #FFFFFF;
  background-color: #14B4C8;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  border-radius: 25px;
  text-decoration: none;
  font-size: 16px;
  padding: 0.8em;
}

.social-links-mobile {
  display: none;
}

.social-links-mobile .linked-in-logo {
  background-image: url('../../resources/img/icons8-linkedin.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
  height: 20px;
  width: 20px;
}

.social-links-mobile .x-logo {
  background-image: url('../../resources/img/icons8-twitterx.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
  height: 20px;
  width: 20px;
}

@media (max-width: 1023px) {
  .footer-container {
    padding-left: 0;
    margin-bottom: 5em;
  }
  .footer-container .footer-top .footer-links {
    margin-right: -100px;
  }

  .footer-container .footer-bottom .footer-links {
    margin-right: -100px;
  }

}

@media (max-width: 600px) {
  .footer-container .footer-bottom .footer-links{
    margin-left: 0px;
  }

  .footer-container .copyright{
    width: 100%;
  }

  .footer-container .footer-bottom {
    display: block;
    align-items: center;
    padding: 1.25em 0;
  }

  .footer-container .footer-top {
    display: none;
  }

  .footer-container .footer-top .actify-logo {
    background-image: url('../../resources/img/actify_logo.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    flex: 30%;
    width: auto;
    height: 20px;
    cursor: pointer;
  }


  .footer-container .footer-links {
    display: block !important;
    text-decoration: underline;
    cursor: pointer;
    text-align: center;
    padding-top: 0.5em;
  }

  .footer-container .footer-links>span {
    display: block;
    padding: 0.5em;
  }

  .footer-container .footer-bottom .footer-links > .link-button {
    display: block;
    padding: 0.5em;
    margin: auto;
  }

  .terms-container{
    margin: 1em 1.5em;
  }

  .social-links-mobile{
    display: flex;
    gap: 10px;
    justify-content: center;
    padding: 10px;
  }

}


.CreateHub {
  padding: 4%;
  /* height: 650px; */
}

.CreateHub .modal-header-container {
  text-align: center;
}

.CreateHub .modal-button-container {
  text-align: center;
}

.CreateHub .control-label {
  display: block;
  font-size: 25px;
  font-weight: bold;
  color: #646464;
  font-style: "Roboto";
  flex:1;
  margin:10px;
}

.CreateHub .form-group {
  display: flex;
  padding: 20px 0px 0px 0px;
}

.CreateHub .form-control {
  background-color: #FFF8E2;
  border-radius: 20px;
  border-color: #FAC832;
}

.CreateHub .form-input{
  flex:1;
  margin: 10px 40px 10px 0px;
}

.CreateHub .modal-title {
  font-weight: bold;
  color: var(--colour-accent-1);
  font-size: 48px;
  margin-bottom: 10px;
}

.CreateHub .modal-button {
  background-color: var(--colour-accent-1);
  background-image: none;
  color: #ffffff;
  text-transform: uppercase;
  text-shadow: none;
  font-size: 14px;
  font-weight: normal;
  border: 1px solid transparent;
  border-radius: 20px;
  padding: 5px 15px 5px 15px;
  margin: 10px 0px 5px 0px;
  box-shadow: 0px 4px 4px rgb(0,0,0,25%) !important;
}

.CreateHub .file-input-label {
  display: block;
  width: 100%;
}

.img1 {
  display: none;
}

.img1[src] {
 display: block;
}

.CreateHub .image-description {
  color: #646464;
}

.CreateHub .targets {
  display: block;
  flex : 0.30;
  /* margin: 10px; */
  border-radius: 15px;
  background-color: #FFF8E2;
  color: #646464;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  cursor: pointer;
}

.CreateHub .targets-selected {
  display: block;
  flex : 0.30;
  /* margin: 10px; */
  border-radius: 15px;
  border-style: solid;
  border-color: #FAC832 !important;
  background-color: #FFF8E2;
  color: #646464;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  cursor: pointer;
}

.CreateHub .activity-select{
  width: 100%;
}

.CreateHub .targetGroup-select {
  width: 100%;
}
.CreateHub .browse-files-button-container {
  display: block;
  text-align: center;
  float: right;
}

.CreateHub .browse-files-button {
  background-color:#FAC832;
  background-image: none;
  color: #ffffff;
  text-transform: uppercase;
  text-shadow: none;
  font-size: 14px;
  font-weight: normal;
  border: 1px solid transparent;
  border-radius: 20px;
  padding: 5px 15px 5px 15px;
  margin: 10px 0px 10px 0px;
  float: right;
}

.CreateHub .browse-files-button:active {
  background-color: #e59552;
  color: #ffffff;
  border-color: #e59552;
  text-shadow: none;
}

.CreateHub .form-input .logo {
  display: none;
}

.CreateHub .multi-select {
  display: block;
  width: 100%;
}

.CreateHub .chosen-goals-container{
  display: block;
  float: right;
  margin-top: 1.5em;
}

.CreateHub .multi-select-container {
  display: block;
  text-align: center;
  float: right;
}

.CreateHub .multi-button-select {
  background-color:#FAC832;
  background-image: none;
  color: #ffffff;
  text-transform: uppercase;
  text-shadow: none;
  font-size: 14px;
  font-weight: normal;
  border: 1px solid transparent;
  border-radius: 20px;
  padding: 5px 15px 5px 15px;
}

.CreateHub .selection-text {
  display: inline-block;
  color: white;
}

.CreateHub .inline {
  display: block;
  font-size: 14px;
  color: #737373;
}

.CreateHub .selection-icon{
  display: inline-block;
  padding-left: 5px;
}

.CreateHub .selection-container {
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 15px;
  margin: 5px;
  background-color: #FAC832;
  padding: 5px;
}

.CreateHub .chosen-selection-container {
  padding: 10px;
}

.CreateHub .section-container {
  padding: 50px 0px 10px 0px;
}

.CreateHub .character-count {
  display: inline-block;
  width: 20%;
  margin-left: 8px;
}

.CreateHub .inline-label {
  width: 80%;
  display: inline-block;
}

.CreateHub .success-container {
  width: 100%;
  text-align: center;
  position: relative;
}

.CreateHub .success-container .success-message{
  margin: 1em auto;
  max-width: 700px;
}

.CreateHub .feedback-container {
  text-align: center;
}

.modal-form-container-no-scroll {
  overflow-y: hidden !important;
}

.CreateHub .modal-form-container {
  overflow-y: scroll;
  height: 450px;
}

.modal-form-container::-webkit-scrollbar {
  width: 1em;
}

.modal-form-container::-webkit-scrollbar-track {
  background-color: #F4F3F3;
  border-radius: 10px;
  margin: 1em;
}

.modal-form-container::-webkit-scrollbar-thumb {
  background-color: #A0A0A0;
  border-radius: 10px;
}

.CreateHub .form-control-feedback {
  display: none;
}

.CreateHub .help-block {
  color: #a94442;
}

.CreateHub .required-asterisk{
color:  #a94442;
}



@media (max-width: 425px) {
  .CreateHub .form-group {
    display: flow-root;
  }
  .CreateHub .modal-title {
    font-size: 35px;
  }

  .CreateHub .control-label {
    font-size: 19px;
  }

  .CreateHub .form-input {
    margin: 5px 10px 2px 0px;
  }

  .CreateHub .success-image {
    width: 100%;
  }
  .CreateHub {
    padding: 15% 4% 4% 4%;
  }
}

.remove-user-modal-container {
  text-align: center;
}

.remove-user-modal-container .text-container {
  margin-top: 60px;
}

.remove-user-modal-container p {
  font-size: 18px;
}

.remove-user-modal-container .button-container {
  margin-top: 20px;
  margin-bottom: 50px;
}

.remove-user-modal-container button {
  background-color: var(--colour-accent-1);
  background-image: none;
  color: #ffffff;
  text-transform: uppercase;
  border-color: #e59552;
  margin: 10px;
  width: 75px;
  text-shadow: none;
}



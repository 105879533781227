.manage-hubs table {
  width: 100%;
  margin-bottom: 30px;
}

.manage-hubs .hub-path-group {
  display: inline-block;
  margin-left: 10px;
}

.manage-hubs .pending-button, .manage-hubs .remove-button {
  background-image: none;
  color: #ffffff;
  text-shadow: 0 1px 0 #fff;
  font-size: 14px;
  font-weight: normal;
  border: 1px solid transparent;
  border-radius: 4px;
}

.manage-hubs .pending-button {
  background-color: var(--colour-accent-1);
  text-shadow: none;
}

.manage-hubs .remove-button {
  background-color: var(--colour-warning);
  text-shadow: none;
}

.manage-hubs th {
  padding: 25px 10px;
}

.manage-hubs tr {
  border-bottom: solid 2px #eeeeee;
}

.manage-hubs td {
  padding: 10px;
}

.manage-hubs table button {
  height: 40px;
}

.manage-hubs .hub-path-input {
  background-color: #f5f5f5;
  border-radius: 2px;
  border: none;
}

.manage-hubs .path-input {
  width: 40%;
}

.manage-hubs .manage-hub-button-container {
  text-align: right;
}


.remove-hub-modal-container {
  text-align: center;
}

.remove-hub-modal-container .text-container {
  margin-top: 60px;
}

.remove-hub-modal-container p {
  font-size: 18px;
}

.remove-hub-modal-container .button-container {
  margin-top: 20px;
  margin-bottom: 50px;
}

.remove-hub-modal-container button {
  background-color: var(--colour-accent-1);
  background-image: none;
  color: #ffffff;
  text-transform: uppercase;
  border-color: #e59552;
  margin: 10px;
  width: 75px;
  text-shadow: none;
}


.create-drag-question .question-word {
  display: inline-block;
  margin: 0px 5px;
  padding: 5px;
  background-color: #f5f5f5;
}

.create-drag-question .question-word-space {
  display: inline-block;
  background-color: #f5f5f5;
  width: 100px;
  height: 20px;
  margin: 0px 2px;
  border: 1px;
  position: relative;
  top: 5px;
}

.create-drag-question .question-word:hover {
  cursor: pointer;
  color: white;
  background-color:#10b5c6;
  border-radius: 25%;
}

.create-drag-question .instruction-text {
  font-weight: bold;
}

.create-drag-question .picked {
  text-decoration: line-through;
  color: #10b5c6;
}

.create-drag-question .words-container {
  margin: 20px 0px;
}

.create-drag-question .draggable {
  display: inline-block;
  border: 1px solid black;
  padding: 5px;
  margin: 0px 10px;
  margin-bottom: 10px;
  border-radius: 0%;
  min-width: 100px;
  text-align: center;
  cursor: pointer;
}

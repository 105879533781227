.analytics-tab-container {
  padding: 2.5%;
  padding-top: 5%;
}

.analytics-tab-container .block-container {
  width: 100%;
}

.analytics-tab-container .block-container:not(:last-child) {
  padding-bottom: 20px;
  margin-bottom: 40px;
  border-bottom: solid 2px #eeeeee;
}

.analytics-tab-container .analytics-block {
  text-align: center;
}

.analytics-tab-container .block-title {
  font-size: 14pt;
  text-align: left;
}

.analytics-tab-container .module-totals, .analytics-tab-container .session-totals, .analytics-tab-container .plan-totals  {
  display: inline-block;
  width: 100%;
  border: 18px solid;
  border-radius: 50%;
  width: 220px;
  height: 220px;
  text-align: center;
  padding-top: 18px;
  margin-top: 20px;
}

.analytics-tab-container .total-count {
  font-size: 60pt;
  margin-bottom: -10px;
}

.analytics-tab-container .total-title {
  font-size: 12pt;
}

.analytics-tab-container .module-totals {
  border-color: #ffc739;
}

.analytics-tab-container .graphs-container .module-totals {
  margin-top: 11px;
  border-color: #fa4444;
}

.analytics-tab-container .session-totals {
  border-color: #2a9ad3;
}
.analytics-tab-container .graphs-container .session-totals {
  margin-top: 11px;
  border-color: #877fcb;
}

.analytics-tab-container .plan-totals {
  border-color: #6bb376;
}
.analytics-tab-container .graphs-container .plan-totals {
  margin-top: 11px;
  border-color: #5acab6;
}

.analytics-tab-container .graphs-container {
  width: 100%;
  position: relative;
  margin-top: 35px;
}

.analytics-tab-container .view-button {
  background-color: #e59552;
  background-image: none;
  color: #ffffff;
  text-transform: uppercase;
  border-color: #e59552;
  margin: 10px;
  letter-spacing: 1px;
  text-shadow: none;
}

.analytics-tab-container .table-container {
  max-height: 300px;
  overflow: scroll;
  margin-left: 2.5%;
}

.analytics-tab-container table {
  width: 100%;
  margin-bottom: 20px;
}

.analytics-tab-container th {
  font-size: 12pt;
  padding: 0px 0 10px 0;
  text-align: left;
}

.analytics-tab-container tr td:first-child {
  text-align: left;
}

.analytics-tab-container tr {
  border-bottom: solid 2px #eeeeee;
}

.analytics-tab-container td {
  padding: 20px 0;
}

.analytics-tab-container tr td:not(:first-child), .analytics-tab-container tr th:not(:first-child) {
  text-align: center;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0,0,0,.5);
  -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
}

.recharts-wrapper {
  margin: 0 auto !important;
}

.analytics-placeholder {
  margin: 3em;
  font-size: 16px;
}

.nav-links-container{
  height: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
  float:left;
  padding-top: 15px;
  padding-right: 5%;
}

.nav-links-wrapper{
  margin: 0;
  padding: 40px 0;
  display: flex;
  height: auto;
  list-style: none;
  background-color: var(--color-background-darker-role);
  width: 100%;
  flex-direction: column;
  position: absolute;
  top: 65px;
  left: 0;
  overflow: hidden;
  transition: all 0.5s ease-in;
  align-items: center;
  z-index: 90;
}

.nav-links-item{
  width: 100%;
  padding: 0 1.1em;
  color: #222;
  font-weight: 500;
  font-size: 16px;
  display: flex;
  margin-bottom: 15px;
  justify-content: center;
}

.nav-link{
  text-decoration: none;
  color: inherit;
  font-size: 20px;
  font-weight: 500;
  /* font-style: 'Roboto'; */
}

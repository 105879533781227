.radio-question-container {
  background-color: rgba(250, 200, 50, 0.35);
  padding-left: 20px;
  padding-bottom: 20px;
  border-radius: 25px;
  margin-top: 20px;
}


.radio-question-container .control-label {
  margin-bottom: 10px;
  width: 100% !important;
}

.radio-question-container .question-text-container {
  font-weight: bold;
  padding-top: 15px;
  width: 80%;
  display: inline-block;
  padding-left: 10px;
  color: #646464;
}

.radio-question-container .answers-container {
  padding-left: 10px;
  max-width: 400px;
}

.answers-container .radio input[type="radio"], .radio-inline input[type="radio"], .checkbox input[type="checkbox"], .checkbox-inline input[type="checkbox"] {
  transform: scale(1.5);
  filter: invert(70%) hue-rotate(358deg) brightness(1.7);
  margin-top: 7px;
  margin-left: -35px;
}

.answers-container .radio label, .checkbox label {
  color: #646464;
  font-weight: bold;
  font-size: 20px;
  margin: 0px 0px 4px 30px;
}


.radio-question-container ol {
  padding-left: 20px;
  list-style: outside;
  list-style-type: decimal;
  display: block;
}

.radio-question-container .correct {
  color: green;
}

.radio-question-container .incorrect {
  color: red;
}

.radio-question-container .fa-check {
  padding-right: 20px;
}

.session-details-container {
  width: 100%;
  padding: 2.5%;
  display: inline-block;
  text-align: center;
  margin-top: -20px;
  margin-bottom: 10px;
  color: #2D6773;
}

.session-details-container .session-plan-title {
  font-weight: bold;
  font-size: 28px;
  padding: 10px;
  color: var(--color-background-darkest-role);
}

.session-details-container .session-date {
  font-size: 15pt;
  padding: 10px;
}

.session-details-container .session-address {
  text-align: center;
  font-size: 15pt;
  padding: 1px;
}

.session-details-container .button-container {
  text-align: center;
  width: 100%;
  margin-top: 20px;

}

.session-details-container .activai-button {
  width: 30%;
  display: inline-block;
  padding: 10px 0;
  border: 1px solid #ccc;
  color: white;
  background: var(--color-background-darkest-role);
  text-shadow: none;
  margin: 0 10px;
  border-radius: 25px;
}

.session-details-container .activai-button.delete-button {
  background-color: red;
  border: 1px solid #ccc;
  border-radius: 25px;
}

.session-details-container .additional-information-title {
  font-size: 15pt;
  font-weight: 600;
}

.session-details-container .additional-information-container {
  margin-top: 20px;
}


.session-details-container .additional-information {
  margin-top: 8px;
}

.session-details-container .delete-session-title {
  font-size: 15pt;
  font-weight: 600;
}

.session-details-container .confirm-delete-title {
  font-size: 15pt;
  font-weight: 600;
}

.session-details-container .confirm-delete-text {
  margin-top: 20px;
}

.session-feedback-modal {
  width: 90%;
  margin: auto;
  margin-top: 70px;
}

@media (max-width: 425px) {
  .session-feedback-modal {
    width: 100%;
    margin: auto;
    margin-top: 70px;
  }
}

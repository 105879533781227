.logged-out-modal .logged-out {
  min-height: 100px;
  padding: 5%;
  text-align: center;
  font-size: 16px;
}
.logged-out-modal .modal-button-container {
  margin-top: 30px;
}
.logged-out-modal .modal-button {
  background-color: #e59552;
  background-image: none;
  color: #ffffff;
  text-transform: uppercase;
  border-color: #e59552;
  text-shadow: none;
  width: 25%
}
